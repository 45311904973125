import React, { MouseEvent, useState } from 'react';
import { Grid, Typography, Tooltip, Checkbox, IconButton } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

import TruncatedTypography from '../../../Common/TruncatedTypography';
import CircularProgressWithLabel from '../../../Common/CircularProgressWithLabel';
import { DATA_PLACEHOLDER, SourceTypeMapping } from '../../../../constants';
import { EventType, IEventResponse, Stakeholder } from '../../../../models';
import { formatArea, formatCurrency } from '../../../../utils/number';
import { getLocalDateFromUtcTimestamp } from '../../../../utils/date';
import { toSentence, toTitleCase } from '../../../../utils/string';
import { sanitizeAddress } from '../../../../utils/address';
import ConfirmHide from '../Archive/ConfirmHide';
import ProjectScoreIndicator from '../../../Common/ProjectScoreIndicator';
import { IScoreResponse } from '../../../../models/property';
import { Variant } from '../../../../types/typography';
import { black, hyperlinkBlue } from '../../../../theme';
import { RenderCleoIcon, RenderSalesforceIcon } from '../../../Common/SourceIndicator';
import salesforceLogo from '../../../../resources/images/salesforceLogo.svg';
import cleoLogo from '../../../../resources/images/cleo-avatar.png';
import { getAccountIcons } from '../../../../utils/accounts';
import { ReactComponent as OpportunityInSFIcon } from '../../../../resources/images/opportunityInSFIcon.svg';
import { addProjectLeadFavorite, deleteProjectLeadFavorite } from '../../../../api/projects';
import { useMessages } from '../../../../state/contexts';
import { RenderAccountNational } from '../../../Common/OrgIcons/AccountIconRenderer';

export const renderValue = (value?: string): string => {
  return value || DATA_PLACEHOLDER;
};

export const renderTruncatedValue = (value?: string): JSX.Element => {
  return (
    <TruncatedTypography
      maxLines={2}
      TooltipProps={{ title: value || '', placement: 'left', arrow: true }}
      TypographyProps={{ variant: 'body2' }}
    >
      {renderValue(value?.replace(/\//g, ' / '))}
    </TruncatedTypography>
  );
};

export const renderBoolean = (value?: boolean): string => {
  return value?.toString() ?? '';
};

export const renderFalsyValue = (value?: string | number): string => {
  return value as string;
};

export const renderTitleCase = (value?: string): string => {
  return toTitleCase(value) || DATA_PLACEHOLDER;
};

export const renderRole = (value?: string): string => {
  return toTitleCase(value) || 'No Role found';
};

export const renderContact = (contact: { [key: string]: string | undefined }) => {
  return Object.keys(contact).map(key => {
    return (
      <Typography variant={'body1'} key={key}>
        {`${key}, ${contact[key]}` || DATA_PLACEHOLDER}
      </Typography>
    );
  });
};

export const renderSource = (
  salesforceCollision: boolean,
  sourceLabel: string,
  sourceName: string,
): JSX.Element => {
  return (
    <Grid style={{ display: 'flex', flexDirection: 'row', width: '5rem' }}>
      {RenderCleoIcon({ cleoLogo, size: '25px', sourceName, sourceLabel, salesforceCollision })}
      {RenderSalesforceIcon({ salesforceLogo, size: '25px', sourceLabel, sourceName })}
    </Grid>
  );
};

export const renderItem = (
  value?: string,
  titleCase = false,
  variant: Variant = 'body1',
  hyperlink = false,
  defaultValue = '',
): JSX.Element => {
  if (titleCase && value) {
    value = toTitleCase(value.toString());
  }
  const color = hyperlink && value ? hyperlinkBlue : 'inherit';
  return (
    <Typography style={{ color }} variant={variant}>
      {value || defaultValue || DATA_PLACEHOLDER}
    </Typography>
  );
};

export const renderItem2 = (
  value?: string,
  titleCase = false,
  variant: Variant = 'body1',
  hyperlink = false,
  defaultValue = '',
): JSX.Element | string => {
  if (titleCase && value) {
    value = toTitleCase(value.toString());
  }
  const color = hyperlink && value ? hyperlinkBlue : 'inherit';
  if (!parseInt(value as string)) {
    return '';
  } else {
    return (
      <Typography style={{ color }} variant={variant}>
        {value || defaultValue || DATA_PLACEHOLDER}
      </Typography>
    );
  }
};

export const renderProperty = (address: string, city: string, state: string): JSX.Element => {
  const formattedAddr = toTitleCase(address);
  return (
    <>
      <Typography color="primary">{`${toTitleCase(city)}, ${state?.toUpperCase()}`}</Typography>
      {formattedAddr && <Typography variant="body2">{formattedAddr}</Typography>}
    </>
  );
};

export const renderPropertyPL = (
  address: string,
  city: string,
  state: string,
  variant: Variant = 'body1',
  hyperlink = false,
  singleLine = false,
): JSX.Element => {
  const formattedAddr = toTitleCase(address);
  const color = hyperlink ? hyperlinkBlue : 'inherit';

  if (singleLine) {
    const addressCity = [formattedAddr, toTitleCase(city)].filter(Boolean).join(' ');
    const singleLineAddresss = [addressCity, state?.toUpperCase()].filter(Boolean).join(', ');
    return (
      <Grid style={{ color }}>
        <Typography variant={variant}>{singleLineAddresss}</Typography>
      </Grid>
    );
  }

  return (
    <Grid style={{ display: 'flex', flexDirection: 'column', color }}>
      {formattedAddr && <Typography variant={variant}>{formattedAddr}</Typography>}
      <Typography variant={variant}>{`${toTitleCase(city)}, ${state?.toUpperCase()}`}</Typography>
    </Grid>
  );
};

export const renderPropertyNationalIcon = (
  address: string,
  city: string,
  state: string,
  hasNationalAccount = false,
  hasNationalTargetAccount = false,
  variant: Variant = 'body1',
  hyperlink = false,
): JSX.Element => {
  const formattedAddr = toTitleCase(address);
  const color = hyperlink ? hyperlinkBlue : 'inherit';
  const nationalIcon = (
    <RenderAccountNational
      hasNationalAccount={hasNationalAccount}
      hasNationalTargetAccount={hasNationalTargetAccount}
    />
  );

  return (
    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
      <Grid style={{ display: 'flex', flexDirection: 'column', color, width: '75%' }}>
        {formattedAddr && <Typography variant={variant}>{formattedAddr}</Typography>}
        <Typography variant={variant}>{`${toTitleCase(city)}, ${state?.toUpperCase()}`}</Typography>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          width: '25%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {nationalIcon}
      </Grid>
    </Grid>
  );
};

export const renderEvent = (
  eventSource: string | IEventResponse[],
  lastEventDate?: string,
  eventDescription = '',
): JSX.Element => {
  let description = eventDescription;
  let displayLastEventDate = lastEventDate;
  let source = typeof eventSource === 'string' ? eventSource : '';
  if (typeof eventSource !== 'string' && eventSource) {
    description = eventSource[0]?.description;
    displayLastEventDate = eventSource[0]?.event_date;
    source = eventSource[0]?.source;
  }
  return (
    <>
      <Typography color="primary">
        <strong>{renderValue(SourceTypeMapping[source ?? ''] || toTitleCase(source ?? ''))}</strong>
        , {renderValue(getLocalDateFromUtcTimestamp(displayLastEventDate) || '')}
      </Typography>
      <TruncatedTypography
        maxLines={2}
        TooltipProps={{ title: description || '', placement: 'left', arrow: true }}
        TypographyProps={{ variant: 'body2' }}
      >
        {renderValue(description)}
      </TruncatedTypography>
    </>
  );
};

export const renderEventNotes = (
  eventSource: string | IEventResponse[],
  lastEventDate?: string,
  eventDescription = '',
): JSX.Element => {
  let description = eventDescription;
  if (typeof eventSource !== 'string' && eventSource) {
    description = eventSource[0]?.description ?? lastEventDate;
  }
  return (
    <>
      <TruncatedTypography
        maxLines={2}
        TooltipProps={{ title: toSentence(description) || '', placement: 'bottom', arrow: true }}
        TypographyProps={{ variant: 'body1' }}
      >
        {renderItem(toSentence(description))}
      </TruncatedTypography>
    </>
  );
};

export const renderLatestEventSource = (
  eventSource: string,
  lastEventDate?: string,
): JSX.Element => {
  return (
    <>
      <Typography variant={'body1'}>
        {renderValue(SourceTypeMapping[eventSource] || toTitleCase(eventSource))}
      </Typography>
      <Typography variant={'body1'}>
        {renderValue(getLocalDateFromUtcTimestamp(lastEventDate ?? '') || '')}
      </Typography>
    </>
  );
};

export const renderDate = (
  date: string,
  variant: Variant = 'body1',
  typoColor: string | undefined = undefined,
): JSX.Element => {
  const color = typoColor ? undefined : 'primary';

  return (
    <Typography variant={variant} color={color} style={{ color: typoColor }}>
      {renderValue(getLocalDateFromUtcTimestamp(date) || '')}
    </Typography>
  );
};

export const renderType = (propertyType?: string, eventType?: string): JSX.Element => {
  return (
    <>
      <Typography color="primary">{renderValue(propertyType)}</Typography>
      <Typography variant="body2">
        {renderValue(eventType ? EventType[eventType.toUpperCase()] || eventType : undefined)}
      </Typography>
    </>
  );
};

export const renderBusiness = (propertyName?: string, address2 = ''): JSX.Element => {
  return (
    <TruncatedTypography
      maxLines={2}
      TooltipProps={{ title: propertyName || '', placement: 'bottom', arrow: true }}
      TypographyProps={{ variant: 'body2' }}
    >
      {propertyName || DATA_PLACEHOLDER}
      {address2 && toTitleCase(address2)}
    </TruncatedTypography>
  );
};

export const renderStakeholders = (
  propertyStakeholders: Stakeholder[],
  projectStakeholders: Stakeholder[],
  eventStakeholders: Stakeholder[],
): JSX.Element => {
  const getDisplayedStakeholder = (
    propertyStakeholders: Stakeholder[],
    projectStakeholders: Stakeholder[],
    eventStakeholders: Stakeholder[],
  ) => {
    const [primaryStakeholder] = projectStakeholders.filter(stakeholder => stakeholder.isPrimary);
    if (primaryStakeholder) {
      return primaryStakeholder;
    }
    if (propertyStakeholders.length) {
      const [latestPropertyStakeholder] = propertyStakeholders;
      return latestPropertyStakeholder;
    }
    if (projectStakeholders.length) {
      const [latestProjectStakeholder] = projectStakeholders;
      return latestProjectStakeholder;
    }
    if (eventStakeholders.length) {
      const [latestEventStakeholder] = eventStakeholders;
      return latestEventStakeholder;
    }
    return new Stakeholder({ name: '', created_at: '', updated_at: '', id: '' });
  };

  const displayedStakeholder = getDisplayedStakeholder(
    propertyStakeholders,
    projectStakeholders,
    eventStakeholders,
  );
  const { name, roleDisplayName, organization, phoneNumber, fullAddress } = displayedStakeholder;

  const allStakeholders = uniqBy(
    [...propertyStakeholders, ...projectStakeholders, ...eventStakeholders],
    'id',
  );
  const renderAllStakeholders = allStakeholders.map((stakeholder: Stakeholder, i: number) => (
    <span key={i} style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
      <span>{stakeholder.name && toTitleCase(stakeholder.name)}</span>
      {stakeholder.roleDisplayName && <span>{roleDisplayName}</span>}
    </span>
  ));
  return (
    <span style={{ display: 'flex', flexDirection: 'column' }}>
      <TruncatedTypography
        maxLines={1}
        TooltipProps={{
          title: renderAllStakeholders,
          placement: 'left',
          arrow: true,
        }}
        TypographyProps={{ variant: 'body2' }}
      >
        {name && toTitleCase(name)}
      </TruncatedTypography>
      <TruncatedTypography
        maxLines={2}
        TooltipProps={{
          title: '',
          placement: 'left',
          arrow: true,
        }}
        TypographyProps={{ variant: 'body2' }}
      >
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{roleDisplayName && roleDisplayName}</span>
          {organization && <span>{organization.name}</span>}
          <span>{phoneNumber && phoneNumber}</span>
        </span>
      </TruncatedTypography>
      {fullAddress && !fullAddress.includes('undefined') && (
        <TruncatedTypography
          maxLines={1}
          TooltipProps={{
            title: '',
            placement: 'left',
            arrow: true,
          }}
          TypographyProps={{ variant: 'body2' }}
        >
          {sanitizeAddress(fullAddress)}
        </TruncatedTypography>
      )}
    </span>
  );
};

export const renderNumber = (value: number | string, isMoney = false): JSX.Element => {
  const number = typeof value === 'number' ? value : parseInt(value);
  return (
    <Typography color="primary" variant="h6">
      <strong>
        {number > 0 ? (isMoney ? formatCurrency(value) : formatArea(value)) : DATA_PLACEHOLDER}
      </strong>
    </Typography>
  );
};

export const renderNumber2 = (
  value: number,
  isMoney = false,
  variant: Variant = 'body1',
  suffix = '',
): JSX.Element => {
  const formattedNum = isMoney ? formatCurrency(value) : formatArea(value);
  return (
    <Typography variant={variant}>
      {value > 0 ? `${formattedNum} ${suffix}`.trim() : DATA_PLACEHOLDER}
    </Typography>
  );
};

export const renderAccountIcons = (
  sfRating: string,
  incFlag: boolean,
  isRating: boolean,
): JSX.Element => {
  const { incIconElement, ratingIconElement } = getAccountIcons(sfRating);
  if (incFlag) {
    return !isRating ? incIconElement : ratingIconElement;
  } else {
    return <div />;
  }
};

export const renderMoney = (value?: string): JSX.Element => {
  return (
    <Typography color="primary" variant="h6">
      <strong>{formatCurrency(value) || DATA_PLACEHOLDER}</strong>
    </Typography>
  );
};

export const renderScore = (score: number): JSX.Element => {
  if (typeof score !== 'number') {
    return <CircularProgressWithLabel size={50} value={0} />;
  }
  return <CircularProgressWithLabel size={50} value={score} />;
};

export const renderProjectScore = (
  score: number | IScoreResponse,
  stroke?: string,
  height?: number,
  width?: number,
  fontSize?: string,
): JSX.Element => {
  let displayScore = 0;
  if (score === null) {
    displayScore = 0;
  }
  if (typeof score === 'number') {
    displayScore = score;
  } else if (score !== null && typeof score === 'object' && 'score' in score) {
    displayScore = score.score;
  }
  return (
    <ProjectScoreIndicator
      score={displayScore}
      stroke={stroke}
      height={height}
      width={width}
      fontSize={fontSize}
    />
  );
};

interface IProps {
  score: number;
  stroke?: string;
  height?: number;
  width?: number;
  fontSize?: string;
  projectId: string;
  reloadFunction: () => void;
}
export const RenderFavoriteProjectScore: React.FC<IProps> = ({
  score,
  stroke,
  height,
  width,
  fontSize,
  projectId,
  reloadFunction,
}: IProps): JSX.Element => {
  const [isFavorite, setIsFavorite] = useState<boolean>(true);
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const toggleFavorite = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isFavorite) {
      try {
        await deleteProjectLeadFavorite(projectId || '');
        setIsFavorite(!isFavorite);
        setSuccessMessage('Removed from Favorites');
      } catch (err) {
        setErrorMessage('Error removing favorites: ', err);
      }
    } else {
      try {
        await addProjectLeadFavorite(projectId);
        setIsFavorite(!isFavorite);
        setSuccessMessage('Added to Favorites');
      } catch (err: any) {
        setErrorMessage('Error adding favorites: ', err);
      }
    }

    try {
      setTimeout(() => reloadFunction(), 1000);
    } catch (err) {
      setErrorMessage('There was an error reloading page: ', err);
    }
  };

  return (
    <Grid style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-around' }}>
      <IconButton
        aria-label={'Add Project Lead Favorite'}
        component="span"
        onClick={toggleFavorite}
        style={{ padding: 0 }}
      >
        {isFavorite ? <Favorite color={'error'} /> : <FavoriteBorder color={'error'} />}
      </IconButton>
      <Grid style={{ width: '100%', padding: '0' }}>
        {renderProjectScore(score, stroke, height, width, fontSize)}
      </Grid>
    </Grid>
  );
};

export const renderSearchProjectScore = (score: number | IScoreResponse): JSX.Element => {
  const height = Math.round(61 * 0.75);
  const width = Math.round(112 * 0.75);
  const fontSize = '28px';
  let displayScore = 0;
  if (score === null) {
    displayScore = 0;
  }
  if (typeof score === 'number') {
    displayScore = score;
  } else if (score !== null && typeof score === 'object' && 'score' in score) {
    displayScore = score.score;
  }
  return (
    <ProjectScoreIndicator score={displayScore} height={height} width={width} fontSize={fontSize} />
  );
};

type reloadItems = () => Promise<void>;

export const renderIcon = (
  reloadItems: reloadItems,
  projectName: string,
  projectId: string,
  isConverted?: boolean,
): JSX.Element => {
  return (
    <ConfirmHide
      reloadItems={reloadItems}
      projectName={projectName}
      projectId={projectId}
      isConverted={isConverted}
    />
  );
};

export const confirmHideWNationalIcon = (
  reloadItems: reloadItems,
  projectName: string,
  projectId: string,
  hasNationalAccount: boolean,
  hasNationalTargetAccount: boolean,
  isConverted?: boolean,
): JSX.Element => {
  const natIcon = (
    <RenderAccountNational
      hasNationalAccount={hasNationalAccount}
      hasNationalTargetAccount={hasNationalTargetAccount}
    />
  );
  return (
    <Grid
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}
    >
      <Grid style={{ width: '50%' }}>{natIcon}</Grid>
      <Grid style={{ width: '50%' }}>
        <ConfirmHide
          reloadItems={reloadItems}
          projectName={projectName}
          projectId={projectId}
          isConverted={isConverted}
          hideBackdrop={true}
        />
      </Grid>
    </Grid>
  );
};

export const renderStatusIcon = (isConverted?: boolean): JSX.Element => {
  return (
    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
      {isConverted && (
        <Tooltip title="Opportunity in Salesforce" placement="left" arrow>
          <OpportunityInSFIcon />
        </Tooltip>
      )}
    </Grid>
  );
};

export const renderCheckbox = (
  value: string,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  defaultChecked: boolean,
): JSX.Element => {
  return (
    <Grid style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
      <Checkbox
        style={{ padding: 0, color: black }}
        onChange={handleChange}
        value={value}
        defaultChecked={defaultChecked}
        inputProps={{ 'aria-label': `Checkbox Project ${value}` }}
      />
    </Grid>
  );
};

// Keep updated - List all function signatures here (so we can export to config files).
export type renderFunctions =
  | ((value?: string) => string)
  | ((value?: string) => JSX.Element)
  | ((address: string, city: string, state: string) => JSX.Element)
  | ((
      address: string,
      city: string,
      state: string,
      hasNationalAccount: boolean,
      hasNationalTargetAccount: boolean,
      variant: Variant,
      hyperlink: boolean,
    ) => JSX.Element)
  | ((date: string) => JSX.Element)
  | ((propertyType?: string, eventType?: string) => JSX.Element)
  | ((propertyName?: string, address2?: string) => JSX.Element)
  | ((
      propertyStakeholders: Stakeholder[],
      projectStakeholders: Stakeholder[],
      eventStakeholders: Stakeholder[],
    ) => JSX.Element)
  | ((value: number | string, isMoney: boolean) => JSX.Element)
  | ((score: number) => JSX.Element)
  | ((
      reloadItems: reloadItems,
      projectName: string,
      projectId: string,
      hasNational: boolean,
      hasTargetNational: boolean,
      isConverted?: boolean,
    ) => JSX.Element)
  | ((
      score: number | IScoreResponse,
      stroke: string,
      height: number,
      width: number,
      fontSize: string,
      projectId?: string,
    ) => JSX.Element)
  | ((score: number | IScoreResponse) => JSX.Element)
  | ((contact: { [key: string]: string | undefined }) => JSX.Element[])
  | ((salesforceCollision: boolean, sourceLabel: string, sourceName: string) => JSX.Element)
  | ((value?: boolean) => string)
  | ((sfRating: string, incFlag: boolean, isRating: boolean) => JSX.Element)
  | ((projectId: string, score: number) => JSX.Element)
  | React.FC<IProps>
  | ((
      handleClick: (e: React.MouseEvent<HTMLInputElement>) => void,
      checked: boolean,
    ) => JSX.Element)
  | ((
      value: string,
      titleCase: boolean,
      variant: Variant,
      hyperlink: boolean,
      defaultValue: string,
    ) => JSX.Element | string);
