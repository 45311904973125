import { Box, Button, makeStyles, styled, withTheme } from '@material-ui/core';

import { black, light, primaryBlue, secondary, success, white } from '../../../theme';

export const StyledFilterButton = styled(withTheme(Button))(props => ({
  backgroundColor: props.theme.palette.primary.main,
  borderRadius: '0',
  width: '100%',
}));

export const StyledFilterItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 1rem',
});

export const StyledFilterItemButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '1rem',
});

export const ToolbarContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '20px',
});

export const useLeadsStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '0',
    width: '100vw',
    height: 'calc(100vh - 64px)',
    maxWidth: 'none',
    overflow: 'hidden',
  },
  paper: {
    flexGrow: 1,
    overflow: 'scroll',
  },
  filterTagsContainer: {
    backgroundColor: secondary,
    boxShadow: 'none',
    minHeight: '3rem',
    display: 'flex',
    borderRadius: '5px',
    alignItems: 'center',
    margin: '4px 20px',
    padding: '.5rem',
  },
  filterTagsLabel: {
    width: '8%',
    fontWeight: 'bold',
  },
  filterTagsScrollContainer: {
    display: 'flex',
    maxHeight: '6rem',
    overflow: 'scroll',
  },
  filterTags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterTag: {
    marginLeft: '0.5rem',
  },
  filterChip: {
    backgroundColor: white,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    height: '4rem',
    alignContent: 'center',
    borderBottom: `1px solid ${primaryBlue}`,
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  leadsInfoContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '3rem',
  },
  leadsInfo: {
    height: '2rem',
    width: '12rem',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.68,
    color: success,
    border: `1px solid ${success}`,
    borderRadius: '4px',
    backgroundColor: 'white',
  },
  popover: {
    '& .MuiPaper-root.MuiPopover-paper': {
      top: '148px !important',
      width: '30%',
    },
  },
  openFilterDrawer: {
    color: 'white',
    width: '15.5rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.2rem 0 1rem 0',
    height: 'calc(100vh - 64px)',
    overflow: 'scroll',
  },
  filterDividerTop: {
    background: 'white',
    margin: '1rem 0 2rem 0',
    height: '0.0125rem',
  },
  filterDividerBottom: {
    background: 'white',
    margin: '2rem 0 1rem 0',
    height: '0.0125rem',
  },
  filterContainer: {
    maxHeight: '37rem',
    overflow: 'scroll',
  },
  filterItem: {
    padding: '0.5rem 0',
  },
  filterItemButtonsContainer: {
    justifyContent: 'space-between',
    marginTop: 0,
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  filterItemButtons: {
    width: '45%',
  },
  filterItemButton: {
    width: '-webkit-fill-available',
  },
  filterOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterOptions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
  },
  stateFilter: { display: 'flex', flexDirection: 'column' },
  subFilterOptions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
  },
  filterOptionLabel: {
    fontSize: '0.9rem',
    marginLeft: '0.2rem',
    width: '60%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  filterOptionState: {
    fontSize: '0.9rem',
    marginLeft: '0.2rem',
    width: '60%',
    cursor: 'pointer',
  },
  filterDrawerContainer: {
    background: 'rgb(12, 67, 119)',
    maxWidth: '25%',
  },
  closedFilterDrawer: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filterIcon: {
    color: primaryBlue,
    cursor: 'pointer',
    fontSize: '1.3rem',
  },
  filtersHeaderLabel: {
    color: white,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  filterLabel: {
    fontSize: '1.05rem',
  },
  radioGroup: {
    '& .MuiRadio-root': {
      color: 'white',
    },
  },
  savedFilterErrorContainer: {
    background: '#F4F9FC',
    padding: '1rem',
  },
  filterNameInput: {
    width: '-webkit-fill-available',
    border: '0.5px solid #0F1A24',
    borderRadius: '4px',
    backgroundColor: 'white',
    height: '2rem',
    fontSize: '1rem',
    fontWeight: 'normal',
    paddingLeft: '10px',
    color: black,
  },
  saveFilterButton: {
    marginLeft: '1rem',
    marginRight: '1rem',
    width: '-webkit-fill-available',
    color: black,
    background: light,
    '&:hover': {
      backgroundColor: light,
    },
  },
  saveModalButton: {
    fontSize: '.9rem',
    marginLeft: '1rem',
    marginRight: '.5rem',
    width: '-webkit-fill-available',
    backgroundColor: primaryBlue,
    color: white,
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
    },
  },
  cancelModalButton: {
    fontSize: '.9rem',
    marginLeft: '.5rem',
    marginRight: '1rem',
    width: '-webkit-fill-available',
    backgroundColor: white,
    color: primaryBlue,
    '&:hover': {
      backgroundColor: white,
      color: primaryBlue,
    },
  },
  savedFilterNameErrorBorder: {
    '& .MuiInputBase-input': {
      border: '1px solid #f03434',
    },
  },
  savedFilterNameErrorMessage: {
    color: '#f03434',
  },
  hidden: {
    display: 'none',
  },
  filterNameContainer: {
    margin: '4px 20px 16px 20px',
    display: 'flex',
    alignItems: 'center',
  },
  filterNameDisplay: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginRight: '3rem',
  },
  filterNameDetails: {
    marginRight: '3rem',
  },
  filterNameEditIcon: {
    fontSize: '1rem',
    color: '#0066cc',
    marginLeft: '0.2rem',
    cursor: 'pointer',
  },
  filterNameEditInput: {
    width: '-webkit-fill-available',
    border: '0.5px solid #0F1A24',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: black,
    height: '36px',
    fontSize: '1.5rem',
    fontWeight: 'normal',
    paddingLeft: '10px',
  },
  filterNameErrorEditInput: {
    width: '-webkit-fill-available',
    border: '0.5px solid red',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: 'red',
    height: '36px',
    fontSize: '1.5rem',
    fontWeight: 'normal',
    paddingLeft: '10px',
  },
  filterNameEditSaveButton: {
    fontSize: '.9rem',
    marginLeft: '1rem',
    marginRight: '.5rem',
    width: '6.5rem',
    backgroundColor: primaryBlue,
    color: white,
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
    },
  },
  filterNameEditCancelButton: {
    fontSize: '.9rem',
    marginLeft: '.5rem',
    marginRight: '1rem',
    width: '6.5rem',
    backgroundColor: secondary,
    color: primaryBlue,
    '&:hover': {
      backgroundColor: secondary,
      color: primaryBlue,
    },
  },
  filterNameEditButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  updatedFilterNameErrorInput: {
    width: '-webkit-fill-available',
    border: '0.5px solid red',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'normal',
    paddingLeft: '10px',
    fontSize: '.9rem',
  },
  editedFilterNameText: {
    color: white,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  editedFilterNameErrorText: {
    color: '#f03434',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  filterRadio: {
    color: white,
  },
});
