import { Comparators, FilterItemInputType, FilterOptionsItem } from '../../../../../types';
import { toTitleCase } from '../../../../../utils/string';
import { stateAbbreviations } from '../../../../../utils/stateAbbreviations';
import { countyStateList } from '../../../../../utils/countyStateList';

/**
 * Maps each state (key) to a list of county options in the form of FilterOptionsItem[]
 * */
export const getCountyFiltersByState = (): Record<string, FilterOptionsItem[]> => {
  const counties = countyStateList;
  const mappedCountyOptions: Record<string, FilterOptionsItem[]> = {};
  counties.forEach(countyObj => {
    const { state, county } = countyObj;
    const countyOption: FilterOptionsItem = {
      label: `${toTitleCase(county)} - ${state}`.trim(),
      value: `${county} - ${state}`.trim(),
      inputType: FilterItemInputType.Select,
      comparator: Comparators.In,
      multiple: true,
    };

    const stateKey = stateAbbreviations[state];
    if (!mappedCountyOptions[stateKey]) {
      mappedCountyOptions[stateKey] = [countyOption];
    } else {
      mappedCountyOptions[stateKey].push(countyOption);
    }
  });
  return mappedCountyOptions;
};
