import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

import TransitionButtonsActiveContainer from './TransitionButtonsActiveContainer';
import { useProjectLeadDetailsHeaderStyle } from './ProjectLeadDetailsHeader.style';

import ProjectScoreIndicator from '../../Common/ProjectScoreIndicator';
import { hyperlinkBlue } from '../../../theme';
import { toTitleCase } from '../../../utils/string';
import { ProjectStatus } from '../../../models/common';
import Loader from '../../Common/Loader';
import { Variant } from '../../../types/typography';
import { useMessages } from '../../../state/contexts';
import { addProjectLeadFavorite, deleteProjectLeadFavorite } from '../../../api/projects';

interface ProjectLeadDetailsProps {
  score: number;
  projectId?: string;
  projectName: string;
  refreshPage: () => void;
  projectStatus: ProjectStatus;
  isFavorite: boolean;
  setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectLeadDetailsHeader: React.FC<ProjectLeadDetailsProps> = ({
  score,
  projectName,
  projectId,
  refreshPage,
  projectStatus,
  isFavorite,
  setIsFavorite,
}) => {
  const classes = useProjectLeadDetailsHeaderStyle();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const disableButtons = projectStatus !== ProjectStatus.Active;
  const titleVariant: Variant = 'h2';
  if (!projectId) {
    return <Loader />;
  }

  const toggleFavorite = async () => {
    if (isFavorite) {
      try {
        await deleteProjectLeadFavorite(projectId);
        setIsFavorite(!isFavorite);
        setSuccessMessage('Removed from Favorites');
      } catch (err) {
        setErrorMessage('Error removing favorites', err);
      }
    } else {
      try {
        await addProjectLeadFavorite(projectId);
        setIsFavorite(!isFavorite);
        setSuccessMessage('Added to Favorites');
      } catch (err: any) {
        setErrorMessage('Error adding favorites', err);
      }
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.score}>
        <ProjectScoreIndicator
          score={score}
          stroke={hyperlinkBlue}
          width={100}
          height={55}
          fontSize={'36px'}
        />
      </Grid>
      <Typography variant={titleVariant} className={classes.projectName}>
        {toTitleCase(projectName)}
      </Typography>
      <Grid className={classes.buttons}>
        <IconButton
          aria-label={'Add Project Lead Favorite'}
          component="span"
          onClick={toggleFavorite}
          className={classes.favorite}
        >
          {isFavorite ? <Favorite color={'error'} /> : <FavoriteBorder color={'error'} />}
        </IconButton>
        <TransitionButtonsActiveContainer
          activeProjectId={projectId}
          projectName={projectName}
          disabled={disableButtons}
          refreshPropertyDetails={refreshPage}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectLeadDetailsHeader;
