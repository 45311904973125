import React, { useCallback, useEffect, useState } from 'react';
import { List, makeStyles } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroll-component';
import uniqBy from 'lodash/uniqBy';
import { useParams } from 'react-router';

import AccountCard from './AccountCard';

import {
  accountsCountState,
  accountsIsNewState,
  accountsListState,
  accountsSortState,
  selectedAccountState,
} from '../../../../state/atoms/accounts';
import Loader from '../../../Common/Loader';
import { getAccountViewFilter } from '../../../../api/organizations';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { APICancel, createCancelToken, isCancel } from '../../../../api';
import { sanitizeFilterConfig } from '../../../../utils/filter';
import { accountSortCols, AccountsSortList } from '../../../../models/accountListAccount';
import { ViewFilterRequest } from '../../Leads/Archive/Archive.config';
import { FilterData, Optional } from '../../../../types';
import { AccountPageStatus } from '../AccountsPage.config';

let apiCancel: APICancel;

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: 0,
    paddingRight: '0',
    overflow: 'scroll',
  },
});

interface AccountsListProps {
  filterOption: Optional<FilterData>;
}

interface IRouteParams {
  status: AccountPageStatus;
  savedFilterId?: string;
  organizationId?: string;
}

const AccountsList: React.FC<AccountsListProps> = ({ filterOption = {} }) => {
  const classes = useStyles();
  const { organizationId } = useParams<IRouteParams>();

  const [selectedAccountId, setSelectedAccountId] = useRecoilState(selectedAccountState);
  const [accountsList, setAccountsList] = useRecoilState(accountsListState);
  const accountsListSort = useRecoilValue(accountsSortState);
  const setAccountsCount = useSetRecoilState(accountsCountState);
  const isNew = useRecoilValue(accountsIsNewState);
  const [accountsStartIndex, setAccountsStartIndex] = useState<number>(accountsList.length);
  const [loadMore, setLoadMore] = useState(false);

  const loadAccounts = useCallback(
    async (startIndex: number) => {
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }
      // New obj prevents 'not extensible' error
      const updatedFilterOption = { ...filterOption };
      try {
        if (isNew) {
          updatedFilterOption.is_new = { eq: true };
        }

        if (organizationId) {
          updatedFilterOption.organizationId = { eq: organizationId };
        }

        const config: ViewFilterRequest<AccountsSortList> = {
          sort: {
            order: accountsListSort.direction as 'asc' | 'desc',
            orderBy: accountsListSort.column as keyof AccountsSortList,
          },
          filterOption: updatedFilterOption,
          startIndex,
          limit: DEFAULT_PAGE_SIZE,
        };

        const configOptions = sanitizeFilterConfig(config, accountSortCols, 'accounts');

        const accounts = await getAccountViewFilter(
          configOptions,
          createCancelToken(cancel => {
            apiCancel = cancel;
          }),
        );

        const { items, total } = accounts;

        setAccountsList(prev => {
          return uniqBy([...prev, ...items], 'id');
        });
        setAccountsStartIndex(prev => prev + 10);
        setAccountsCount(total);
      } catch (err) {
        if (!isCancel(err)) {
          console.log(err.message);
        }
      }
    },
    [
      setAccountsList,
      setAccountsStartIndex,
      setAccountsCount,
      accountsListSort,
      isNew,
      filterOption,
      organizationId,
    ],
  );

  useEffect(() => {
    // logic allows to keep accounts list in state when moving away from page
    if (loadMore || !accountsStartIndex) {
      setLoadMore(false);
      loadAccounts(accountsStartIndex);
    }
    // eslint-disable-next-line
  }, [loadAccounts, setLoadMore, loadMore]);

  useEffect(() => {
    if (accountsList.length && selectedAccountId === '') {
      setSelectedAccountId(accountsList[0]?.id || '');
    }
  }, [accountsList, selectedAccountId, setSelectedAccountId]);

  useEffect(() => {
    // This loads a new accounts list every time you change the sort by option
    setLoadMore(false);
    setAccountsStartIndex(0);
    setAccountsList([]);
    setSelectedAccountId('');
    loadAccounts(0);
  }, [loadAccounts, setAccountsList, accountsListSort, setSelectedAccountId]);

  return (
    <List id={'accountsListScrollableDiv'} className={classes.list}>
      <InfiniteScroll
        next={() => {
          setLoadMore(true);
        }}
        hasMore={accountsList.length < 1000}
        loader={<Loader size={40} minHeight={100} />}
        dataLength={accountsList.length}
        scrollableTarget={'accountsListScrollableDiv'}
      >
        {accountsList.map(acc => {
          const selected = selectedAccountId === acc.id;
          return (
            <div key={acc.id} style={{ marginBottom: '1rem' }}>
              <AccountCard
                isSelected={selected}
                account={acc}
                onClick={() => setSelectedAccountId(acc.id || '')}
              />
            </div>
          );
        })}
      </InfiniteScroll>
    </List>
  );
};

export default AccountsList;
