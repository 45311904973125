import React from 'react';
import { MuiThemeProvider, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LocalizationProvider } from '@material-ui/pickers';
import LuxonUtils from '@material-ui/pickers/adapter/luxon';
import { RecoilRoot } from 'recoil';

import Themes from './theme';
import { ProtectedRoute } from './Components/Pages/ProtectedRoute';
import { MessagesProvider } from './state/contexts';
import { PageLayout as PageView } from './Components/Common/PageLayout/PageLayout';
import ErrorPage from './Components/Pages/Error';
import MetricsPage from './Components/Pages/Metrics/Metrics';
import NetworkPage from './Components/Pages/Network/Network';
import OrganizationDetailsPage from './Components/Pages/Network/OrganizationDetails';
import StakeholderDetailsPage from './Components/Pages/Network/StakeholderDetails';
import StyleGuide from './Components/Pages/StyleGuide/StyleGuide';
import AuthUtils from './utils/auth';
import {
  getAccountsPagePath,
  getAnalyticsPagePath,
  getAtAGlancePagePath,
  getNetworkPagePath,
  getOrganizationDetailsPagePath,
  getProjectLeadsPagePath,
  getStakeholderDetailsPagePath,
  getUniversalSearchDetailsPagePath,
} from './utils/page';
import './App.css';
import DataMapper from './Components/Pages/DataMapper/DataMapper';
import DataMapperCreateWizard from './Components/Pages/DataMapper/DataMapperCreateWizard';
import AtAGlancePage from './Components/Pages/AtAGlance/AtAGlance';
import UniversalSearchPage from './Components/Pages/UniversalSearch/UniversalSearchPage';
import ProjectLeadsPage from './Components/Pages/ProjectLeads/ProjectLeads';
import { ProjectLeadStatus } from './models/projectLead';
import ProjectLeadDetails from './Components/Pages/ProjectLeadDetails/ProjectLeadDetails';
import AccountsPage from './Components/Pages/Accounts/AccountsPage';
import AnalyticsPage from './Components/Pages/Analytics/AnalyticsPage';
import { AccountPageStatus } from './Components/Pages/Accounts/AccountsPage.config';

const ProjectLeadStatusPaths = Object.values(ProjectLeadStatus).join('|');
const AccountsStatusPaths = Object.values(AccountPageStatus).join('|');

export default function App(): JSX.Element {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  AuthUtils.getIdTokenClaims = getIdTokenClaims;
  AuthUtils.getAccessTokenSilently = getAccessTokenSilently;

  return (
    <RecoilRoot>
      <MuiThemeProvider theme={Themes}>
        <ThemeProvider theme={Themes}>
          <LocalizationProvider dateAdapter={LuxonUtils}>
            <MessagesProvider>
              <PageView>
                <CssBaseline />

                <Switch>
                  <ProtectedRoute path="/metrics" component={MetricsPage} exact />
                  <ProtectedRoute path="/data-mapper" component={DataMapper} exact />
                  <ProtectedRoute
                    path="/data-mapper/create"
                    component={DataMapperCreateWizard}
                    exact
                  />
                  <Route
                    path="/project-leads/"
                    render={() => <Redirect to={`${getProjectLeadsPagePath()}/home`} />}
                    exact
                  />
                  <ProtectedRoute
                    path={`${getProjectLeadsPagePath()}/:status(${ProjectLeadStatusPaths})?`}
                    component={ProjectLeadsPage}
                    exact
                  />
                  <ProtectedRoute
                    path={`/project-leads/savedFilters/:savedFilterId`}
                    component={ProjectLeadsPage}
                    exact
                  />
                  <ProtectedRoute
                    path={getProjectLeadsPagePath() + `/details/:projectId`}
                    component={ProjectLeadDetails}
                    exact
                  />
                  <ProtectedRoute path={getNetworkPagePath()} component={NetworkPage} exact />
                  <ProtectedRoute
                    path={`${getAccountsPagePath()}/:status(${AccountsStatusPaths})?`}
                    component={AccountsPage}
                    exact
                  />
                  <ProtectedRoute
                    path={getAccountsPagePath() + '/details/:organizationId'}
                    component={AccountsPage}
                    exact
                  />
                  <ProtectedRoute path={getAnalyticsPagePath()} component={AnalyticsPage} exact />
                  <ProtectedRoute path={getAtAGlancePagePath()} component={AtAGlancePage} exact />
                  <ProtectedRoute
                    path={getUniversalSearchDetailsPagePath(':search')}
                    component={UniversalSearchPage}
                    exact
                  />
                  <ProtectedRoute
                    path={getStakeholderDetailsPagePath(':stakeholderId')}
                    component={StakeholderDetailsPage}
                    exact
                  />
                  <ProtectedRoute
                    path={getOrganizationDetailsPagePath(':organizationId')}
                    component={OrganizationDetailsPage}
                    exact
                  />
                  <ProtectedRoute path="/style-guide" component={StyleGuide} exact />
                  <Route
                    path="/"
                    render={() => <Redirect to={`${getProjectLeadsPagePath()}/home`} />}
                    exact
                  />
                  <Route component={ErrorPage} />
                </Switch>
              </PageView>
            </MessagesProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </RecoilRoot>
  );
}
