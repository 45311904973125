import React from 'react';
import { Paper, Grid, Container, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { CleoObjectType, ProjectStatusAction } from '../../../../models/common';
import PaperHeader from '../../../Common/PaperHeader';
import PaperTitle from '../../../Common/PaperTitle';
import PaperBody from '../../../Common/PaperBody';
import TransitionSuccess from '../../../../resources/images/cleo-transition-success.png';
import { darkBlue, primaryBlue, darkGray } from '../../../../theme';
import { toTitleCase } from '../../../../utils/string';

type IProps = {
  leadName: string;
  objectType?: CleoObjectType;
  status: string;
  propertyIds?: string[];
  onClick: () => void;
};

const useStyles = makeStyles(theme => ({
  image: {
    width: '20rem',
    marginBottom: theme.spacing(11),
  },
  leadName: {
    fontSize: '.93rem',
    lineHeight: '1.68',
    letterSpacing: 'normal',
    color: darkBlue,
    marginBottom: theme.spacing(1),
  },
  lead: {
    fontWeight: 'bold',
    color: primaryBlue,
  },
  hasBeen: {
    fontSize: '.8rem',
    fontWeight: 'bold',
    lineHeight: '1.68',
    color: darkBlue,
    marginBottom: theme.spacing(8),
  },
  subtext: {
    fontSize: '.8rem',
    color: darkGray,
    marginBottom: theme.spacing(3),
  },
  last: {
    padding: theme.spacing(2.5, 12),
    marginBottom: theme.spacing(20),
  },
}));

const ProjectTransitioned: React.FC<IProps> = ({
  leadName,
  status,
  onClick,
  propertyIds,
  objectType = CleoObjectType.Addresses,
}) => {
  const classes = useStyles();
  let newStatus = 'Demoted';
  let cleoObjectType;
  let toPath;
  if (objectType === CleoObjectType.Addresses) {
    cleoObjectType = 'Lead';
    toPath = '/leads';
  } else {
    cleoObjectType = 'Project Lead';
    toPath = '/project-leads';
  }
  let subtext = `see more ${cleoObjectType.toLowerCase()}s`;

  if (status === ProjectStatusAction.Convert) {
    newStatus = 'Converted';
    subtext = `awesome job, ${subtext}`;
  }

  return (
    <Container maxWidth="md">
      <Paper>
        <PaperHeader>
          <Grid container justify="center">
            <Grid item>
              <PaperTitle
                title={
                  propertyIds
                    ? `${propertyIds.length} Lead${propertyIds.length > 1 && 's'} ${newStatus}`
                    : `Lead ${newStatus}`
                }
              />
            </Grid>
          </Grid>
        </PaperHeader>
        <PaperBody>
          <Grid container direction="column" justify="space-evenly" alignItems="center">
            <Grid item>
              <img src={TransitionSuccess} alt={newStatus} className={classes.image} />
            </Grid>
            <Grid item className={classes.leadName}>
              {propertyIds ? (
                'All selected leads'
              ) : (
                <>
                  <strong className={classes.lead}>{cleoObjectType}: </strong>
                  {leadName}
                </>
              )}
            </Grid>
            <Grid item className={classes.hasBeen}>
              <strong>
                {propertyIds ? `have` : `has`} been {newStatus.toLowerCase()} to Salesforce
              </strong>
            </Grid>
            {cleoObjectType !== 'Project Lead' && (
              <div>
                <Grid item className={classes.subtext}>
                  {toTitleCase(subtext)}
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.last}
                    component={Link}
                    to={toPath}
                    onClick={onClick}
                  >
                    {`ALL ${cleoObjectType}s`}
                  </Button>
                </Grid>
              </div>
            )}
          </Grid>
        </PaperBody>
      </Paper>
    </Container>
  );
};

export default ProjectTransitioned;
