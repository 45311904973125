import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Input,
  Typography,
} from '@material-ui/core';
import { SetterOrUpdater } from 'recoil';

import { primaryBlue } from '../../../../theme';
import { useLeadsStyles } from '../Leads.styles';

interface IProps {
  setIsOpen: (bool: boolean) => void;
  isOpen: boolean;
  handleSavedFilterName: () => Promise<void>;
  setSavedFilterValue: SetterOrUpdater<string>;
  savedFilterValue: string;
}

const SaveFilterModal: React.FC<IProps> = ({
  setIsOpen,
  isOpen,
  handleSavedFilterName,
  setSavedFilterValue,
  savedFilterValue,
}: IProps) => {
  const classes = useLeadsStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      BackdropProps={{
        style: {
          background: 'rgba(49, 49, 49, 0.5)',
        },
      }}
    >
      <DialogContent style={{ paddingTop: '.5rem' }}>
        <FormControl>
          <Typography style={{ color: primaryBlue, fontWeight: 'bold' }}>
            New Filter Name
          </Typography>
          <Input
            value={savedFilterValue}
            onChange={evt => setSavedFilterValue(evt.currentTarget.value)}
            id={`saved-filter-value-input-display`}
            onKeyPress={ev => ev.key === 'Enter' && handleSavedFilterName()}
            className={classes.filterNameInput}
            style={{ width: '15rem' }}
            disableUnderline
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '.75rem' }}>
        <Button onClick={() => handleSavedFilterName()} className={classes.saveModalButton}>
          Save
        </Button>
        <Button onClick={() => setIsOpen(false)} className={classes.cancelModalButton}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFilterModal;
