import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Stakeholder } from '../../../../models';
import StakeholderCard from '../../StakeholderDetails/StakeholderCard';
import EmptyBox from '../../../Common/EmptyBox';
import {
  AccountContactsState,
  accountContactsState,
  accountPartnersState,
} from '../../../../state/atoms/accounts';
import { getOrganizationStakeholders } from '../../../../api/organizations';

export enum AccountContactType {
  Contact = 'Contact',
  Partner = 'Partner',
}

type AccountContactsProps = {
  contactType: AccountContactType;
  accountId: string;
  setContactsCount: React.Dispatch<React.SetStateAction<number>>;
};

const AccountContacts: React.FC<AccountContactsProps> = ({
  contactType,
  accountId,
  setContactsCount,
}) => {
  const [accountContacts, setAccountContact] = useRecoilState<AccountContactsState>(
    accountContactsState(accountId),
  );

  const [accountPartners, setAccountPartners] = useRecoilState<AccountContactsState>(
    accountPartnersState(accountId),
  );

  const [displayList, setDisplayList] = useState<Stakeholder[]>([]);

  const loadContacts = useCallback(async () => {
    const contacts = await getOrganizationStakeholders(accountId);
    setAccountContact({ loaded: true, stakeholders: contacts });
  }, [setAccountContact, accountId]);

  const loadPartners = useCallback(async () => {
    // TODO: Make API call for Partners here
    setAccountPartners({ loaded: true, stakeholders: [] });
  }, [setAccountPartners]);

  useEffect(() => {
    if (accountId !== '') {
      loadContacts();
      loadPartners();
    }
  }, [loadContacts, loadPartners, contactType, accountId]);

  useEffect(() => {
    contactType === AccountContactType.Contact
      ? setContactsCount(accountContacts.stakeholders.length)
      : setContactsCount(accountContacts.stakeholders.length);
  }, [accountContacts, accountPartners, setContactsCount, contactType]);

  useEffect(() => {
    if (contactType === AccountContactType.Contact) {
      setDisplayList(accountContacts.stakeholders);
    } else if (contactType === AccountContactType.Partner) {
      setDisplayList(accountPartners.stakeholders);
    }
  }, [accountContacts, accountPartners, contactType]);

  if (displayList.length === 0) {
    return <EmptyBox style={{ height: '100%' }}>There are no {contactType}s to display.</EmptyBox>;
  }

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {displayList.map((contact, idx) => (
        <StakeholderCard isOdd={!(idx % 2)} stakeholder={contact} key={idx} />
      ))}
    </div>
  );
};

export default AccountContacts;
