import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import { useParams } from 'react-router';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';
import isEmpty from 'lodash/isEmpty';

import { useAccountsPageStyles } from './AccountsPage.styles';
import { AccountPageStatus, AccountsPageMenu } from './AccountsPage.config';
import AccountsHome from './AccountsHome/AccountsHome';

import { CleoObjectType } from '../../../models/common';
import LeadsFilterDrawer from '../Leads/LeadsFilter/LeadsFilterDrawer';
import StatusBar from '../Leads/LeadsTable/StatusBar';
import { getAccountsPagePath } from '../../../utils/page';
import {
  Comparators,
  FilterData,
  FilterItemInputType,
  FilterOptionsItem,
  FilterOptionsResponse,
  Optional,
} from '../../../types';
import { filterOptionsFetchState } from '../../../state/selectors/filterOptions';
import {
  allFilterTagsState,
  allKeysSelectedState,
  clearFiltersState,
  currentFilterOptionsState,
  editedFilterNameState,
  filterNameErrorState,
  filterOptionState,
  filterTagsToDisplayState,
  savedFilterValueState,
} from '../../../state/atoms/filters';
import FilterTags from '../Leads/FilterTags';
import { User } from '../../../models';
import { resetCachedFilterState } from '../../../state/atoms/leadRows';
import { currentUserState, userOptionsState } from '../../../state/atoms/users';
import { usersFetchState } from '../../../state/selectors/users';
import { accountDetailsIdState, accountsMergeIdState } from '../../../state/atoms/accounts';

interface IRouteParams {
  status: AccountPageStatus;
  savedFilterId?: string;
  organizationId?: string;
}

const AccountsPage: React.FC = () => {
  const classes = useAccountsPageStyles();
  const { status, organizationId } = useParams<IRouteParams>();

  const [accountsPageStatus, setAccountsPageStatus] = useState(status);
  const accountsMergeId = useRecoilValue(accountsMergeIdState);
  const [filterTagsToDisplay, setFilterTagsToDisplay] = useRecoilState(filterTagsToDisplayState);
  const [clearFilters, setClearFilters] = useRecoilState(clearFiltersState);
  const [filterOption, setFilterOption] = useRecoilState<Optional<FilterData>>(filterOptionState);
  // const prefix = getProjectLeadsPagePath();
  const setAllKeysSelected = useSetRecoilState(allKeysSelectedState);
  const setAllFilterTags = useSetRecoilState(allFilterTagsState);
  const setResetCachedFilter = useSetRecoilState(resetCachedFilterState);
  const setAccountDetailsId = useSetRecoilState(accountDetailsIdState);

  // User Filters
  const { user } = useAuth0();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setUserOptions = useSetRecoilState<FilterOptionsItem[]>(userOptionsState);
  const users = useRecoilValueLoadable(usersFetchState);

  // Saved Filters
  // TODO: Saved Filter items for Accounts Page commented out until API is built
  // const [editedFilterNameError, setEditedFilterNameError] = useState<string>();
  // const [savedFilter, setSavedFilter] = useState<UserFilterNameResponse>();
  const setEditedFilterName = useSetRecoilState<string>(editedFilterNameState);
  const setFilterNameError = useSetRecoilState<string | undefined>(filterNameErrorState);
  const setSavedFilterValue = useSetRecoilState(savedFilterValueState);

  const fetchedFilterOptions = useRecoilValueLoadable<FilterOptionsResponse>(
    filterOptionsFetchState,
  );
  const setCurrentFilterOptions = useSetRecoilState<FilterOptionsResponse>(
    currentFilterOptionsState,
  );

  const handleTabClick = (nextAccountsPageStatus: AccountPageStatus) => {
    setAccountsPageStatus(nextAccountsPageStatus);
  };

  const disableFilters = !!accountsMergeId || !!organizationId;

  const handleFilterChange = useCallback(
    (filterData: FilterData) => {
      return setFilterOption(currentFilterOption => ({ ...currentFilterOption, ...filterData }));
    },
    [setFilterOption],
  );

  const hasFilterBeenCleared = useCallback(() => {
    let filterHasBeenCleared = false;
    if (
      filterOption &&
      (isEmpty(filterOption) ||
        Object.keys(filterOption).every(option =>
          option === 'isNew' ? true : isEmpty(filterOption[option]),
        ))
    ) {
      filterHasBeenCleared = true;
    }
    return filterHasBeenCleared;
  }, [filterOption]);

  useEffect(() => {
    if (fetchedFilterOptions.state === 'hasValue') {
      setCurrentFilterOptions(fetchedFilterOptions.contents);
    }
  }, [fetchedFilterOptions, setCurrentFilterOptions]);

  // handles specific organization from route parameters
  useEffect(() => {
    if (organizationId) {
      setAccountDetailsId(organizationId);
    } else {
      setAccountDetailsId(null);
    }
  }, [setAccountDetailsId, organizationId]);

  useEffect(() => {
    if (clearFilters) {
      setAllKeysSelected({});
      setFilterOption({});
      setAllFilterTags([]);
      setFilterTagsToDisplay([]);
      setResetCachedFilter(true);
      setClearFilters(false);
      setEditedFilterName('');
    }
    if (hasFilterBeenCleared()) {
      // setEditedFilterNameError(undefined);
      // setFilterNameError(undefined);
      setSavedFilterValue('');
    }
  }, [
    clearFilters,
    setAllKeysSelected,
    setAllFilterTags,
    setResetCachedFilter,
    setClearFilters,
    hasFilterBeenCleared,
    setFilterTagsToDisplay,
    setFilterOption,
    setFilterNameError,
    setSavedFilterValue,
    setEditedFilterName,
  ]);

  useEffect(() => {
    if (users.state === 'hasValue') {
      const options: FilterOptionsItem[] = users.contents.map(i => {
        return {
          label: i.fullName || i.email,
          value: i.id,
          inputType: FilterItemInputType.Select,
          comparator: Comparators.In,
        };
      });
      options.push({
        label: 'Unassigned',
        value: null,
        inputType: FilterItemInputType.Select,
        comparator: Comparators.Is,
      });
      setUserOptions(options);
      setCurrentUser(users.contents.find((u: User) => user.email === u.email));
    }
  }, [users, user, setUserOptions, setCurrentUser]);

  // Clear filters when changing tabs
  useEffect(() => {
    setClearFilters(true);
  }, [setClearFilters, status]);

  return (
    <Container className={classes.root}>
      <LeadsFilterDrawer
        handleFilterChange={handleFilterChange}
        hasFilterBeenCleared={hasFilterBeenCleared}
        setScoreStatus={() => () => console.log('set score status')}
        setSavedFilter={() => console.log('set saved filter')}
        // savedFilter={}
        cleoObjectType={CleoObjectType.Accounts}
        disabled={disableFilters}
        filterTagsToDisplay={[]}
        filterType={'project_lead'}
        // editedFilterNameError={}
        setEditedFilterNameError={() => console.log('set edited filter name error')}
        closeFilterDrawer={!!accountsMergeId}
      />
      <Paper className={classes.paper}>
        <Grid className={classes.menuTabs}>
          <StatusBar
            statusMenu={AccountsPageMenu}
            selectedStatus={accountsPageStatus || AccountPageStatus.Home}
            pathPrefix={getAccountsPagePath()}
            onChange={handleTabClick}
          />
        </Grid>
        {filterTagsToDisplay.length > 0 && <FilterTags handleFilterChange={handleFilterChange} />}
        {(accountsPageStatus === AccountPageStatus.Home || organizationId) && (
          <AccountsHome filterOption={filterOption} />
        )}
      </Paper>
    </Container>
  );
};

export default AccountsPage;
