import { HeadCell } from '../../../Common/Tables/Table';
import {
  renderDate,
  renderEvent,
  RenderFavoriteProjectScore,
  renderItem,
  renderNumber2,
  renderPropertyNationalIcon,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { hyperlinkBlue } from '../../../../theme';
import { IProject, ProjectLeadTable as ProjectsColumn } from '../../Leads/Archive/Archive.config';

export const projectLeadFavoritesHeadCells: HeadCell<ProjectsColumn, IProject>[] = [
  {
    id: 'isFavorite',
    label: 'Score',
    gridProps: { md: 1 },
    textAlign: 'center',
    renderRow: {
      function: RenderFavoriteProjectScore,
      param: ['projectScore'],
      noData: 0,
      style: {
        textAlign: 'center',
        paddingTop: '1rem',
      },
      reloadItems: true,
    },
  },
  {
    id: 'projectName',
    label: 'Project Name',
    gridProps: { md: 3 },
    renderRow: {
      function: renderItem,
      param: ['projectName'],
      noData: '',
      style: {
        color: hyperlinkBlue,
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'biddate',
    label: 'Bid Date',
    gridProps: { md: 1 },
    renderRow: {
      function: renderDate,
      param: ['bidDate'],
      noData: '',
      style: {
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'actionStage',
    label: 'Action Stage',
    gridProps: { md: 1 },
    renderRow: {
      function: renderItem,
      param: ['mappedActionStage'],
      noData: '',
      style: {
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'value',
    label: 'Valuation',
    gridProps: { md: 1 },
    renderRow: {
      function: renderNumber2,
      param: ['value'],
      additionalParam: [true],
      noData: 0,
      style: {
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'projectId',
    label: 'Latest Event',
    gridProps: { md: 3 },
    renderRow: {
      function: renderEvent,
      param: ['dataSource', 'lastUpdated', 'latestEvent'],
      noData: '',
      style: {
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
  {
    id: 'property',
    label: 'Location',
    gridProps: { md: 2 },
    renderRow: {
      function: renderPropertyNationalIcon,
      param: ['address1', 'city', 'state', 'hasNationalAccount', 'hasNationalTargetAccount'],
      noData: '',
      style: {
        color: hyperlinkBlue,
        paddingTop: '1rem',
        alignSelf: 'flex-start',
      },
    },
  },
];
