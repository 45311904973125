import API, { APICancelToken } from './api';

import type { Organization, Project, Property, Stakeholder } from '../models';

export interface SearchCount {
  organizations: number;
  projects: number;
  properties: number;
  stakeholders: number;
  total: number;
}

export interface UniversalSearchResponse {
  items: Project[] | Stakeholder[] | Organization[] | Property[];
  objectType: string;
  searchCount: SearchCount;
  universalSearchStr: string;
}

export const getSearchResults = async (
  searchStr: string,
  objectType: string,
  startIndex?: number,
  limit?: number,
  cancelToken?: APICancelToken,
): Promise<UniversalSearchResponse> => {
  const params = {
    universalSearchStr: encodeURIComponent(searchStr),
    limit: encodeURIComponent(limit ?? 10),
    offset: encodeURIComponent(startIndex ?? 0),
    objectType: encodeURIComponent(objectType),
  };
  const { data } = await API.get('/search', {
    params,
    cancelToken,
  });
  return data as UniversalSearchResponse;
};
