import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Grid } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';

import { ScoreStatus, SortOrder } from '../../../../models/lead';
import { ProjectLeadStatus } from '../../../../models/projectLead';
import { projectLeadFavoritesHeadCells } from '../ProjectLeadsTable/ProjectLeadsFavoritesTable.config';
import {
  IProject,
  ProjectLeadTable,
  ProjectLeadTable as ProjectsColumn,
  SortArchive,
  sortColumns,
} from '../../Leads/Archive/Archive.config';
import { showNewFilterState } from '../../../../state/atoms/leadRows';
import { bulkDemoteLeadsButtonPressedState } from '../../../../state/atoms/projectLeads';
import {
  bulkDemoteTransitionState,
  selectedBulkDemoteState,
} from '../../../../state/atoms/projects';
import { FilterData, Optional } from '../../../../types';
import { getProjectLeadsDetailsPagePath } from '../../../../utils/page';
import { getDemotionReasons, getProjectViewFilter } from '../../../../api/projects';
import { APICancel, createCancelToken, isCancel } from '../../../../api';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import ShowNewAndBulkDemote from '../../../Common/ShowNewAndBulkDemote';
import NationalAccountFilter from '../NationalAccountFilter';
import BulkDemoteButtons from '../ProjectLeadsTable/BulkDemoteButtons';
import ProjectLeadsBulkDemoteConfirmation from '../ProjectLeadsBulkDemote/ProjectLeadsBulkDemoteConfirmation';
import QualificationBulkDemoteButtons from '../ProjectLeadsTable/QualificationBulkDemoteButtons';
import Table from '../../../Common/Tables/Table';

let apiCancel: APICancel;

interface ProjectLeadsFavoritesTableProps {
  filterOption: Optional<FilterData>;
  status: ProjectLeadStatus;
}

const ProjectLeadsFavoritesTable: React.FC<ProjectLeadsFavoritesTableProps> = ({
  filterOption = {},
  status,
}) => {
  const history = useHistory();
  const scoreStatus = ScoreStatus.Home;
  const rowCount = 10;
  const headCells = projectLeadFavoritesHeadCells;

  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const [rows, setRows] = useState<IProject[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [startIndex, setStartIndex] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<keyof ProjectsColumn>(
    status === ProjectLeadStatus.Favorites ? 'isFavorite' : 'projectScore',
  );
  const [order, setOrder] = useState<SortOrder>('desc');
  const [sort, setSort] = useState<SortArchive<ProjectLeadTable>>({
    orderBy: 'projectScore',
    order: 'desc',
  });
  const [showNew, setShowNew] = useRecoilState(showNewFilterState);
  const [bulkDemoteButtonClicked, setBulkDemoteButtonClicked] = useRecoilState(
    bulkDemoteLeadsButtonPressedState,
  );
  const [isDemoting, setIsDemoting] = useState<boolean>(false);
  const [completingDemotion, setCompletingDemotion] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const setSelectedBulkItems = useSetRecoilState(selectedBulkDemoteState);
  const [transitionItems, setTransitionItems] = useRecoilState(bulkDemoteTransitionState);
  const [nationalAccountFilter, setNationalAccountFilter] = useState<FilterData>({} as FilterData);
  const [demotionReasons, setDemotionReasons] = useState<Record<string, string>>({});

  const getReasons = useCallback(async () => {
    const dReasonsResp = await getDemotionReasons();
    const dReasons = {} as Record<string, string>;
    dReasonsResp.map(x => {
      return (dReasons[x.transition_reason] = x.reason_mapping);
    });
    setDemotionReasons(dReasons);
  }, []);

  const handleClickRow = {
    function: (projectId: string) => {
      history.push(getProjectLeadsDetailsPagePath(projectId));
    },
    to: (projectId: string) => getProjectLeadsDetailsPagePath(projectId),
    param: 'id' as keyof IProject,
    link: '',
    newTab: true,
  };

  const handleSortRequest = useCallback(
    (e: React.MouseEvent<unknown>, property: keyof ProjectsColumn) => {
      e.preventDefault();
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setSort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
      setStartIndex(0);
      setRows([]);
    },
    [order, orderBy, setOrder, setOrderBy, setSort, setStartIndex, setRows],
  );

  const createDefaultFilter = useCallback(() => {
    const defaultFilter: FilterData = cloneDeep(filterOption);
    defaultFilter.projectStatus = { eq: 'active' };
    if (showNew) {
      defaultFilter.isNew = { eq: true };
    } else {
      defaultFilter.isNew = {};
    }

    // Handle National Account Filters
    Object.keys(nationalAccountFilter).forEach(key => {
      defaultFilter[key] = (nationalAccountFilter as FilterData)[key];
    });
    return defaultFilter;
  }, [showNew, filterOption, nationalAccountFilter]);

  const loadItems = useCallback(
    async startIndex => {
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }

      // Do default filter handling here
      try {
        const defaultFilter = createDefaultFilter();
        const data = await getProjectViewFilter(
          {
            sort,
            startIndex,
            filterOption: defaultFilter,
          },
          sortColumns,
          createCancelToken(cancel => {
            apiCancel = cancel;
          }),
        );
        if (!data.items) {
          throw new Error('Projects not available');
        }
        setRows(rows => [...rows, ...data.items]);
        setCount(data.total);
        if (data.items.length < DEFAULT_PAGE_SIZE) {
          setHasMoreItems(false);
        }
      } catch (e: any) {
        if (!isCancel(e)) {
          setErrorMessage(e.message);
        }
      }
    },
    [sort, createDefaultFilter],
  );

  // Used for hiding items, toggling favorite, and reloading again
  const reloadItems = async () => {
    setRows([] as IProject[]);
    setStartIndex(0);
    setHasMoreItems(true);
    setErrorMessage('');

    await loadItems(startIndex);
  };

  const handleShowNew = () => {
    setShowNew(!showNew);
    reloadItems().catch();
  };

  const handleBulkDemoteButton = useCallback(() => {
    setBulkDemoteButtonClicked((prev: boolean) => !prev);
  }, [setBulkDemoteButtonClicked]);

  const handleCheckbox = useCallback(
    (row: IProject) => {
      setSelectedBulkItems(prev => {
        const rowSelected = prev.find(selectedProject => selectedProject.id === row.id);
        if (rowSelected) {
          return prev.filter(selectedProject => selectedProject.id !== row.id);
        } else {
          return prev.concat(row);
        }
      });
    },
    [setSelectedBulkItems],
  );

  const handleCancelBulkDemote = useCallback(() => {
    setSelectedBulkItems([]);
    if (transitionItems) {
      setTransitionItems(undefined);
    }
    setBulkDemoteButtonClicked(false);
    setIsDemoting(false);
    setCompletingDemotion(false);
  }, [transitionItems, setSelectedBulkItems, setTransitionItems, setBulkDemoteButtonClicked]);

  useEffect(() => {
    loadItems(startIndex).catch();
  }, [loadItems, startIndex]);

  useEffect(() => {
    reloadItems().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption, nationalAccountFilter, sort, showNew]);

  useEffect(() => {
    // Make sure to clear out bulk demote recoil state when starting process over
    if (!bulkDemoteButtonClicked) {
      handleCancelBulkDemote();
    }
  }, [bulkDemoteButtonClicked, handleCancelBulkDemote]);

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  return (
    <div>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '.5rem 2rem 0 2rem',
        }}
      >
        <ShowNewAndBulkDemote
          showNew={showNew}
          count={count}
          handleShowNew={handleShowNew}
          handleBulkDemoteButton={handleBulkDemoteButton}
          bulkDemoteButtonClicked={bulkDemoteButtonClicked}
        />
        <NationalAccountFilter setNationalAccountFilter={setNationalAccountFilter} />
      </Grid>
      {bulkDemoteButtonClicked && !isDemoting && !completingDemotion && (
        <BulkDemoteButtons
          handleCancelBulkDemote={handleCancelBulkDemote}
          setIsDemoting={setIsDemoting}
          count={count}
        />
      )}
      {completingDemotion && (
        <ProjectLeadsBulkDemoteConfirmation clearBulkDemote={handleCancelBulkDemote} />
      )}
      {isDemoting && bulkDemoteButtonClicked && (
        <QualificationBulkDemoteButtons
          setTransitionItems={setTransitionItems}
          handleCancelBulkDemote={handleCancelBulkDemote}
          setCompletingDemotion={setCompletingDemotion}
          completingDemotion={completingDemotion}
          count={count}
          demotionReasons={demotionReasons}
        />
      )}
      {!completingDemotion && (
        <>
          <div style={{ margin: '0 2rem 1rem 2rem' }}>
            <Table
              headerCells={headCells}
              order={order}
              orderBy={orderBy}
              rowCount={rowCount}
              rows={rows}
              scoreStatus={scoreStatus}
              startIndex={startIndex}
              hasMoreItems={hasMoreItems}
              onRequestSort={handleSortRequest}
              setStartIndex={setStartIndex}
              handleClickRow={handleClickRow}
              reloadItems={reloadItems}
              errorMessage={errorMessage}
              isNewKey={'isNew'}
              handleCheckbox={handleCheckbox}
              bulkDemoteButtonClicked={bulkDemoteButtonClicked}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectLeadsFavoritesTable;
