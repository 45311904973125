import { Column } from 'react-table';

import {
  renderDate,
  renderEvent,
  renderItem,
  renderProjectScore,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { hyperlinkBlue, success } from '../../../../theme';
import PvfProject from '../../../../models/pvfProject';
import { ColumnDraggable } from '../../../Common/Tables/ReactTable/ReactTable';

export const accountProjectLeadsColumns: (Column<PvfProject> & ColumnDraggable)[] = [
  {
    id: 'id',
    Header: 'Id',
    disableSortBy: true,
    disableResizing: true,
    disableDrag: true,
    accessor: (row: PvfProject) => row.id,
  },
  {
    id: 'projectScore',
    Header: 'Score',
    disableSortBy: false,
    disableResizing: true,
    disableDrag: true,
    accessor: (row: PvfProject, idx) => {
      const stroke = idx % 2 ? success : hyperlinkBlue;
      return renderProjectScore(row.projectScore, stroke, 30, 60, '20px');
    },
  },
  {
    id: 'projectName',
    Header: 'Project Name',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.projectName, false, undefined, true),
  },
  {
    id: 'biddate',
    Header: 'Bid Date',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderDate(row.bidDate),
  },
  {
    id: 'actionStage',
    Header: 'Action Stage',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderItem(row.mappedActionStage, true),
  },
  {
    id: 'latestEventSource',
    Header: 'Latest Event',
    disableSortBy: false,
    disableResizing: false,
    disableDrag: true,
    accessor: (row: PvfProject) => renderEvent(row.dataSource, row.lastUpdated, row.latestEvent),
  },
];

export const defaultHiddenColumnIdsAccountsPL = ['id'];

export const defaultInitialStateAccountsPL = {
  hiddenColumns: defaultHiddenColumnIdsAccountsPL,
  pageSize: 0,
  sortBy: [
    {
      id: 'projectScore',
      desc: true,
    },
  ],
};
