import React from 'react';
import { Box, CircularProgress, styled } from '@material-ui/core';

const LoaderEl = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

interface IProps {
  size?: number;
  minHeight?: number;
}

export default function Loader({ size = 100, minHeight = 500 }: IProps): JSX.Element {
  return (
    <LoaderEl style={{ minHeight }}>
      <CircularProgress size={size} data-testid="progress" />
    </LoaderEl>
  );
}
