import React from 'react';
import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { black } from '../../theme';

interface IProps {
  count: number;
  handleShowNew: () => void;
  showNew: boolean;
  bulkDemoteButtonClicked?: boolean;
  handleBulkDemoteButton?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    padding: '.25rem .5rem',
    textTransform: 'none',
  },
  item: {
    margin: '.25rem',
  },
  greyedItem: {
    color: '#a1a2a3',
    margin: '.25rem',
  },
}));

const ShowNewAndBulkDemote: React.FC<IProps> = ({
  count,
  handleShowNew,
  showNew,
  handleBulkDemoteButton,
  bulkDemoteButtonClicked,
}) => {
  const classes = useStyles({ bulkDemoteButtonClicked });
  return (
    <Grid className={classes.root}>
      <Checkbox
        style={{ color: black, padding: '0' }}
        defaultChecked={showNew}
        value={showNew}
        onChange={handleShowNew}
        disabled={bulkDemoteButtonClicked}
      />
      <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
        Show Only New
      </Typography>
      {!bulkDemoteButtonClicked && (
        <Button
          style={{
            color: '#0C4377',
            backgroundColor: 'white',
            border: '1px solid #0C4377',
            boxShadow: 'none',
            margin: '0 0.5rem',
          }}
          variant={'contained'}
          color={bulkDemoteButtonClicked ? 'primary' : undefined}
          onClick={handleBulkDemoteButton}
          disableRipple={true}
          className={classes.button}
        >
          <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
            Demote Leads
          </Typography>
        </Button>
      )}

      <Typography
        className={bulkDemoteButtonClicked ? classes.greyedItem : classes.item}
        variant="subtitle1"
        id="leads-results"
      >
        {count} Results
      </Typography>
    </Grid>
  );
};

export default ShowNewAndBulkDemote;
