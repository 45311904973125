import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AccountsList from './AccountsList';
import AccountDetails from './AccountDetails';
import AccountContacts, { AccountContactType } from './AccountContacts';
import AccountsMergeList from './AccountsMergeList';
import AccountsMergeModal from './AccountsMergeModal';
import AccountsProjectLeads from './AccountsProjectLeads';

import AccountsPageHeader from '../AccountsPageHeader';
import { useAccountsPageStyles } from '../AccountsPage.styles';
import TitleBorder from '../../../Common/TitleBorder';
import {
  accountsCountState,
  accountsMergeIdState,
  selectedAccountState,
} from '../../../../state/atoms/accounts';
import AccordionDropDown from '../../../Common/AccordionDropDown';
import { FilterData, Optional } from '../../../../types';
import { clearFiltersState } from '../../../../state/atoms/filters';

interface AccountsHomeProps {
  filterOption: Optional<FilterData>;
}

const AccountsHome: React.FC<AccountsHomeProps> = ({ filterOption }) => {
  const classes = useAccountsPageStyles();
  const border = `0.25px solid #0C4377`;

  const selectedAccountId = useRecoilValue(selectedAccountState);
  const accountsCount = useRecoilValue(accountsCountState);
  const [accountsMergeId, setAccountsMergeId] = useRecoilState(accountsMergeIdState);
  const setClearFilters = useSetRecoilState(clearFiltersState);

  const [contactType, setContactType] = useState<AccountContactType>(AccountContactType.Contact);
  const [contactsCount, setContactsCount] = useState<number>(0);

  useEffect(() => {
    // clears filters when viewing duplicate accounts
    if (accountsMergeId) {
      setClearFilters(true);
    }
  }, [accountsMergeId, setClearFilters]);

  useEffect(() => {
    //reset mergeAccountId when reloading Home Tab
    setAccountsMergeId(null);
  }, [setAccountsMergeId]);

  return (
    <Grid className={classes.body}>
      <AccountsPageHeader count={accountsCount} />
      <Grid className={classes.main}>
        <Grid className={classes.list}>
          {!accountsMergeId ? <AccountsList filterOption={filterOption} /> : <AccountsMergeList />}
        </Grid>
        <Grid className={classes.mainRight}>
          <Grid className={classes.rightTop}>
            <TitleBorder leftItem={'Details'} variantLeft={'body1'} border={border}>
              <AccountDetails organizationId={selectedAccountId} />
            </TitleBorder>
            <TitleBorder
              leftItem={
                <AccordionDropDown
                  handleSelectedValue={setContactType}
                  options={Object.keys(AccountContactType) as AccountContactType[]}
                  variant={'body1'}
                />
              }
              count={contactsCount}
              variantLeft={'body1'}
              border={border}
            >
              <AccountContacts
                contactType={contactType}
                accountId={selectedAccountId}
                setContactsCount={setContactsCount}
              />
            </TitleBorder>
          </Grid>
          <Grid className={classes.rightBottom}>
            <TitleBorder leftItem={'Project Leads'} variantLeft={'body1'} border={border}>
              <AccountsProjectLeads organizationId={selectedAccountId} />
            </TitleBorder>
          </Grid>
        </Grid>
      </Grid>
      <AccountsMergeModal />
    </Grid>
  );
};

export default memo(AccountsHome);
