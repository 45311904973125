import React, { memo, useCallback, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Grid, IconButton, makeStyles, Tab, Typography } from '@material-ui/core';
import { useRecoilState } from 'recoil';

import {
  defaultInitialStateAccountsPL,
  accountProjectLeadsColumns,
} from './AccountsPLTableColumns';

import OpenListIcon from '../../../../resources/images/OpenListIcon.svg';
import { FetchDataProps, ReactTable } from '../../../Common/Tables/ReactTable/ReactTable';
import PvfProject from '../../../../models/pvfProject';
import { selectedAccountProjectLeadsState } from '../../../../state/atoms/accounts';
import { getAccountsPageProjects } from '../../../../api/organizations';
import { tabStyles } from '../../../Common/CleoTab.styles';
import EmptyBox from '../../../Common/EmptyBox';
import { sortColumns } from '../../Leads/Archive/Archive.config';
import { black, lightGreen } from '../../../../theme';

enum Tabs {
  Active = 'active',
  Converted = 'converted',
  Demoted = 'demoted',
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'scroll',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabPanel: {
    height: '100%',
    padding: 0,
  },
  empty: {
    backgroundColor: lightGreen,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: black,
  },
}));

type AccountProjectLeadsProps = {
  organizationId: string;
};

const AccountsProjectLeads: React.FC<AccountProjectLeadsProps> = ({ organizationId }) => {
  const classes = useStyles();
  const [accountProjectLeads, setAccountProjectLeads] = useRecoilState(
    selectedAccountProjectLeadsState(organizationId),
  );

  const [tab, setTab] = useState<Tabs>(Tabs.Active);

  const loadProjectLeads = useCallback(
    async ({ sortId, sortIsDesc }: FetchDataProps) => {
      let projectLeads;
      if (organizationId) {
        projectLeads = await getAccountsPageProjects(
          organizationId,
          {
            sort: {
              orderBy: sortId || 'projectScore',
              order: sortIsDesc || 'desc',
            },
            startIndex: 0,
            limit: undefined,
          },
          sortColumns,
        );
      } else {
        projectLeads = [] as PvfProject[];
      }
      setAccountProjectLeads({ loaded: true, projectLeads });
    },
    [setAccountProjectLeads, organizationId],
  );

  useEffect(() => {
    if (!accountProjectLeads.loaded) {
      loadProjectLeads({});
    }
  }, [loadProjectLeads, accountProjectLeads]);

  useEffect(() => {
    setTab(Tabs.Active);
  }, [organizationId]);

  const handleTabChange = useCallback(
    (e, nextTab: Tabs) => {
      e.preventDefault();
      setTab(nextTab);
    },
    [setTab],
  );

  const handleOpenList = () => {
    console.log('Open List of Project Leads');
  };

  return (
    <Grid className={classes.root}>
      <TabContext value={tab}>
        <Grid className={classes.flexRow}>
          <TabList onChange={handleTabChange} aria-label="accounts project lead tabs">
            {(Object.keys(Tabs) as Array<keyof typeof Tabs>).map((key, idx) => {
              const { loaded, projectLeads } = accountProjectLeads;
              const count = projectLeads.filter(acc => acc.projectStatus === Tabs[key]).length;
              return (
                <Tab
                  key={idx}
                  classes={tabStyles({ fontSize: '1rem' })}
                  label={`${key} (${loaded ? count : ''})`}
                  value={Tabs[key]}
                />
              );
            })}
          </TabList>
          <IconButton onClick={handleOpenList}>
            <img src={OpenListIcon} alt={'open project leads list icon'} />
          </IconButton>
        </Grid>
        {(Object.keys(Tabs) as Array<keyof typeof Tabs>).map((key, idx) => {
          // filter the pvfs to display (based on tab)
          const { loaded, projectLeads } = accountProjectLeads;
          const displayPLs = projectLeads.filter(acc => acc.projectStatus === Tabs[key]);
          return (
            <TabPanel key={idx} value={Tabs[key]} className={classes.tabPanel}>
              {!displayPLs.length && loaded && (
                <EmptyBox className={classes.empty}>
                  <Typography
                    variant={'subtitle1'}
                  >{`There are no ${Tabs[key]} projects at this time`}</Typography>
                </EmptyBox>
              )}
              {!!displayPLs.length && (
                <ReactTable<PvfProject>
                  columns={accountProjectLeadsColumns}
                  data={displayPLs}
                  loading={!loaded}
                  fetchData={loadProjectLeads}
                  pageCount={0}
                  initialTableState={defaultInitialStateAccountsPL}
                />
              )}
            </TabPanel>
          );
        })}
      </TabContext>
    </Grid>
  );
};

export default memo(AccountsProjectLeads);
