import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { List, makeStyles } from '@material-ui/core';

import AccountCard from './AccountCard';

import {
  accountsMergeIdState,
  accountsMergeListState,
  selectedAccountState,
} from '../../../../state/atoms/accounts';
import Loader from '../../../Common/Loader';
import { getAccountDuplicates } from '../../../../api/organizations';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: 0,
    paddingRight: '0',
    overflow: 'scroll',
  },
});

const AccountsMergeList: React.FC = () => {
  const [selectedAccountId, setSelectedAccountId] = useRecoilState(selectedAccountState);
  const [accountMergeList, setAccountMergeList] = useRecoilState(accountsMergeListState);
  const accountsMergeId = useRecoilValue(accountsMergeIdState);
  const [loading, setLoading] = useState(false);
  const loadMergeAccounts = useCallback(async () => {
    if (accountsMergeId) {
      setLoading(true);
      const accounts = await getAccountDuplicates(accountsMergeId);
      setAccountMergeList(accounts);
      setLoading(false);
    }
  }, [setAccountMergeList, accountsMergeId]);

  useEffect(() => {
    // loading merge accounts
    setSelectedAccountId('');
    setAccountMergeList([]);
    loadMergeAccounts();
  }, [loadMergeAccounts, setAccountMergeList, setSelectedAccountId]);

  useEffect(() => {
    // updating selected account
    if (accountMergeList.length && selectedAccountId === '') {
      setSelectedAccountId(accountMergeList[0]?.id || '');
    }
  }, [accountMergeList, selectedAccountId, setSelectedAccountId]);

  const classes = useStyles();

  return (
    <List className={classes.list}>
      {loading && <Loader size={40} minHeight={100} />}
      {accountMergeList.map(acc => {
        return (
          <div key={acc.id} style={{ marginBottom: '1rem' }}>
            <AccountCard
              isSelected={selectedAccountId === acc.id}
              account={acc}
              onClick={() => setSelectedAccountId(acc.id || '')}
            />
          </div>
        );
      })}
    </List>
  );
};

export default AccountsMergeList;
