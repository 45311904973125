import { Chip, Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@material-ui/icons/Delete';

import useSavedFiltersRowStyles from './SavedFiltersRow.styles';
import SavedFiltersRowItem from './SavedFiltersRowItem';

import { TagsDisplay, UserFilterNameResponse } from '../../../../types';
import { deleteFilterName } from '../../../../api/filters';
import ConfirmationDialog from '../../../Common/ConfirmationDialog';
import { CleoObjectType } from '../../../../models/common';
import { hyperlinkBlue, lightGreen } from '../../../../theme';
import { toTitleCase } from '../../../../utils/string';
const useStyles = makeStyles(() => ({
  filterTagsScrollContainer: {
    display: 'flex',
    maxHeight: '4.25rem',
    overflow: 'hidden',
  },
  filterTags: { display: 'flex', flexWrap: 'wrap', rowGap: '.25rem' },
  filterTag: {
    fontWeight: 'normal',
    border: '.5px solid black',
    margin: '0 .125rem',
    width: '11rem',
  },
  chipLabel: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 .25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '.875rem',
  },
  chipLabelLong: {
    display: 'flex',
    justifyContent: 'start',
    width: '10.5rem',
    padding: '0 .25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '.875rem',
  },
}));

interface IProps {
  row: UserFilterNameResponse;
  reloadSavedFiltersList: (savedFilterStartIndex: number, deletedUserFilterId?: string) => void;
  savedFilterStartIndex: number;
  cleoObjectType?: CleoObjectType;
  isOdd: boolean;
}
const SavedFiltersRow: React.FC<IProps> = ({
  row,
  reloadSavedFiltersList,
  savedFilterStartIndex,
  cleoObjectType = CleoObjectType.Addresses,
  isOdd,
}: IProps) => {
  const classes = useSavedFiltersRowStyles();
  const classesTags = useStyles();
  const history = useHistory();
  const { user } = useAuth0();

  const [confirmDeleteFilterOpen, setConfirmDeleteFilterOpen] = useState<boolean>(false);
  const [filterIdToDelete, setFilterIdToDelete] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');

  const historyPrefix =
    cleoObjectType === CleoObjectType.Addresses
      ? '/leads/inbound/savedFilters/'
      : '/project-leads/savedFilters/';

  const handleDeleteFilter = useCallback(
    async (userFilterId: string) => {
      try {
        await deleteFilterName(userFilterId);
        reloadSavedFiltersList(savedFilterStartIndex, userFilterId);
      } catch (e: any) {
        setErrorMessage(e.response.data.message);
      }
    },
    [reloadSavedFiltersList, savedFilterStartIndex],
  );

  const handleRowClick = useCallback((id: string) => {
    setConfirmDeleteFilterOpen(true);
    setFilterIdToDelete(id);
  }, []);

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }

  return (
    <>
      <Grid item className={classes.row} style={{ backgroundColor: isOdd ? '' : lightGreen }}>
        <Grid className={classes.savedFilterRoot}>
          <Grid
            container
            alignItems="center"
            spacing={5}
            className={classes.savedFilterMain}
            onClick={() => history.push(`${historyPrefix}${row.id}`)}
          >
            <SavedFiltersRowItem itemClass={classes.savedFilterName} rowSize={2}>
              {row.filter_name}
            </SavedFiltersRowItem>
            <SavedFiltersRowItem
              itemClass={classes.savedFilterTags}
              rowSize={6}
              useTypography={false}
            >
              <div className={classesTags.filterTagsScrollContainer}>
                <div className={classesTags.filterTags}>
                  {row.filter_tags.tags.slice(0, 8).map((obj: TagsDisplay, i: number) => {
                    const tagLabel = `${toTitleCase(obj.label)}: ${
                      obj.key === 'value'
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(Number(obj.value))
                        : obj.value
                    }`;
                    return (
                      <div key={`${i}`}>
                        <Chip
                          style={{ backgroundColor: 'white' }}
                          label={tagLabel}
                          variant="outlined"
                          size="medium"
                          classes={{
                            label:
                              tagLabel.length < 25
                                ? classesTags.chipLabel
                                : classesTags.chipLabelLong,
                            outlined: classesTags.filterTag,
                          }}
                        />
                      </div>
                    );
                  })}
                  {row.filter_tags.tags.length > 8 ? (
                    <div style={{ alignSelf: 'end', marginLeft: '.25rem' }}>
                      <strong>{'...'}</strong>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </SavedFiltersRowItem>
            <SavedFiltersRowItem itemClass={classes.savedFilterOwner} rowSize={1}>
              {row.user.fullName || row.user.email}
            </SavedFiltersRowItem>
            <SavedFiltersRowItem itemClass={classes.savedFilter} rowSize={1}>
              {new Date(row.created_at).toLocaleDateString()}
            </SavedFiltersRowItem>
            <SavedFiltersRowItem itemClass={classes.savedFilter} rowSize={1}>
              {new Date(row.updated_at).toLocaleDateString()}
            </SavedFiltersRowItem>
            {row.user.email === user.email && (
              <SavedFiltersRowItem rowSize={1} handleRowClick={handleRowClick} rowId={row.id}>
                <DeleteIcon style={{ color: hyperlinkBlue }} />
              </SavedFiltersRowItem>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={confirmDeleteFilterOpen}
        title={'Deleting Saved Filter'}
        content={'Are you sure you want to delete this filter?'}
        onConfirm={() => {
          filterIdToDelete && handleDeleteFilter(filterIdToDelete);
          setConfirmDeleteFilterOpen(false);
          setFilterIdToDelete('');
        }}
        onCancel={() => setConfirmDeleteFilterOpen(false)}
      />
    </>
  );
};

export default SavedFiltersRow;
