import { Comparators, FilterKey, FilterOptionsItem, TagsDisplay } from '../../../../../types';
import { stateAbbreviations } from '../../../../../utils/stateAbbreviations';

interface IHandleSelectStateCountyStateProps {
  countyStateIn: string[];
  countyFilterTagsToAdd: TagsDisplay[];
  countiesInEachState: Record<string, FilterOptionsItem[]>;
  filterItemOption: FilterOptionsItem;
}

/**
 * Function helps create a list of counties to add/remove from display tags AND list to select/deselect for filters
 * @param countyStateIn - list of counties in the state
 * @param countyFilterTagsToAdd - Tags Display of the counties inside the state
 * @param countiesInEachState - recoil value that contains all the mapping of the counties (FilterOptionsItem) in each state (string)
 * @param filterItemOption - FilterItemOption that just clicked (state)
 */
export const updateCountiesAssociatedWithState = ({
  countyStateIn,
  countyFilterTagsToAdd,
  countiesInEachState,
  filterItemOption,
}: IHandleSelectStateCountyStateProps) => {
  countiesInEachState[stateAbbreviations[filterItemOption.value as string]].forEach(option => {
    const filterValue = option.value as string;
    const filterLabel = option.label as string;
    countyStateIn.push(filterValue);
    countyFilterTagsToAdd.push({
      label: 'County',
      key: FilterKey.CountyState,
      value: filterLabel,
      comparator: Comparators.In,
      filterValue: filterValue,
      isInput: false,
    });
  });
};

/**
 * Function is expecting to receive a countyState filter value (string)
 * in the form of `COUNTY - ST`.
 * Returns the abbreviated state
 * */
export const stateAssociatedWithCountyState = ({
  countyStateValue,
}: {
  countyStateValue: string;
}) => {
  const stateAbb = countyStateValue?.split('-')[1].trim();
  return stateAbb;
};
