import { makeStyles } from '@material-ui/core';
// import { ReactTableCellProps } from './ReactTableCell';

export const useReactTableCellStyles = makeStyles(() => ({
  root: {
    border: 'none',
    fontSize: '0.75rem',
    padding: '0.5rem',
    // width: '10% !important',
  },
  scoreIcon: {
    // width: '10% !important',
    justifyContent: 'center',
  },
  rowCellText: {
    width: '100%',
    // '& svg': {
    //   fill: ({ cell }: ReactTableCellProps<Record<any, any>>) =>
    //     cell.column.id === '_selector' ? '#0F1A24' : 'unset',
    // },
  },
  gearIconCell: {
    width: '5% !important',
  },
}));
