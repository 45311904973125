import { ScoreStatus } from '../../../../models/lead';
import { toTitleCase } from '../../../../utils/string';
import { FilterData } from '../../../../types';
import { StatusBarTabItem } from '../LeadsTable/StatusBar';
import { SearchAddress } from '../../UniversalSearch/addressHeadCells';
import { TransitionDetails } from '../../../../models/project';

export const ArchiveMenu: StatusBarTabItem<ScoreStatus>[] = [
  {
    status: ScoreStatus.Converted,
    text: toTitleCase(ScoreStatus.Converted),
    defaultFilter: {},
  },
  {
    status: ScoreStatus.Demoted,
    text: toTitleCase(ScoreStatus.Demoted),
    defaultFilter: { projectStatus: { eq: 'demoted' } },
  },
];

// TODO: Rename, Interface of Project View Filter
// Demoted Archive
export interface IProject {
  id: string;
  projectId: string;
  projectName: string;
  projectStatus: string;
  hide: boolean;
  propertyId: string;
  address1: string;
  city: string;
  state: string;
  actionStage: string;
  mappedActionStage: string;
  value: number;
  value1: number;
  bidDate: string;
  eventDate: string;
  projectType: string;
  categories: string;
  lastUpdated: string;
  projectScore: number;
  zipcode: string;
  publishDate: string;
  size: string;
  countyState: string;
  cityState: string;
  latestEvent: string;
  latestEventDate: string;
  isNew: boolean;
  dataSource: string;
  specAlerts: string;
  projectNote: string;
  dodgeVersionNumber: string;
  projectUrl: string;
  ownerId: string;
  primaryProjectType: string;
  transitionDetails: TransitionDetails;
  isFavorite: boolean;
  hasNationalAccount: boolean;
  hasNationalTargetAccount: boolean;
  region: string;
  county: string;
  outcome: string;
  positiveKeywords: string;
  negativeKeywords: string;
  externalId: string;
}

export enum ArchivedStatus {
  Converted = 'converted',
  Demoted = 'demoted',
}

export interface ProjectLeadTable {
  property: string;
  event: string;
  projectId: string;
  projectName: string;
  biddate: string;
  actionStage: string;
  publishDate: string;
  hide: boolean;
  value: number;
  primaryProjectType: string;
  projectType: string;
  categories: string;
  lastUpdated: string;
  projectScore: number;
  latestEventSource: string;
  projectStatus: string;
  isFavorite: string;
  county: string;
  state: string;
  region: string;
}

export type SortOrder = 'asc' | 'desc';

export interface SortTable<T> {
  orderBy: keyof T;
  order: SortOrder;
}

export interface SortArchive<T> {
  orderBy: keyof T;
  order: SortOrder;
}

export interface AddressSortArchive {
  orderBy: keyof SearchAddress;
  order: SortOrder;
}

export type SortColumns<T> = Record<keyof T, string[]>;

// Todo: Rename to sortColumnsPLTable
export const sortColumns: SortColumns<ProjectLeadTable> = {
  property: ['city', 'state', 'address1'],
  event: ['eventDate', 'score'],
  projectId: ['projectId'],
  projectName: ['projectName'],
  biddate: ['bidDate'],
  actionStage: ['mappedActionStage'],
  publishDate: ['eventDate'],
  hide: ['hide'],
  value: ['value'],
  projectType: ['projectType'],
  primaryProjectType: ['primaryProjectType'],
  categories: ['categories'],
  lastUpdated: ['lastUpdated'],
  projectScore: ['projectScore'],
  latestEventSource: ['dataSource', 'lastUpdated'],
  projectStatus: [''],
  isFavorite: ['projectScore', 'lastUpdated'],
  county: ['county', 'state'],
  state: ['state', 'county'],
  region: ['region'],
};

export interface ViewFilterRequest<T> {
  sort: SortTable<T>;
  startIndex: number;
  filterOption?: FilterData;
  limit?: number;
}
