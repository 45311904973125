import { LeadType, ScoreStatus } from '../models/lead';

enum RoutePrefix {
  ProjectLeads = '/project-leads',
  Leads = '/leads',
  Network = '/network',
  AtAGlance = '/at-a-glance',
  UniversalSearch = '/search',
  Accounts = '/accounts',
  Analytics = '/analytics',
}

export const getProjectLeadsPagePath = (): string => RoutePrefix.ProjectLeads;
export const getProjectLeadsDetailsPagePath = (projectId: string): string =>
  [RoutePrefix.ProjectLeads, 'details', projectId].join('/');

export const getLeadsPagePath = (type: LeadType, status?: ScoreStatus): string =>
  [RoutePrefix.Leads, type, status].filter(x => !!x).join('/');

export const getLeadDetailsPagePath = (propertyId: string): string =>
  [RoutePrefix.Leads, propertyId].join('/');

export const getNetworkPagePath = (): string => RoutePrefix.Network;

export const getAccountsPagePath = (): string => RoutePrefix.Accounts;

export const getAnalyticsPagePath = (): string => RoutePrefix.Analytics;

export const getAtAGlancePagePath = (): string => RoutePrefix.AtAGlance;

export const getUniversalSearchPagePath = (): string => `${RoutePrefix.UniversalSearch}`;

export const getStakeholderDetailsPagePath = (id: string): string =>
  `${getNetworkPagePath()}/contacts/${id}`;

export const getOrganizationDetailsPagePath = (id: string): string =>
  `${getNetworkPagePath()}/accounts/${id}`;

export const getUniversalSearchDetailsPagePath = (search: string): string =>
  `${getUniversalSearchPagePath()}/${search}`;

export const getAccountDetailsPagePath = (id: string): string =>
  `${getAccountsPagePath()}/details/${id}`;
