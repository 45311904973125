import React from 'react';
import { Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import useSavedFiltersTableStyles from './SavedFiltersTable.styles';
import SavedFiltersRow from './SavedFiltersRow';

import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserFilterNameResponse } from '../../../../types';
import Loader from '../../../Common/Loader';
import { CleoObjectType } from '../../../../models/common';

interface IProps<T> {
  savedFiltersList: UserFilterNameResponse[];
  reloadSavedFiltersList: (savedFilterStartIndex: number, deletedUserFilterId?: string) => void;
  savedFilterStartIndex: number;
  setSavedFilterStartIndex: (startIndex: any) => void;
  savedFiltersHaveMoreItems: boolean;
  scoreStatus: T;
  cleoObjectType?: CleoObjectType;
}

function SavedFiltersTable<T>({
  savedFiltersList,
  reloadSavedFiltersList,
  savedFilterStartIndex,
  setSavedFilterStartIndex,
  savedFiltersHaveMoreItems,
  scoreStatus,
  cleoObjectType = CleoObjectType.Addresses,
}: IProps<T>) {
  const classes = useSavedFiltersTableStyles();

  return (
    <div id="savedFiltersScrollableDiv" className={classes.infiniteScrollContainer}>
      <InfiniteScroll
        dataLength={savedFiltersList.length}
        next={() => setSavedFilterStartIndex((startIndex: any) => startIndex + DEFAULT_PAGE_SIZE)}
        hasMore={savedFiltersHaveMoreItems}
        loader={savedFiltersHaveMoreItems && <Loader size={40} minHeight={100} />}
        scrollableTarget="savedFiltersScrollableDiv"
      >
        <Grid container direction="column" className={classes.table}>
          {savedFiltersList.map((row, i) => (
            <div key={`${scoreStatus}-${row.id}-${i}`}>
              <SavedFiltersRow
                savedFilterStartIndex={savedFilterStartIndex}
                reloadSavedFiltersList={reloadSavedFiltersList}
                row={row}
                cleoObjectType={cleoObjectType}
                isOdd={!!(i % 2)}
              />
            </div>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
}

export default SavedFiltersTable;
