import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useAccountsMergeModalStyle } from './AccountsMergeModal.style';

import {
  accountsMergeIdsSelectedState,
  accountsMergeIdState,
  accountsMergeListState,
  accountsMergeModalState,
} from '../../../../state/atoms/accounts';
import { primaryBlue, white } from '../../../../theme';
import { useMessages } from '../../../../state/contexts';
import ContainedButton from '../../../Common/ContainedButton';
import { IAccountDuplicateRequest } from '../../../../models/accountListAccount';
import { mergeAccountDuplicates } from '../../../../api/organizations';

const AccountsMergeModal: React.FC = () => {
  const [modalOpen, setModalOpen] = useRecoilState(accountsMergeModalState);
  const [selectedAccountsToMergeIds, setSelectedAccountsToMergeIds] = useRecoilState(
    accountsMergeIdsSelectedState,
  );
  const [mergeListAccounts, setMergeListAccounts] = useRecoilState(accountsMergeListState);
  const setAccountsMergeId = useSetRecoilState(accountsMergeIdState);
  const [primaryId, setPrimaryId] = useState<null | string>(null);
  const [confirmationState, setConfirmationState] = useState(false);
  const { setPageFetching, setSuccessMessage, setErrorMessage } = useMessages();
  const label = 'accounts-merge';
  const classes = useAccountsMergeModalStyle();

  const selectedAccounts = mergeListAccounts.filter(account =>
    selectedAccountsToMergeIds.has(account.id),
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryId((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    setConfirmationState(false);
    setModalOpen(false);
    setPrimaryId(null);
  };

  const handleMerge = async () => {
    if (primaryId) {
      const payload: IAccountDuplicateRequest = {
        primary: primaryId,
        duplicates: [...selectedAccountsToMergeIds].filter(id => id !== primaryId),
      };
      try {
        setModalOpen(false);
        setPageFetching(true);
        await mergeAccountDuplicates(payload);
        setSuccessMessage('The accounts have been merged!');
      } catch (err) {
        setErrorMessage('The accounts have failed to merge: ', err);
      }
      setPageFetching(false);
      setAccountsMergeId('');
      setMergeListAccounts([]);
      setSelectedAccountsToMergeIds(new Set());
      handleClose();
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby={`${label}-modal-title`}
      aria-describedby={`${label}-modal-description`}
      hideBackdrop
    >
      <Fade in={modalOpen}>
        <Grid className={classes.root}>
          <IconButton onClick={handleClose} style={{ padding: 0 }} className={classes.close}>
            <CloseIcon />
          </IconButton>
          <Grid className={classes.header}>
            <Typography className={classes.title} variant={'h5'}>
              Accounts to Merge
            </Typography>
            <Typography variant={'h6'}>
              Total Selected: {selectedAccountsToMergeIds.size}
            </Typography>
            <hr className={classes.hr} />
          </Grid>
          <Grid style={{ overflow: 'scroll', width: '100%' }}>
            <table className={classes.table}>
              <thead>
                <tr className={classes.tr}>
                  <th className={classes.c1}>Primary</th>
                  <th className={classes.c2}>Account Name</th>
                  <th className={classes.c2}>Address</th>
                </tr>
              </thead>
              <tbody>
                {selectedAccounts.map(account => {
                  return (
                    <tr className={classes.tr} key={account.id}>
                      <td className={classes.c1}>
                        <RadioGroup
                          aria-label={'make-primary-account'}
                          name={'accountMerge'}
                          defaultChecked={false}
                          value={primaryId}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            control={<Radio color={'default'} disabled={confirmationState} />}
                            label={''}
                            value={account.id}
                          />
                        </RadioGroup>
                      </td>
                      <td className={classes.c2}>{account.name}</td>
                      <td className={classes.c2}>{account.fullAddress}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Grid>
          {!confirmationState && (
            <Grid className={classes.footer}>
              <Grid className={classes.footerRow}>
                <ContainedButton
                  bgColor={primaryBlue}
                  disabled={!primaryId}
                  onClick={() => setConfirmationState(true)}
                  style={{ minWidth: '125px', margin: 0 }}
                >
                  MERGE
                </ContainedButton>
                <ContainedButton bgColor={white} textColor={primaryBlue} onClick={handleClose}>
                  CANCEL
                </ContainedButton>
              </Grid>
            </Grid>
          )}
          {confirmationState && (
            <Grid className={classes.footer}>
              <Typography className={classes.footerRow} variant={'body1'}>
                Are you sure you want to&nbsp;
                <strong>
                  {selectedAccountsToMergeIds.size > 2
                    ? 'merge all'
                    : selectedAccountsToMergeIds.size === 1
                    ? 'make this'
                    : 'merge this'}
                </strong>
                &nbsp;
                {selectedAccountsToMergeIds.size > 2
                  ? ' these subaccounts '
                  : selectedAccountsToMergeIds.size === 1
                  ? 'account '
                  : ' subaccount '}
                {selectedAccountsToMergeIds.size === 1
                  ? 'the main account?'
                  : 'to the main account?'}
              </Typography>
              <Grid className={classes.footerRow}>
                <ContainedButton
                  bgColor={primaryBlue}
                  onClick={handleMerge}
                  style={{ minWidth: '125px', margin: 0 }}
                >
                  Yes
                </ContainedButton>
                <ContainedButton bgColor={white} textColor={primaryBlue} onClick={handleClose}>
                  No
                </ContainedButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AccountsMergeModal;
