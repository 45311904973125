import cloneDeep from 'lodash/cloneDeep';

import { SortColumns, ViewFilterRequest } from '../Components/Pages/Leads/Archive/Archive.config';
import { sortDict } from '../models/lead';
import { FilterDataOr, FilterValue } from '../types';
import { actionStageValueMap, DEFAULT_PAGE_SIZE } from '../constants';

export const sanitizeFilterConfig = <T>(
  { sort, startIndex, filterOption, limit }: ViewFilterRequest<T>,
  sortColumns: SortColumns<T>,
  model = 'addresses',
) => {
  const filterCopy = cloneDeep(filterOption);
  const { order, orderBy } = sort;
  const dir = sortDict[order];
  const query: FilterDataOr = { or: [] };
  /**
   * We need to make sure we push all filters into the query.or array
   * and delete the object from the filterCopy so that backend logic can
   * handle large amounts of filters
   */

  if (filterCopy?.projectStatus) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.projectStatus).map(filterKey => ({
        projectStatus: { [filterKey]: filterCopy.projectStatus[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.projectStatus;
  }

  if (filterCopy?.ownerId) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.ownerId).map(filterKey => ({
        ownerId: { [filterKey]: filterCopy.ownerId[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.ownerId;
  }

  if (filterCopy?.state) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.state).map(filterKey => ({
        state: { [filterKey]: filterCopy.state[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.state.in;
  }

  if (filterCopy?.county) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.county).map(filterKey => ({
        county: { [filterKey]: filterCopy.county[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.county.in;
  }

  if (filterCopy?.countyState) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.countyState).map(filterKey => ({
        countyState: { [filterKey]: filterCopy.countyState[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.countyState.in;
  }

  if (filterCopy?.province) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.province).map(filterKey => ({
        state: { [filterKey]: filterCopy.province[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.province.in;
  }

  if (filterCopy?.keywords && filterCopy.keywords.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.keywords.contains).map(value => ({
        keywords: { contains: value },
      })),
    );
    delete filterCopy.keywords.contains;
  }

  if (filterCopy?.accountRatings && filterCopy.accountRatings.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.accountRatings.contains).map(value => ({
        accountRatings: { contains: value },
      })),
    );
    delete filterCopy.accountRatings.contains;
  }

  if (filterCopy?.region) {
    Array.prototype.push.apply(
      query.or,
      Object.keys(filterCopy.region).map(filterKey => ({
        region: { [filterKey]: filterCopy.region[filterKey as keyof FilterValue] },
      })),
    );
    delete filterCopy.region.in;
  }

  if (filterCopy?.specAlerts && filterCopy.specAlerts.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.specAlerts.contains).map(specAlert => ({
        specAlerts: { contains: specAlert },
      })),
    );
    delete filterCopy.specAlerts.contains;
  }

  if (filterCopy?.projectTypes && filterCopy.projectTypes.contains) {
    Array.prototype.push.apply(
      query.or,
      Object.values(filterCopy.projectTypes.contains).map(projectType => ({
        categories: { contains: projectType },
      })),
    );
    delete filterCopy.projectTypes.contains;
  }

  if (filterCopy?.actionStage && filterCopy.actionStage.eq) {
    const actionStages: any = [];
    Object.values(filterCopy.actionStage.eq).forEach(actionStage => {
      actionStages.push(actionStageValueMap[actionStage]);
    });
    Array.prototype.push.apply(
      query.or,
      actionStages.flat().map((actionStage: any) => ({
        mappedActionStage: { eq: actionStage },
      })),
    );
    delete filterCopy.actionStage.eq;
  }

  if (model === 'addresses') {
    if (filterCopy?.score?.between) {
      Array.prototype.push.apply(
        query.or,
        filterCopy?.score?.between.map(scoreRange => ({
          score: {
            gt: scoreRange[0],
            lte: scoreRange[1],
          },
        })),
      );
      delete filterCopy.score.between;
    }
  } else if (model === 'projectLeads') {
    if (filterCopy?.score) {
      filterCopy.projectScore = cloneDeep(filterCopy.score);
      filterCopy.score = {};
    }

    if (filterCopy?.projectScore?.between) {
      Array.prototype.push.apply(
        query.or,
        filterCopy?.projectScore?.between.map(scoreRange => ({
          projectScore: {
            gt: scoreRange[0],
            lte: scoreRange[1],
          },
        })),
      );
      delete filterCopy.score.between;
      delete filterCopy.projectScore.between;
    }
  }

  if (filterCopy?.projectTitle?.cicontains) {
    Array.prototype.push.apply(query.or, [
      { projectTitle: { cicontains: filterCopy.projectTitle.cicontains } },
    ]);
    delete filterCopy.projectTitle.cicontains;
  }

  if (filterCopy?.dodgeIds?.cicontains) {
    Array.prototype.push.apply(query.or, [
      { dodgeIds: { contains: filterCopy.dodgeIds.cicontains } },
    ]);
    delete filterCopy.dodgeIds.cicontains;
  }

  return {
    // Include propertyId to ensure deterministic loading across pages
    sort: sort && [...sortColumns[orderBy].map(col => `${dir}${col}`), '-propertyId'],
    query: {
      ...query,
      ...filterCopy,
    },
    limit: limit || DEFAULT_PAGE_SIZE,
    offset: startIndex,
  };
};
