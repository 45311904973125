import { makeStyles } from '@material-ui/core';

import { primaryBlue } from '../../../theme';

export const useAccountsPageHeaderStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '.5rem 0',
    marginLeft: '-.5rem',
  },
  filterButton: {
    paddingLeft: 0,
  },
  count: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
  },
  isNew: {
    paddingLeft: 12,
    paddingRight: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: primaryBlue,
  },
}));
