import { atom, atomFamily } from 'recoil';

import { AccountListAccount, Stakeholder } from '../../models';
import { OrganizationAccount, OrganizationWithAccountDetails } from '../../models/organization';
import PvfProject from '../../models/pvfProject';

export const accountsListState = atom({
  key: 'accountsList',
  default: [] as AccountListAccount[],
});

export const accountsMergeListState = atom({
  key: 'accountsMergeList',
  default: [] as OrganizationAccount[],
});

export const selectedAccountState = atom({
  key: 'selectedAccount',
  default: '',
});

export type AccountContactsState = {
  loaded: boolean;
  stakeholders: Stakeholder[];
};

export const accountContactsState = atomFamily<AccountContactsState, string>({
  key: 'accountContacts',
  default: {
    loaded: false,
    stakeholders: [] as Stakeholder[],
  },
});

export const accountPartnersState = atomFamily<AccountContactsState, string>({
  key: 'accountPartners',
  default: {
    loaded: false,
    stakeholders: [] as Stakeholder[],
  },
});

export const accountsCountState = atom({
  key: 'accountsCount',
  default: 0,
});

export const accountsMergeIdState = atom<null | string>({
  key: 'accountsMerge',
  default: null,
});

export const accountDetailsIdState = atom<null | string>({
  key: 'accountDetailsId',
  default: null,
});

export const accountsMergeIdsSelectedState = atom<Set<string>>({
  key: 'accountsMergeIdsSelected',
  default: new Set(),
});

export const accountsMergeModalState = atom<boolean>({
  key: 'accountsMergeModal',
  default: false,
});

export const accountsSortState = atom({
  key: 'accountsSort',
  default: { column: 'updated_at', direction: 'desc' },
});

export const accountsIsNewState = atom({
  key: 'accountIsNew',
  default: false,
});

export const selectedAccountDetails = atomFamily({
  key: 'selectedAccountDetails',
  default: {
    loaded: false,
    account: {} as OrganizationWithAccountDetails,
  },
});

export const selectedAccountProjectLeadsState = atomFamily({
  key: 'selectedAccountsProjectLeads',
  default: {
    loaded: false,
    projectLeads: [] as PvfProject[],
  },
});
