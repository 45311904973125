import React from 'react';
import { Cell, Row } from 'react-table';
import { Typography } from '@material-ui/core';

import ReactTableCell from './ReactTableCell';
import { useReactTableRowStyles } from './ReactTableRow.styles';

import { getProjectLeadsDetailsPagePath } from '../../../../utils/page';

interface ReactTableRowProps<TableType extends Record<any, any>> {
  row: Row<TableType>;
}

export const ReactTableRow = <TableType extends Record<any, any>>({
  row,
}: ReactTableRowProps<TableType>): JSX.Element => {
  const classes = useReactTableRowStyles();

  const handleRowClick = () => {
    const newWindow = window.open(`${getProjectLeadsDetailsPagePath(row.values?.id)}`, '_blank');
    newWindow?.focus();
  };

  return (
    <tr {...row.getRowProps()} className={classes.root} onClick={handleRowClick}>
      {row.original?.isNew && (
        <div className={classes.newRibbon}>
          <Typography variant={'subtitle1'}>
            <strong>New</strong>
          </Typography>
        </div>
      )}
      {row.cells.map((cell: Cell<TableType>, index: number) => {
        return <ReactTableCell<TableType> cell={cell} key={`cell-${index}-${cell.row.id}`} />;
      })}
    </tr>
  );
};
