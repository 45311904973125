import * as Yup from 'yup';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import { StatusBarTabItem } from './LeadsTable/StatusBar';

import {
  ACCOUNT_RATING_LABELS,
  ACTION_STAGES,
  DATA_SOURCE_LABELS,
  EVENT_DATE_LABELS,
  FILTER_LABELS,
  SCORE_LABELS,
} from '../../../constants';
import {
  Comparators,
  FilterItem,
  FilterItemInputType,
  FilterKey,
  FilterOptionsItem,
  FilterOptionsResponse,
  OptionValue,
} from '../../../types';
import { formatCurrency } from '../../../utils/number';
import { stateAbbreviations } from '../../../utils/stateAbbreviations';
import { provinceAbbreviations } from '../../../utils/provinceAbbreviations';
import { LeadType, ScoreStatus } from '../../../models/lead';
import { toTitleCase, toTitleCaseHyphen } from '../../../utils/string';
import { CleoObjectType } from '../../../models/common';
import { countyStateList } from '../../../utils/countyStateList';

export const STATUS_MENU: StatusBarTabItem<ScoreStatus>[] = [
  {
    status: ScoreStatus.Home,
    text: toTitleCase(ScoreStatus.Home),
    defaultFilter: {},
  },
  {
    status: ScoreStatus.Favorites,
    text: toTitleCase(ScoreStatus.Favorites),
    defaultFilter: { isBookmarked: { eq: true } },
  },
  {
    status: ScoreStatus.SavedFilters,
    text: 'Saved Filters',
    defaultFilter: {},
  },
  {
    status: ScoreStatus.Archive,
    text: toTitleCase(ScoreStatus.Archive),
    defaultFilter: { inactiveProjectCount: { gt: 0 } },
  },
  {
    status: ScoreStatus.Hidden,
    text: 'Filter Details',
    defaultFilter: {},
  },
];

export const normalizeScoreStatus = (leadType: LeadType, scoreStatus: ScoreStatus): ScoreStatus => {
  let result = scoreStatus;
  if (leadType === LeadType.Converted || leadType === LeadType.Demoted) {
    result = ScoreStatus.Inactive;
  } else if (scoreStatus === ScoreStatus.Inactive) {
    result = ScoreStatus.Home;
  }
  return result;
};

export const getFilteredItemsFromInput = (
  inputValue: string,
  filterOptions: FilterOptionsItem[],
): FilterOptionsItem[] =>
  filterOptions.filter(option => option.label?.toLowerCase().includes(inputValue.toLowerCase()));

interface FilterOptionsParams {
  filterItem: FilterItem;
  userOptions?: FilterOptionsItem[];
  searchStateValue?: string;
  searchCountyValue?: string;
  searchSpecAlertValue?: string;
  searchKeywordValue?: string;
  searchProjectTypeValue?: string;
  searchRegionValue?: string;
  searchLocationValue?: string;
  searchProvinceValue?: string;
}

export const getAllFilterOptions = ({
  filterItem,
  userOptions,
  searchStateValue,
  searchKeywordValue,
  searchSpecAlertValue,
  searchProjectTypeValue,
  searchRegionValue,
  searchLocationValue,
  searchProvinceValue,
}: FilterOptionsParams): FilterOptionsItem[] => {
  let filterOptions: FilterOptionsItem[];
  if (filterItem.filterLabel === 'Owner' && userOptions) {
    filterOptions = userOptions;
  } else {
    filterOptions = filterItem.options;
  }

  switch (filterItem.filterLabel) {
    case 'Location':
      if (searchLocationValue && searchLocationValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchLocationValue, filterOptions);
      }
      break;
    case 'Region':
      if (searchRegionValue && searchRegionValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchRegionValue, filterOptions);
      }
      break;
    case 'State':
      if (searchStateValue && searchStateValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchStateValue, filterOptions);
      }
      break;
    case 'Province':
      if (searchProvinceValue && searchProvinceValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchProvinceValue, filterOptions);
      }
      break;
    case 'Spec Alerts':
      if (searchSpecAlertValue && searchSpecAlertValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchSpecAlertValue, filterOptions);
      }
      break;
    case 'Keywords':
      if (searchKeywordValue && searchKeywordValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchKeywordValue, filterOptions);
      }
      break;
    case 'Project Types':
      if (searchProjectTypeValue && searchProjectTypeValue.length > 0) {
        filterOptions = getFilteredItemsFromInput(searchProjectTypeValue, filterOptions);
      }
      break;
    case 'Owner':
      filterOptions = filterOptions.slice(0, 6);
      break;
    default:
      break;
  }

  return filterOptions;
};

export const getLeadsConfig = (currentFilterOptions: FilterOptionsResponse) => {
  const LeadsConfig: FilterItem[] = [];
  const { projectTypes, specAlerts, keywords, regions } = currentFilterOptions;
  FILTER_LABELS.forEach(filterLabel => {
    switch (filterLabel) {
      case 'Location': {
        const allStateOptions: FilterOptionsItem[] = Object.keys(stateAbbreviations).map(state => ({
          label: stateAbbreviations[state],
          value: state,
          inputType: FilterItemInputType.Select,
          comparator: Comparators.In,
          multiple: true,
          filterKey: FilterKey.State,
        }));
        const allCountyStateOptions: FilterOptionsItem[] = countyStateList.map(countyObj => {
          const { county, state } = countyObj;
          return {
            label: `${toTitleCase(county)} - ${state}`.trim(),
            value: `${county} - ${state}`.trim(),
            inputType: FilterItemInputType.Select,
            comparator: Comparators.In,
            multiple: true,
            filterKey: FilterKey.CountyState,
          };
        });
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.Location,
          multiple: true,
          options: [...allStateOptions, ...allCountyStateOptions],
          addSearch: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Accounts, CleoObjectType.Addresses],
        });
        break;
      }
      case 'State':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.State,
          multiple: true,
          options: Object.keys(stateAbbreviations).map(state => ({
            label: stateAbbreviations[state],
            value: state,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.In,
            multiple: true,
          })),
          addSearch: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Accounts, CleoObjectType.Addresses],
        });
        break;
      case 'Province':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.Province,
          multiple: true,
          options: Object.keys(provinceAbbreviations).map(province => ({
            label: provinceAbbreviations[province],
            value: province,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.In,
            multiple: true,
          })),
          addSearch: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Accounts, CleoObjectType.Addresses],
        });
        break;
      case 'Region':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.Region,
          multiple: true,
          options: regions.map(region => {
            const label = region === 'SOCAL' ? 'SoCal' : toTitleCaseHyphen(toTitleCase(region));
            return {
              label: label,
              value: region,
              inputType: FilterItemInputType.Select,
              comparator: Comparators.In,
              multiple: true,
            };
          }),
          addSearch: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses, CleoObjectType.Accounts],
        });
        break;
      case 'Score':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.Score,
          multiple: false,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          validation: Yup.object().shape({
            score_max: Yup.number().typeError('').max(100, 'Maximum score is 100'),
            score_min: Yup.number().when('score_max', {
              is: value => value,
              then: Yup.number()
                .typeError('')
                .max(Yup.ref('score_max'), 'Minimum score cannot be greater than maximum'),
              otherwise: Yup.number(),
            }),
          }),
          options: SCORE_LABELS.map(label => ({
            ...(label === 'Minimum'
              ? {
                  label,
                  id: 'score min',
                  formatter: (val: OptionValue): string => `>${val}`,
                  unformatter: (val: OptionValue): number => numeral(val).value(),
                  inputType: FilterItemInputType.Input,
                  comparator: Comparators.Gte,
                }
              : label === 'Maximum'
              ? {
                  label,
                  id: 'score_max',
                  formatter: (val: OptionValue): string => `<${val}`,
                  unformatter: (val: OptionValue): number => numeral(val).value(),
                  inputType: FilterItemInputType.Input,
                  comparator: Comparators.Lte,
                }
              : {
                  label,
                  value: label.split('-').map(val => Number(val)),
                  inputType: FilterItemInputType.Select,
                  comparator: Comparators.Between,
                  multiple: true,
                }),
          })),
        });
        break;
      case 'Data Source':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.EventSource,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: DATA_SOURCE_LABELS.map(label => ({
            label,
            value:
              label === 'Hospital Survey'
                ? 'SURVEYS'
                : label === 'Build Central'
                ? 'BUILD_CENTRAL'
                : label.toUpperCase(),
            inputType: FilterItemInputType.Select,
            comparator: Comparators.In,
            formatter: (val: OptionValue): string => (val + '').replace('_', ' '),
          })),
        });
        break;
      case 'Owner':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.OwnerId,
          multiple: true,
          options: [],
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
        });
        break;
      case 'Project Value':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.Value,
          multiple: false,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          validation: Yup.object().shape({
            value_max: Yup.number().typeError('').max(1e12, 'Maximum valuation is $1 trillion'),
            value_min: Yup.number().when('value_max', {
              is: value => value,
              then: Yup.number()
                .typeError('')
                .max(Yup.ref('value_max'), 'Minimum amount cannot be greater than maximum'),
              otherwise: Yup.number(),
            }),
          }),
          options: [
            {
              label: '$0',
              id: 'value_min',
              formatter: (val: OptionValue): string => `>${formatCurrency(val as number)}`,
              unformatter: (val: OptionValue): number => numeral(val).value(),
              inputType: FilterItemInputType.Input,
              comparator: Comparators.Gte,
            },
            {
              label: '$0',
              id: 'value_max',
              formatter: (val: OptionValue): string => `<${formatCurrency(val as number)}`,
              unformatter: (val: OptionValue): number => numeral(val).value(),
              inputType: FilterItemInputType.Input,
              comparator: Comparators.Lte,
            },
          ],
        });
        break;
      case 'Publish Date':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.EventDate,
          multiple: false,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: EVENT_DATE_LABELS.map(label => ({
            label,
            selectionId: `gt${label.replace(/<|\s/gi, '').toLowerCase()}`,
            value:
              DateTime.fromJSDate(new Date())
                .minus({ months: Number(label.replace(/<|[months]/gi, '')) })
                .toISODate() || undefined,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Gt,
            multiple: false,
          })),
        });
        break;
      case 'Action Stage':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.ActionStage,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: Object.keys(ACTION_STAGES).map(actionStage => ({
            label: ACTION_STAGES[actionStage],
            value: actionStage,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Eq,
            multiple: true,
          })),
        });
        break;
      case 'Spec Alerts':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.SpecAlerts,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: specAlerts.map(specAlert => ({
            label: specAlert,
            value: specAlert,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Contains,
            multiple: true,
          })),
          addSearch: true,
        });
        break;
      case 'Keywords':
        LeadsConfig.push({
          filterLabel: 'Keywords',
          filterKey: FilterKey.Keywords,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: keywords.map(keyword => ({
            label: toTitleCase(keyword),
            value: keyword,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Contains,
            multiple: true,
          })),
          addSearch: true,
        });
        break;
      case 'Project Types':
        LeadsConfig.push({
          filterLabel,
          filterKey: FilterKey.ProjectTypes,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
          options: projectTypes.map(category => ({
            label: toTitleCase(category),
            value: category,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Contains,
            multiple: true,
          })),
          addSearch: true,
        });
        break;
      case 'Account Ratings':
        LeadsConfig.push({
          filterLabel: 'Account Ratings',
          filterKey: FilterKey.AccountRatings,
          multiple: true,
          model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses, CleoObjectType.Accounts],
          options: ACCOUNT_RATING_LABELS.map(rating => ({
            label: toTitleCase(rating),
            value: rating,
            inputType: FilterItemInputType.Select,
            comparator: Comparators.Contains,
            multiple: true,
          })),
        });
        break;
      default:
        break;
    }
  });
  return LeadsConfig;
};
