import { makeStyles } from '@material-ui/core/styles';

import { lightGreen, primary, primaryBlue } from '../../../theme';

export const useProjectLeadDetailsStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 64px)',
    width: '100%',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  header: {
    margin: '2rem 0 .5rem 0',
    padding: '0 .5rem',
  },
  tabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  tabsContainer: {
    borderBottom: `1px solid ${primaryBlue}`,
    marginLeft: '.5rem',
    marginRight: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tabPanelContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  tabPanel: {
    height: '100%',
    padding: `1rem 0`,
  },
  emptyBox: {
    backgroundColor: lightGreen,
    verticalAlign: 'middle',
    height: '100%',
    width: '100%',
    borderRadius: '0',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0F1A24',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  transparentBorder: {
    width: '100%',
    margin: '.75rem .25rem',
    border: `1px solid transparent`,
    position: 'relative',
  },
  border: {
    border: `1px solid ${primary}`,
    borderRadius: '4px',
    margin: '.75rem .25rem',
    padding: '.75rem',
    height: '100%',
    zIndex: 0,
  },
  title: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    margin: '0 1rem',
    padding: '0 1rem',
  },
  boxedScore: {
    left: '1.5%',
    bottom: '85%',
  },
  boxedTitle: {
    left: '7%',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  itemRight: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    margin: '0',
    padding: '0 0.5rem',
    right: '3%',
  },
  body: {
    paddingTop: '2rem',
    paddingBottom: '1rem',
    height: '100%',
    '& div:nth-child(even)': {
      backgroundColor: '#EAF5F3',
    },
  },
  editButton: {
    color: primaryBlue,
    border: `1px ${primaryBlue} solid`,
    width: '7rem',
    padding: '0',
  },
  addressItemContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1.5rem',
  },
  addressItem: {
    width: '50%',
    fontWeight: 'bold',
    fontSize: '1rem',
    backgroundColor: 'inherit',
  },
  addressValue: {
    width: '50%',
    fontSize: '1rem',
    backgroundColor: 'inherit',
  },
  addressItemForm: {
    width: '100%',
    backgroundColor: 'inherit',
    '& div': {
      width: '100%',
      backgroundColor: 'inherit',
    },
    '& div div': {
      width: '100%',
      backgroundColor: 'inherit',
    },
  },

  tabList: {
    marginTop: '1rem',
  },
}));
