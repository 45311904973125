import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAccountsPageHeaderStyle } from './AccountsPageHeader.styles';
import AccountsListSort from './AccountsListSort';

import {
  accountDetailsIdState,
  accountsIsNewState,
  accountsMergeIdsSelectedState,
  accountsMergeIdState,
  accountsMergeListState,
  accountsMergeModalState,
} from '../../../state/atoms/accounts';
import ContainedButton from '../../Common/ContainedButton';
import { black, primary, primaryBlue, white } from '../../../theme';

interface IAccountsPageHeaderProps {
  count: number;
}

const AccountsPageHeader: React.FC<IAccountsPageHeaderProps> = ({ count }) => {
  const classes = useAccountsPageHeaderStyle();
  const [accountsMergeId, setAccountsMergeId] = useRecoilState(accountsMergeIdState);
  const [selectedMergeIds, setSelectedMergeIds] = useRecoilState(accountsMergeIdsSelectedState);
  const [mergeAccountsList, setMergeAccountsList] = useRecoilState(accountsMergeListState);
  const accountDetailsId = useRecoilValue(accountDetailsIdState);
  const [isNew, setIsNew] = useRecoilState(accountsIsNewState);
  const setModalOpen = useSetRecoilState(accountsMergeModalState);

  const handleSelectAll = () => {
    if (selectedMergeIds.size === mergeAccountsList.length) {
      // deselect all logic
      setSelectedMergeIds(new Set());
    } else {
      // select all logic
      const accountsIds: string[] = mergeAccountsList.map(account => account.id);
      setSelectedMergeIds(new Set(accountsIds));
    }
  };

  const handleCancel = () => {
    setSelectedMergeIds(new Set());
    setMergeAccountsList([]);
    setAccountsMergeId(null);
  };

  const handleMerge = () => {
    setModalOpen(true);
  };

  const mergeDisabled = selectedMergeIds.size < 1;
  const hideSortAndNew = !!accountDetailsId || !!accountsMergeId;

  return (
    <Grid className={classes.root}>
      {!hideSortAndNew && <AccountsListSort />}
      {!hideSortAndNew && (
        <Grid className={classes.isNew}>
          <Checkbox
            style={{ color: black, padding: '0' }}
            defaultChecked={isNew}
            value={isNew}
            onChange={() => setIsNew(prev => !prev)}
          />
          <Typography variant={'subtitle1'}>Show Only New Accounts</Typography>
        </Grid>
      )}

      <Typography className={classes.count} variant={'subtitle1'}>{`Total Accounts: ${
        !accountsMergeId ? count : mergeAccountsList.length
      }`}</Typography>
      {accountsMergeId && !!mergeAccountsList.length && (
        <Grid>
          <ContainedButton bgColor={white} textColor={primaryBlue} onClick={handleCancel}>
            CANCEL
          </ContainedButton>
          <ContainedButton
            bgColor={primary}
            onClick={handleSelectAll}
            style={{ minWidth: '125px' }}
          >
            SELECT ALL
          </ContainedButton>
          <ContainedButton
            bgColor={primaryBlue}
            onClick={handleMerge}
            disabled={mergeDisabled}
            style={{ minWidth: '125px' }}
          >
            MERGE
          </ContainedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default AccountsPageHeader;
