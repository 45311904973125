import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Grid, Typography } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/ArrowForward';
import CloseFilterIcon from '@material-ui/icons/ArrowBack';
import { useRecoilValue } from 'recoil';

import LeadsFilterContainer from './LeadsFilterContainer';

import { StyledFilterButton, StyledFilterItem, useLeadsStyles } from '../Leads.styles';
import { FilterData, TagsDisplay, UserFilterNameResponse } from '../../../../types';
import { ScoreStatus } from '../../../../models/lead';
import { CleoObjectType } from '../../../../models/common';
import { white } from '../../../../theme';
import { currentFilterOptionsState } from '../../../../state/atoms/filters';

interface IProps {
  handleFilterChange: (filterData: FilterData) => void;
  hasFilterBeenCleared: () => boolean;
  setSavedFilter: (savedFilter: UserFilterNameResponse) => void;
  savedFilter?: UserFilterNameResponse;
  setScoreStatus: (status: ScoreStatus) => void;
  cleoObjectType: CleoObjectType;
  disabled?: boolean;
  filterTagsToDisplay: TagsDisplay[];
  filterType: 'address' | 'project_lead';
  editedFilterNameError?: string;
  setEditedFilterNameError: (error: string) => void;
  closeFilterDrawer?: boolean;
}
const LeadsFilterDrawer: React.FC<IProps> = ({
  handleFilterChange,
  hasFilterBeenCleared,
  setScoreStatus,
  setSavedFilter,
  savedFilter,
  cleoObjectType,
  disabled = false,
  filterTagsToDisplay,
  filterType,
  editedFilterNameError,
  setEditedFilterNameError,
  closeFilterDrawer = false,
}: IProps) => {
  const classes = useLeadsStyles();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const currentFilterOptions = useRecoilValue(currentFilterOptionsState);
  const disableOpen = disabled || Object.keys(currentFilterOptions).length === 0;

  useEffect(() => {
    if (closeFilterDrawer) {
      setFilterDrawerOpen(false);
    }
  }, [setFilterDrawerOpen, closeFilterDrawer]);

  return (
    <Grid item className={classes.filterDrawerContainer}>
      {!filterDrawerOpen ? (
        <StyledFilterButton
          disabled={disableOpen}
          onClick={() => setFilterDrawerOpen(prev => !prev)}
          style={{ marginTop: '1rem' }}
        >
          <div className={classes.closedFilterDrawer}>
            <Typography className={classes.filtersHeaderLabel}>FILTERS</Typography>
            <Avatar style={{ backgroundColor: white }}>
              <FilterIcon className={classes.filterIcon} />
            </Avatar>
          </div>
        </StyledFilterButton>
      ) : (
        <div className={classes.openFilterDrawer}>
          <StyledFilterItem>
            <Typography className={classes.filtersHeaderLabel}>FILTERS</Typography>
            <Avatar style={{ backgroundColor: white }}>
              <CloseFilterIcon
                onClick={() => {
                  setFilterDrawerOpen(prev => !prev);
                }}
                className={classes.filterIcon}
              />
            </Avatar>
          </StyledFilterItem>
          <Divider variant="fullWidth" className={classes.filterDividerTop} flexItem />
          <LeadsFilterContainer
            handleFilterChange={handleFilterChange}
            hasFilterBeenCleared={hasFilterBeenCleared}
            setScoreStatus={setScoreStatus}
            setSavedFilter={setSavedFilter}
            savedFilter={savedFilter}
            cleoObjectType={cleoObjectType}
            filterTagsToDisplay={filterTagsToDisplay}
            filterType={filterType}
            setEditedFilterNameError={setEditedFilterNameError}
            editedFilterNameError={editedFilterNameError}
          />
        </div>
      )}
    </Grid>
  );
};

export default LeadsFilterDrawer;
