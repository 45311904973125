import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import FilterItemSelectIcon from '@material-ui/icons/KeyboardArrowDown';
import FilterItemClosedIcon from '@material-ui/icons/ChevronRight';

import AddSearchForm from './AddSearchForm';
import LeadsFilterItem from './LeadsFilterItem';
import { getCountyFiltersByState } from './LocationFilterHelpers/GetCountyFiltersByState';
import LeadsFilterItemLocation from './LeadsFilterItemLocation';

import { useLeadsStyles } from '../Leads.styles';
import { FilterItem, FilterItemInputType, FilterKey, FilterOptionsItem } from '../../../../types';
import {
  clearFiltersState,
  filterItemsOpenState,
  showMoreStateOptionsState,
  showSeeMoreLinksState,
  filterInputsToClearState,
  currentFilterOptionsState,
  countiesInEachStateState,
  searchLocationValueState,
} from '../../../../state/atoms/filters';
import { CleoObjectType } from '../../../../models/common';

interface IProps {
  getOptions: (i: FilterItem) => FilterOptionsItem[];
  isFilterItemChecked: (filterOptionsItem: FilterOptionsItem, filterItem: FilterItem) => boolean;
  config: FilterItem;
  locationType?: string;
}

export const LeadsFilterSection: React.FC<IProps> = ({
  getOptions,
  isFilterItemChecked,
  config,
  locationType,
}) => {
  const classes = useLeadsStyles();
  const filterItemsOpen = useRecoilValue(filterItemsOpenState);
  const clearFilters = useRecoilValue(clearFiltersState);
  const showSeeMoreLinks = useRecoilValue(showSeeMoreLinksState);
  const [filterInputsToClear, setFilterInputsToClear] = useRecoilState(filterInputsToClearState);
  const [showMore, setShowMore] = useRecoilState(showMoreStateOptionsState);
  const [countiesInEachState, setCountiesInEachState] = useRecoilState(countiesInEachStateState);
  const locationSearchValue = useRecoilValue(searchLocationValueState);

  // Items to create county subfilter under state
  // currentFilterOptions keeps track of options for each state
  const currentFilterOptions = useRecoilValue(currentFilterOptionsState);

  let defaultState: Record<string, boolean> | undefined;
  if (config.filterKey === FilterKey.State) {
    defaultState = {};
    config.options.forEach(option => {
      if (defaultState && option.label) {
        defaultState[option.label] = false;
      }
    });
  }
  // subFilterOpen keeps track of state filters open and showing counties or not
  const [subFilterOpen, setSubFilterOpen] = useState<Record<string, boolean>>(defaultState || {});
  const isOpen = useCallback((): boolean => {
    return (
      filterItemsOpen[config.filterLabel] &&
      (locationType ? locationType === config.filterKey : true)
    );
  }, [filterItemsOpen, config.filterLabel, config.filterKey, locationType]);

  // Adds the county's FilterOptionsItem[] by state into recoil state
  useEffect(() => {
    setCountiesInEachState(getCountyFiltersByState());
  }, [currentFilterOptions, setCountiesInEachState]);

  return (
    <>
      {isOpen() && config.addSearch && <AddSearchForm config={config} />}
      {isOpen() && (
        <div className={classes.filterOptionsContainer}>
          {getOptions(config).map((filterItem, i) => {
            // Specifically for State and CountyState Filter
            if (config.filterKey === 'state') {
              const countyConfig: FilterItem = {
                filterKey: FilterKey.CountyState,
                filterLabel: 'County',
                multiple: true,
                options: countiesInEachState[filterItem.label || ''],
                addSearch: true,
                model: [CleoObjectType.ProjectLeads, CleoObjectType.Addresses],
              };
              return (
                <div key={`${filterItem.id}-${i}`} className={classes.stateFilter}>
                  <div className={classes.filterOptions}>
                    {/* These Filter Items are each state*/}
                    <LeadsFilterItemLocation
                      filterItem={config}
                      filterItemOption={filterItem}
                      clearFilters={clearFilters}
                      filterInputsToClear={filterInputsToClear}
                      setFilterInputsToClear={setFilterInputsToClear}
                      isFilterItemChecked={isFilterItemChecked(filterItem, config)}
                      multipleSelect={filterItem.multiple}
                    />
                    {filterItem.inputType !== FilterItemInputType.Input && (
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                        onClick={() =>
                          setSubFilterOpen({
                            ...subFilterOpen,
                            [filterItem.label || '']: !subFilterOpen[filterItem.label || ''],
                          })
                        }
                      >
                        <Typography style={{ width: '75%' }} className={classes.filterOptionState}>
                          {filterItem.label}
                        </Typography>
                        {subFilterOpen[filterItem.label || ''] ? (
                          <FilterItemSelectIcon style={{ cursor: 'pointer', fontSize: '1rem' }} />
                        ) : (
                          <FilterItemClosedIcon style={{ cursor: 'pointer', fontSize: '1rem' }} />
                        )}
                      </Grid>
                    )}
                  </div>
                  {subFilterOpen[filterItem.label || ''] &&
                    countiesInEachState[filterItem.label || '']?.map((option, idx) => {
                      // These Filter Items are the counties in each state
                      return (
                        <div key={`${option.label}-${idx}`} className={classes.subFilterOptions}>
                          <LeadsFilterItemLocation
                            filterItem={countyConfig}
                            filterItemOption={option}
                            clearFilters={clearFilters}
                            filterInputsToClear={filterInputsToClear}
                            setFilterInputsToClear={setFilterInputsToClear}
                            isFilterItemChecked={isFilterItemChecked(option, countyConfig)}
                            multipleSelect={filterItem.multiple}
                          />
                          <Typography className={classes.filterOptionLabel}>
                            {option.label}
                          </Typography>
                        </div>
                      );
                    })}
                </div>
              );
            } else if (config.filterKey === FilterKey.Location) {
              //Will only show search bar in Location Filter
              if (!locationSearchValue.length) {
                return null;
              }
              return (
                <div key={`${filterItem.id}-${i}`} className={classes.filterOptions}>
                  <LeadsFilterItemLocation
                    filterItem={config}
                    filterItemOption={filterItem}
                    clearFilters={clearFilters}
                    filterInputsToClear={filterInputsToClear}
                    setFilterInputsToClear={setFilterInputsToClear}
                    isFilterItemChecked={isFilterItemChecked(filterItem, config)}
                    multipleSelect={filterItem.multiple}
                  />
                  <Typography className={classes.filterOptionLabel}>{filterItem.label}</Typography>
                </div>
              );
            }

            // All other filter sections
            return (
              <div key={`${filterItem.id}-${i}`} className={classes.filterOptions}>
                <LeadsFilterItem
                  filterItem={config}
                  filterItemOption={filterItem}
                  clearFilters={clearFilters}
                  filterInputsToClear={filterInputsToClear}
                  setFilterInputsToClear={setFilterInputsToClear}
                  isFilterItemChecked={isFilterItemChecked(filterItem, config)}
                  multipleSelect={filterItem.multiple}
                />
                {filterItem.inputType !== FilterItemInputType.Input && (
                  <Tooltip
                    title={filterItem.label || ''}
                    aria-label={filterItem.label}
                    placement="bottom-start"
                    arrow
                  >
                    <Typography className={classes.filterOptionLabel}>
                      {filterItem.label}
                    </Typography>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
      )}
      {isOpen() &&
      config.addSearch &&
      showSeeMoreLinks &&
      !showMore &&
      config.filterKey !== FilterKey.Location ? (
        <Typography
          onClick={() => setShowMore(prev => !prev)}
          style={{
            cursor: 'pointer',
            paddingLeft: '1.5rem',
            marginTop: '0.5rem',
            textDecoration: 'underline',
          }}
        >
          See all...
        </Typography>
      ) : isOpen() && showMore ? (
        <Typography
          onClick={() => setShowMore(prev => !prev)}
          style={{
            cursor: 'pointer',
            paddingLeft: '1.5rem',
            marginTop: '0.5rem',
            textDecoration: 'underline',
          }}
        >
          See less...
        </Typography>
      ) : null}
    </>
  );
};
