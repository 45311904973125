import React, { useCallback, useEffect, useState } from 'react';
import { Paper, Grid, Container, Button, makeStyles, Box, Typography } from '@material-ui/core';
import { Formik, Form, FormikHelpers } from 'formik';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';

import ProjectTransitioned from './ProjectTransitioned';

import PaperHeader from '../../../Common/PaperHeader';
import PaperTitle from '../../../Common/PaperTitle';
import PaperBody from '../../../Common/PaperBody';
import PaperFooter from '../../../Common/PaperFooter';
import FormSelect from '../../../Common/FormSelect';
import { toTitleCase } from '../../../../utils/string';
import { light, darkLight, darkBlue, primaryBlue, white } from '../../../../theme';
import { CleoObjectType, ProjectStatusAction } from '../../../../models/common';
import { ProjectIncrementalScoreMapping } from '../../../../constants';
import {
  addProjectFeedbacks,
  updateProjectStateById,
  getConversionReasons,
  getDemotionReasons,
} from '../../../../api/projects';
import { demotePropertiesById, demotePropertyById } from '../../../../api/properties';
import Project, { ProjectStatusChangeSchema } from '../../../../models/project';
import FormTextField from '../../../Common/FormTextField';
import ModalLayout from '../../../Common/ModalLayout';
import { Feedback } from '../../../../models';
import Feedbacks from '../Feedbacks/Feedbacks';
import EmptyBox from '../../../Common/EmptyBox';
import {
  renderEvent,
  renderProperty,
  renderScore,
  renderType,
} from '../../Leads/LeadsTable/LeadTableRowRenderers';
import { bulkDemoteModalOpenState, leadRowsState } from '../../../../state/atoms/leadRows';

const useStyles = makeStyles(theme => ({
  sectionBox: {
    width: '100%',
    marginBottom: theme.spacing(6),
    justifyContent: 'center',
    display: 'contents',
  },
  selectLabel: {
    color: darkLight,
    fontSize: '0.75rem',
  },
  enterData: {
    padding: theme.spacing(4, 8, 5, 8),
    color: darkBlue,
    width: '90%',
  },
  enterQualifications: {
    border: `1px solid ${light}`,
    borderRadius: 8,
  },
  qualifications: {
    paddingTop: theme.spacing(4),
    width: '100%',
  },
  paperbodyContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'start',
  },
  enterNotesTitle: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  enterNotes: {
    padding: theme.spacing(4, 8, 5, 8),
    color: darkBlue,
    width: '100%',
    border: `1px solid ${light}`,
    borderRadius: 8,
  },
  showLeads: {
    padding: theme.spacing(2, 4, 2.5, 4),
    maxHeight: '23.25rem',
    overflow: 'scroll',
  },
  showLeadItem: {
    '& .MuiTypography-body1': {
      fontSize: '0.775rem',
    },
    '& .MuiTypography-body2': {
      fontSize: '0.65rem',
    },
    '& .MuiTypography-caption': {
      fontSize: '0.85rem !important',
    },
  },
  notesContainer: {
    maxHeight: '23rem',
    overflow: 'auto',
  },
  selectedLeadCard: {
    width: '100%',
    margin: '0 0 1em 0',
    border: `1px solid ${darkLight}`,
    padding: theme.spacing(2, 0),
    borderRadius: 6,
    backgroundColor: white,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: light,
    },
    [theme.breakpoints.down('sm')]: {
      '& > .MuiGrid-item': {
        padding: theme.spacing(0, 1),
      },
    },
  },
  scoreColumn: {
    textAlign: 'center',
  },
  typeColumn: {
    lineBreak: 'anywhere',
  },
}));

type IProps = {
  leadName: string;
  objectType?: CleoObjectType;
  projectId?: string;
  propertyId?: string;
  propertyIds?: string[];
  status: ProjectStatusAction;
  isOpen: boolean;
  feedbacks?: Feedback[];
  project?: Project;
  handleClose: () => void;
  fetchProperty?: () => void;
};

type IProjectTransition = {
  reason?: string;
  value?: string;
  note?: string;
  score?: string;
};

const ProjectStatusModal: React.FC<IProps> = ({
  leadName,
  projectId,
  propertyId,
  status,
  isOpen,
  feedbacks,
  handleClose,
  fetchProperty,
  project,
  propertyIds,
  objectType,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [feedbacksToAdd, setFeedbacksToAdd] = useState<string[]>([]);
  const bulkDemoteModalOpen = useRecoilValue<boolean>(bulkDemoteModalOpenState);
  const cachedRowsInfo = useRecoilValue(leadRowsState);
  const { cachedRows } = cachedRowsInfo;

  const ucStatus = toTitleCase(status);
  const classes = useStyles();

  const conversion = status === ProjectStatusAction.Convert;
  const [conversionReasons, setConversionReasons] = useState<Record<string, string>>({});
  const [demotionReasons, setDemotionReasons] = useState<Record<string, string>>({});
  const getTransitionReasons = useCallback(async () => {
    const cReasonsResp = await getConversionReasons();
    const dReasonsResp = await getDemotionReasons();
    const cReasons = {} as Record<string, string>;
    cReasonsResp.map(x => {
      return (cReasons[x.transition_reason] = x.reason_mapping);
    });
    const dReasons = {} as Record<string, string>;
    dReasonsResp.map(x => {
      return (dReasons[x.transition_reason] = x.reason_mapping);
    });
    setConversionReasons(cReasons);
    setDemotionReasons(dReasons);
  }, []);
  const reasons = conversion ? conversionReasons : demotionReasons;

  const handleProjectFeedbacksChange = useCallback((feedbackId: string, add = false) => {
    if (add) {
      setFeedbacksToAdd(prevFeedbacks => {
        const allFeedbacks = prevFeedbacks.concat(feedbackId);
        return allFeedbacks;
      });
    } else {
      setFeedbacksToAdd(prevFeedbacks => {
        const allFeedbacks = prevFeedbacks.filter(id => id === feedbackId);
        return allFeedbacks;
      });
    }
  }, []);
  const renderModalContent = () => {
    if (isSubmitted) {
      return (
        <ProjectTransitioned
          leadName={leadName}
          status={status}
          onClick={handleClose}
          propertyIds={propertyIds}
          objectType={objectType}
        />
      );
    } else {
      return (
        <Container maxWidth="md">
          <Formik
            initialValues={
              {
                reason: '',
                value: '',
                note: '',
                score: '',
              } as IProjectTransition
            }
            validationSchema={ProjectStatusChangeSchema(status)}
            onSubmit={(
              values: IProjectTransition,
              { setSubmitting, resetForm }: FormikHelpers<IProjectTransition>,
            ) => {
              const { reason, value, score, note } = values;
              setTimeout(async () => {
                const transitionDetails = {
                  reason,
                  value,
                  score,
                  note,
                };
                if (projectId) {
                  const result = await updateProjectStateById(projectId, status, transitionDetails);
                  if (result && status === ProjectStatusAction.Convert) {
                    // open a new window with the Opportunity link
                    window.open(
                      `https://armstrongceilings.my.salesforce.com/${result.sfdc_opportunity_id}`,
                    );
                  }
                  if (feedbacksToAdd.length !== 0) {
                    await addProjectFeedbacks(projectId, feedbacksToAdd);
                  }
                } else if (propertyId) {
                  await demotePropertyById(propertyId, transitionDetails);
                } else if (propertyIds) {
                  const params = { ...transitionDetails, idList: propertyIds };
                  await demotePropertiesById(params);
                }
                resetForm();
                setSubmitting(false);
                setIsSubmitted(true);
              }, 500);
            }}
          >
            {(() => (
              <Form>
                <Paper>
                  <PaperHeader>
                    <Grid container justify="center">
                      <Grid item>
                        <PaperTitle title={`${ucStatus} Lead`} />
                      </Grid>
                    </Grid>
                  </PaperHeader>
                  <PaperBody>
                    <Grid
                      container
                      className={classes.paperbodyContainer}
                      justify="space-evenly"
                      direction="row"
                      alignItems="center"
                    >
                      <Grid container direction="column" justify="space-evenly" alignItems="center">
                        <Grid item className={classes.sectionBox}>
                          <Grid
                            container
                            direction="column"
                            component={Box}
                            className={clsx(classes.enterData, classes.enterQualifications)}
                            textAlign="center"
                          >
                            <Grid item>
                              <Typography variant="subtitle1">Enter Qualification</Typography>
                            </Grid>
                            <Grid item className={classes.qualifications}>
                              <FormSelect
                                className={classes.selectLabel}
                                fullWidth
                                name="reason"
                                label={<span className={classes.selectLabel}>Primary Reason</span>}
                                options={reasons}
                              />
                            </Grid>
                            {conversion && (
                              <Grid item className={classes.qualifications}>
                                <FormSelect
                                  fullWidth
                                  label={
                                    <span className={classes.selectLabel}>Incremental Score</span>
                                  }
                                  name="score"
                                  options={ProjectIncrementalScoreMapping}
                                />
                              </Grid>
                            )}
                          </Grid>
                          <Grid item className={classes.sectionBox}>
                            <Grid
                              container
                              direction="column"
                              component={Box}
                              className={classes.enterData}
                              textAlign="center"
                            >
                              <Grid item>
                                <Typography variant="subtitle1">Transition Notes</Typography>
                              </Grid>
                              <Grid item>
                                <FormTextField
                                  fontWeight="normal"
                                  fontColor={primaryBlue}
                                  label={<span className={classes.selectLabel}>Add Note</span>}
                                  name="note"
                                  multiline
                                  fullWidth
                                  rows={3}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {!isSubmitted && (
                        <Grid container direction="column" justify="space-evenly">
                          <Grid item className={classes.sectionBox}>
                            <Grid
                              container
                              direction="column"
                              component={Box}
                              className={clsx(
                                classes.enterNotes,
                                bulkDemoteModalOpen && classes.showLeads,
                              )}
                            >
                              <Grid item>
                                <Typography variant="subtitle1" className={classes.enterNotesTitle}>
                                  {bulkDemoteModalOpen
                                    ? `${propertyIds?.length} Selected Leads`
                                    : 'Add Project Notes'}
                                </Typography>
                                {bulkDemoteModalOpen &&
                                  propertyIds?.map(propertyId => {
                                    const property = cachedRows.find(
                                      row => row.propertyId === propertyId,
                                    );

                                    return (
                                      property && (
                                        <Grid
                                          container
                                          alignItems="center"
                                          className={classes.selectedLeadCard}
                                          spacing={2}
                                          key={property.propertyId}
                                          style={{ display: 'flex' }}
                                        >
                                          <Grid
                                            item
                                            md={2}
                                            className={clsx(
                                              classes.scoreColumn,
                                              classes.showLeadItem,
                                            )}
                                          >
                                            {renderScore(property.score)}
                                          </Grid>
                                          <Grid item md={3} className={classes.showLeadItem}>
                                            {renderProperty(
                                              property.address1,
                                              property.city,
                                              property.state,
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            md={3}
                                            className={clsx(
                                              classes.typeColumn,
                                              classes.showLeadItem,
                                            )}
                                          >
                                            {renderType(property.propertyType, property.eventType)}
                                          </Grid>
                                          <Grid item md={4} className={classes.showLeadItem}>
                                            {renderEvent(
                                              property.eventSource,
                                              property.eventDate,
                                              property.eventDescription,
                                            )}
                                          </Grid>
                                        </Grid>
                                      )
                                    );
                                  })}

                                {feedbacks && feedbacks.length !== 0 && (
                                  <Grid container className={classes.notesContainer}>
                                    <Feedbacks
                                      feedbacks={feedbacks}
                                      readOnly={true}
                                      project={project}
                                      checkboxHandler={handleProjectFeedbacksChange}
                                    />
                                  </Grid>
                                )}
                                {feedbacks && feedbacks.length === 0 && (
                                  <EmptyBox>No Project Notes To Add</EmptyBox>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </PaperBody>
                  <PaperFooter>
                    <Grid container justify="center">
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >{`${ucStatus}`}</Button>
                      </Grid>
                    </Grid>
                  </PaperFooter>
                </Paper>
              </Form>
            ))()}
          </Formik>
        </Container>
      );
    }
  };

  useEffect(() => {
    getTransitionReasons();
  }, [getTransitionReasons]);

  return (
    <ModalLayout
      label={status}
      isOpen={isOpen}
      handleClose={() => {
        isSubmitted && fetchProperty && fetchProperty();
        setIsSubmitted(false);
        handleClose();
      }}
    >
      {renderModalContent()}
    </ModalLayout>
  );
};

export default ProjectStatusModal;
