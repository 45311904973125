import {
  ContactDetailType,
  ProfileType,
  ProjectSpaceType,
  ProjectStage,
  ProjectType,
  ProjectValue,
  ProjectConversionReason,
  ProjectDemotionReason,
  ProjectIncrementalScore,
  SourceType,
  StakeholderRole,
  DateRanges,
} from './models/common';
import type { DisplayNameMapping } from './types';

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'local';
export const ROLL_BAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || 'empty token';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_REDIRECT_URI =
  process.env.REACT_APP_AUTH0_REDIRECT_URI || window.location.origin;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';
export const POWERBI_URL = process.env.POWERBI_URL || '';

export const DATA_NOT_APPLICABLE = 'N/A';
export const DATA_PLACEHOLDER = '...';

export const DEFAULT_PAGE_SIZE = 10;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_STATIC_MAP_API_KEY || '';

export const ProjectSpaceTypeMapping: DisplayNameMapping = {
  [ProjectSpaceType.Office]: 'Office',
  [ProjectSpaceType.Retail]: 'Retail',
  [ProjectSpaceType.Education]: 'Education',
  [ProjectSpaceType.Healthcare]: 'Healthcare',
  [ProjectSpaceType.Hospitality]: 'Hospitality',
  [ProjectSpaceType.Other]: 'Other',
};

export const ProjectStageMapping: DisplayNameMapping = {
  [ProjectStage.Preplanning]: 'Preplanning',
  [ProjectStage.SchematicDesign]: 'Schematic Design',
  [ProjectStage.DesignDevelopment]: 'Design Development',
  [ProjectStage.ConstructionDocuments]: 'Construction Documents',
  [ProjectStage.ContractAwards]: 'Contract Awards',
  [ProjectStage.Construction]: 'Construction',
};

export const ProjectTypeMapping: DisplayNameMapping = {
  [ProjectType.NewBuild]: 'New Build',
  [ProjectType.Renovation]: 'Renovation',
};

export const OrganizationProfileTypeMapping: DisplayNameMapping = {
  [ProfileType.Contractor]: 'General Contractor',
  [ProfileType.Subcontractor]: 'Sub-Contractor',
  [ProfileType.Architect]: 'Architect',
  [ProfileType.Designer]: 'Designer',
  [ProfileType.Installer]: 'Installer',
  [ProfileType.Other]: 'Other',
};

export const ContactDetailMapping: DisplayNameMapping = {
  [ContactDetailType.Phone]: 'Phone Number',
  [ContactDetailType.Email]: 'Email Address',
  [ContactDetailType.Website]: 'Website',
};

export const ContactDetailPlaceholderMapping: DisplayNameMapping = {
  [ContactDetailType.Phone]: '(e.g. (310) 555-1234)',
  [ContactDetailType.Email]: '(e.g. example@cleodv.xyz)',
  [ContactDetailType.Website]: '(e.g. https://example.cleodv.xyz)',
};

export const DemotionReasonMapping: DisplayNameMapping = {
  [ProjectDemotionReason.AlreadyTracked]: 'Opportunity already created in Salesforce',
  [ProjectDemotionReason.AlreadyCompleted]: 'Job has already been completed',
  [ProjectDemotionReason.NoOpportunity]: 'No ceiling opportunity',
  [ProjectDemotionReason.NoContact]: 'No contact is available',
  [ProjectDemotionReason.Competitor]: "Competitor is spec'd to project",
  [ProjectDemotionReason.Residential]: 'This is a residential project',
  [ProjectDemotionReason.Other]: 'Other',
};

export const ConversionReasonMapping: DisplayNameMapping = {
  [ProjectConversionReason.Sold]: "Armstrong Spec'd for Project",
  [ProjectConversionReason.Opportunity]: 'Clear ceiling opportunity',
  [ProjectConversionReason.HighValue]: 'High value project',
  [ProjectConversionReason.Other]: 'Other',
};

export const ProjectValueMapping: DisplayNameMapping = {
  [ProjectValue.Low]: 'Low',
  [ProjectValue.Medium]: 'Medium',
  [ProjectValue.High]: 'High',
};

export const ProjectIncrementalScoreMapping: DisplayNameMapping = {
  [ProjectIncrementalScore.One]: '1 - Armstrong can win this project without sales efforts',
  [ProjectIncrementalScore.Two]: '2',
  [ProjectIncrementalScore.Three]: '3',
  [ProjectIncrementalScore.Four]: '4',
  [ProjectIncrementalScore.Five]: '5 - Project can only be won by Armstrong with sales efforts',
};

export const ProjectOrigin = {
  Lead: 'lead',
  Stakeholder: 'stakeholder',
  Organization: 'organization',
};

export enum ModalType {
  AddLeadStakeholder = 'leadStakeholder',
  AddOrganizationStakeholder = 'organizationStakeholder',
  AddOrganization = 'organization',
}

export const SourceTypeMapping: DisplayNameMapping = {
  [SourceType.Permits]: 'Permit',
  [SourceType.Violations]: 'Violation',
  [SourceType.Surveys]: 'Survey',
  [SourceType.Metadata]: 'Metadata',
  [SourceType.Contacts]: 'Contact',
};

export const DateRangeMapping: DisplayNameMapping = {
  [DateRanges.PastWeek]: 'Past Week',
  [DateRanges.PastMonth]: 'Past Month',
  [DateRanges.Past3Months]: 'Past 3 Months',
  [DateRanges.Past6Months]: 'Past 6 Months',
  [DateRanges.PastYer]: 'Past Year',
};

export const StakeholderRoleMapping: DisplayNameMapping = {
  [StakeholderRole.Applicant]: 'Applicant',
  [StakeholderRole.Architect]: 'Architect',
  [StakeholderRole.Contractor]: 'Contractor',
  [StakeholderRole.Designer]: 'Designer',
  [StakeholderRole.Installer]: 'Installer',
  [StakeholderRole.Owner]: 'Owner',
  [StakeholderRole.OwnerBldr]: 'Owner/Builder',
  [StakeholderRole.Principal]: 'Principal',
  [StakeholderRole.Subcontractor]: 'Sub-Contractor',
  [StakeholderRole.Other]: 'Other',
};

export const FILTER_LABELS: readonly string[] = Object.freeze([
  // TODO: uncomment to use location search bar for state and county search
  // 'Location', // only used for AddSearchForm to condense all location filters into one
  'Region',
  'State',
  'Province',
  'Score',
  'Data Source',
  'Owner',
  'Project Value',
  'Publish Date',
  'Action Stage',
  'Spec Alerts',
  'Keywords',
  'Project Types',
  'Account Ratings',
]);

export const SCORE_LABELS: readonly string[] = Object.freeze([
  '80 - 100',
  '60 - 80',
  '40 - 60',
  '20 - 40',
  '0 - 20',
  'Minimum',
  'Maximum',
]);

export const DATA_SOURCE_LABELS: readonly string[] = Object.freeze([
  'Build Central',
  'CMD',
  'Dodge',
  'Hospital Survey',
  'Permits',
  'Violations',
]);

export const EVENT_DATE_LABELS: readonly string[] = Object.freeze([
  '<1 Month',
  '<3 Months',
  '<6 Months',
  '<12 Months',
  '<24 Months',
]);

export const ACCOUNT_RATING_LABELS: readonly string[] = Object.freeze(['A', 'B', 'C', 'No Rating']);

export const US_STATES: Record<string, string> = Object.freeze({
  AL: 'AL',
  AK: 'AK',
  AS: 'AS',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FM: 'FM',
  FL: 'FL',
  GA: 'GA',
  GU: 'GU',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MH: 'MH',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NY: 'NY',
  NC: 'NC',
  ND: 'ND',
  MP: 'MP',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PW: 'PW',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VI: 'VI',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
});
export const ACTION_STAGES: Record<string, string> = {
  'Pre-design': 'Pre-design',

  Design: 'Design',

  'Bidding/Negotiating': 'Bidding/Negotiating',

  Construction: 'Construction',

  Operation: 'Operation',
};

export const actionStageValueMap: Record<string, string[]> = Object.freeze({
  'Pre-design': ['PREDESIGN'],
  Design: ['DESIGN'],
  'Bidding/Negotiating': ['BIDDING'],
  Construction: ['CONSTRUCTION'],
  Operation: ['OPERATION'],
});
