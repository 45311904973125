import * as Yup from 'yup';
import isNil from 'lodash/isNil';

import { Stakeholder } from '.';

import type { IBaseModelResponse } from './baseModel';
import BaseModel from './baseModel';
import type { IEventResponse } from './event';
import Event from './event';
import type { IPropertyResponse } from './property';
import Property from './property';
import type { IUserResponse } from './user';
import User from './user';
import { IStakeholderResponse } from './stakeholder';

import { ProjectStatusAction } from '../models/common';
import type { ProjectSpaceType, ProjectStage, ProjectType } from '../models/common';

export type ProjectExternals = {
  external_id: string;
  external_label: string;
  external_link: string;
  id: string;
  project_id: string;
};

export type TransitionDetails = {
  reason?: string;
  note?: string;
  value?: string;
  transition_date?: string;
};

export type IProjectResponse = IBaseModelResponse & {
  name: string;
  description?: string;
  value?: number | string;
  type: ProjectType;
  stage?: ProjectStage;
  externals?: ProjectExternals;
  status: string;
  space_type?: ProjectSpaceType;
  materials?: string;
  total_sqft?: number | string;
  ceiling_sqft?: number | string;
  creator?: IUserResponse;
  owner?: IUserResponse;
  property?: IPropertyResponse;
  transition_details?: TransitionDetails;
  lead_value?: string;
  lead_score_increment?: string;
  events?: IEventResponse[];
  total_events?: number | string;
  origin?: string;
  stakeholders: IStakeholderResponse[];
  sfdc_opportunity_id?: string;
  sfdc_project_lead_id?: string;
  score?: number;
  owned_by?: string | null;
  property_id?: string;
};

export type IProjectRequest = Pick<
  IProjectResponse,
  | 'name'
  | 'description'
  | 'value'
  | 'type'
  | 'stage'
  | 'space_type'
  | 'total_sqft'
  | 'ceiling_sqft'
  | 'origin'
> & { owned_by?: string };

export const ProjectRequestShape = {
  name: Yup.string().required('Required').max(100, 'Maximum name length is 100 characters'),
  description: Yup.string(),
  value: Yup.number().max(1e12, 'Maximum valuation is $1 trillion'),
  total_sqft: Yup.number()
    .max(1e9, 'Maximum is 1 billion square feet')
    .when('$ceiling_sqft', (ceilingSqft: number, schema: Yup.NumberSchema) =>
      isNil(ceilingSqft)
        ? schema
        : schema.min(
            ceilingSqft,
            'Total square feet cannot be less than current ceiling square feet',
          ),
    )
    .when('ceiling_sqft', (ceilingSqft: number, schema: Yup.NumberSchema) =>
      isNil(ceilingSqft)
        ? schema
        : schema.min(
            ceilingSqft,
            'Total square feet cannot be less than current ceiling square feet',
          ),
    ),
  ceiling_sqft: Yup.number()
    .when('$total_sqft', (totalSqft: number, schema: Yup.NumberSchema) =>
      isNil(totalSqft)
        ? schema
        : schema.max(
            totalSqft,
            'Ceiling square feet cannot be greater than current total square feet',
          ),
    )
    .when('total_sqft', (totalSqft: number, schema: Yup.NumberSchema) =>
      isNil(totalSqft)
        ? schema
        : schema.max(
            totalSqft,
            'Ceiling square feet cannot be greater than current total square feet',
          ),
    ),
};

export const ProjectRequestSchema = Yup.object().shape(ProjectRequestShape, [
  ['total_sqft', 'ceiling_sqft'],
]);

const DemotionShape = {
  reason: Yup.string().required('Required'),
  notes: Yup.string().max(256, 'Notes are limited to 256 characters'),
};

const ConversionShape = {
  ...DemotionShape,
  score: Yup.string().required('Required'),
};

export const ProjectStatusChangeSchema = (schema: ProjectStatusAction): Yup.ObjectSchema =>
  Yup.object().shape(schema === ProjectStatusAction.Convert ? ConversionShape : DemotionShape);

const QUALIFICATION_KEYS: (keyof Project)[] = [
  'value',
  'type',
  'stage',
  'spaceType',
  'totalSqft',
  'ceilingSqft',
];

export default class Project extends BaseModel {
  name: string;
  description?: string;
  value?: number;
  type: ProjectType;
  stage?: ProjectStage;
  externals?: ProjectExternals;
  status: string;
  spaceType?: string;
  materials?: string;
  totalSqft?: number;
  ceilingSqft?: number;
  creator?: User;
  owner?: User;
  property?: Property;
  events?: Event[];
  totalEvents?: number;
  transition_details?: {
    reason?: string;
    note?: string;
    transition_date?: string;
    value?: string;
  };
  stakeholders?: Stakeholder[];
  sfdc_opportunity_id?: string;
  sfdc_project_lead_id?: string;
  score?: number;
  propertyId?: string;

  constructor(data: IProjectResponse) {
    super(data);

    const {
      name,
      description,
      value,
      type,
      stage,
      externals,
      status,
      space_type,
      materials,
      total_sqft,
      ceiling_sqft,
      creator,
      owner,
      property,
      events,
      total_events,
      transition_details,
      stakeholders,
      sfdc_opportunity_id,
      sfdc_project_lead_id,
      score,
      property_id,
    } = data;
    this.name = name;
    this.description = description;
    const valueNumber = Number(value);
    this.value = Number.isNaN(valueNumber) ? undefined : valueNumber;
    this.type = type;
    this.stage = stage;
    this.externals = externals;
    this.status = status;
    this.spaceType = space_type;
    this.materials = materials;
    this.totalSqft = total_sqft ? Number(total_sqft) : undefined;
    this.ceilingSqft = ceiling_sqft ? Number(ceiling_sqft) : undefined;
    this.creator = creator ? new User(creator) : undefined;
    this.owner = owner ? new User(owner) : undefined;
    this.property = property ? new Property(property) : undefined;
    this.events = events?.map(data => new Event(data));
    this.totalEvents = total_events ? Number(total_events) : undefined;
    this.transition_details = transition_details;
    this.stakeholders =
      stakeholders && stakeholders.map(stakeholder => new Stakeholder(stakeholder));
    this.sfdc_opportunity_id = sfdc_opportunity_id;
    this.sfdc_project_lead_id = sfdc_project_lead_id;
    this.score = score;
    this.propertyId = property_id;
  }

  get isActive(): boolean {
    return this.status === 'active';
  }

  get creatorDisplayName(): string {
    return this.creator?.displayName || '';
  }

  get ownerDisplayName(): string {
    return this.owner?.displayName || '';
  }

  get totalEventsCount(): number {
    return this.events?.length || 0;
  }

  get completedQualificationsCount(): number {
    return QUALIFICATION_KEYS.map(key => this[key]).filter(x => !!x).length;
  }

  get totalQualificationsCount(): number {
    return QUALIFICATION_KEYS.length;
  }

  get isIncremental(): boolean {
    if (!this.isActive || !this.events) {
      return false;
    }
    for (const event of this.events) {
      if (event.incrementalFlag) {
        return true;
      }
    }
    return false;
  }
}
