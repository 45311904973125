import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { POWERBI_URL } from '../../../constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: '2.5rem',
    width: '48vw',
    height: 'calc(100vh - 64px)',
    maxWidth: 'none',
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
}));

const AnalyticsPage: React.FC = () => {
  const classes = useStyles();

  const url =
    POWERBI_URL ||
    'https://app.powerbi.com/reportEmbed?reportId=134d3e86-a93a-4d56-aacb-2580e7d5f971&autoAuth=true&ctid=5b00abe1-105d-47d0-ab18-b84d5ea65f29';

  return (
    <Container className={classes.root}>
      <iframe
        title="Cleo Dashboard"
        src={url}
        frameBorder="0"
        allowFullScreen={true}
        className={classes.iframe}
      />
    </Container>
  );
};

export default AnalyticsPage;
