import { IProject } from '../Components/Pages/Leads/Archive/Archive.config';
import { getFullAddress } from '../utils/address';

export type IPVFResponse = IProject;

interface IMapProjectDetails {
  sold: string;
  opportunity: string;
  highValue: string;
  other: string;
  alreadyTracked: string;
  jobAlreadyCompleted: string;
  noOpportunity: string;
  noContact: string;
  competitor: string;
  residentalProject: string;
}

export const mapProjectDetails: IMapProjectDetails = {
  sold: 'Sold',
  opportunity: 'Opportunity',
  highValue: 'High Value',
  other: 'Other',
  alreadyTracked: 'Already Tracked',
  jobAlreadyCompleted: 'Job Already Completed',
  noOpportunity: 'No Opportunity',
  noContact: 'No Contact',
  competitor: 'Competitor',
  residentalProject: 'Residential Project',
};

export default class PvfProject {
  id: string;
  projectId: string;
  projectName: string;
  projectStatus: string;
  hide: boolean;
  propertyId: string;
  address1: string;
  city: string;
  state: string;
  actionStage: string;
  mappedActionStage: string;
  value: number;
  value1: number;
  bidDate: string;
  eventDate: string;
  projectType: string;
  categories: string;
  lastUpdated: string;
  projectScore: number;
  zipcode: string;
  publishDate: string;
  size: string;
  countyState: string;
  cityState: string;
  latestEvent: string;
  latestEventDate: string;
  isNew: boolean;
  dataSource: string;
  specAlerts: string;
  projectNote: string;
  projectUrl: string;
  ownerId: string;
  primaryProjectType: string;
  transitionReason?: string;
  transitionDate?: string;
  isFavorite: boolean;
  hasNationalAccount?: boolean;
  hasNationalTargetAccount?: boolean;
  outcome?: string;
  constructor(data: IPVFResponse) {
    const {
      id,
      projectId,
      projectName,
      projectStatus,
      hide,
      propertyId,
      address1,
      city,
      state,
      actionStage,
      value,
      value1,
      bidDate,
      eventDate,
      projectType,
      categories,
      lastUpdated,
      projectScore,
      zipcode,
      publishDate,
      size,
      countyState,
      cityState,
      latestEvent,
      latestEventDate,
      isNew,
      dataSource,
      specAlerts,
      projectNote,
      projectUrl,
      ownerId,
      primaryProjectType,
      transitionDetails,
      isFavorite,
      hasNationalAccount,
      hasNationalTargetAccount,
      mappedActionStage,
      outcome,
    } = data;

    let transitionReason;
    if (transitionDetails?.reason) {
      const key = transitionDetails.reason as keyof IMapProjectDetails;
      transitionReason = mapProjectDetails[key] || mapProjectDetails.other;
    }

    this.id = id || '';
    this.projectId = projectId || '';
    this.projectName = projectName;
    this.projectStatus = projectStatus;
    this.hide = hide;
    this.propertyId = propertyId;
    this.address1 = address1;
    this.city = city;
    this.state = state;
    this.actionStage = actionStage;
    this.value = value;
    this.value1 = value1;
    this.bidDate = bidDate;
    this.eventDate = eventDate;
    this.projectType = projectType;
    this.categories = categories;
    this.lastUpdated = lastUpdated;
    this.projectScore = projectScore;
    this.zipcode = zipcode;
    this.publishDate = publishDate;
    this.size = size;
    this.countyState = countyState;
    this.cityState = cityState;
    this.latestEvent = latestEvent;
    this.latestEventDate = latestEventDate;
    this.isNew = isNew;
    this.dataSource = dataSource;
    this.specAlerts = specAlerts;
    this.projectNote = projectNote;
    this.projectUrl = projectUrl;
    this.ownerId = ownerId;
    this.primaryProjectType = primaryProjectType;
    this.transitionReason = transitionReason || mapProjectDetails.other;
    this.transitionDate = transitionDetails?.transition_date || '';
    this.isFavorite = !!isFavorite;
    this.hasNationalAccount = hasNationalAccount;
    this.hasNationalTargetAccount = hasNationalTargetAccount;
    this.mappedActionStage = mappedActionStage;
    this.outcome = outcome;
  }

  get fullAddress(): string {
    return getFullAddress({
      address1: this.address1,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
    });
  }
}
