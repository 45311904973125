import axios, { Canceler, CancelToken } from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URL } from '../constants';
import AuthUtils from '../utils/auth';
import { Optional, RollbarLogEventTypes } from '../types';
import { isAxiosError, reportRollbar } from '../utils/errors';

export interface IResponse<T> {
  items: T[]; //returned records in one page
  total: number; // total number of returned records without pagination
}

export type ItemResponse<T> = T;

export type APICancel = Optional<Canceler>;
export type APICancelToken = CancelToken;

const axiosConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
  },
  timeout: 5000,
};

const API = axios.create(axiosConfig);

export const createCancelToken = (executor: (cancel: Canceler) => void): CancelToken =>
  new axios.CancelToken(executor);

export const isCancel = axios.isCancel;

API.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = await AuthUtils.getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

//on successful response
API.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: Error | AxiosError) => {
    if (isAxiosError(error) && error.response) {
      const { status, headers, data } = error.response;
      console.error(
        `status: ${status}', headers: ${headers && JSON.stringify(headers)}, message: ${
          data.message
        }`,
      );
      if (status !== 404) {
        reportRollbar(
          RollbarLogEventTypes.ERROR,
          `statusCode: ${status}, message: ${data.message}`,
        );
      }
      if (status === 408 || error.code === 'ECONNABORTED') {
        console.error(`A timeout happened on url ${error.config.url}`);
      }
    } else if (!axios.isCancel(error)) {
      console.error(`General error ocurred ${error.message}`);
    }
    return Promise.reject(error);
  },
);

export default API;
