import API from './api';

import { FilterOptionsResponse, UserFilterNameRequest, UserFilterNameResponse } from '../types';
import { CleoObjectType } from '../models/common';
import { IUserResponse, User } from '../models';

export const getFilterOptions = async () => {
  const { data } = await API.get('/filters/filter-options');
  return data as FilterOptionsResponse;
};

export const instantiateUsersInSavedFiltersList = (
  savedFilters: UserFilterNameResponse[] | UserFilterNameResponse,
) => {
  const adjustedSavedFilters = Array.isArray(savedFilters)
    ? savedFilters.map((savedFilter: UserFilterNameResponse) => ({
        ...savedFilter,
        user: new User({
          ...savedFilter.user,
          created_at: savedFilter.created_at,
          updated_at: savedFilter.updated_at,
        } as IUserResponse),
      }))
    : {
        ...savedFilters,
        user: new User({
          ...savedFilters.user,
          created_at: savedFilters.created_at,
          updated_at: savedFilters.updated_at,
        } as IUserResponse),
      };
  return adjustedSavedFilters;
};

export const getSavedFiltersList = async (
  sort: { orderBy: string; order: string },
  objectType: CleoObjectType = CleoObjectType.Addresses,
) => {
  const filterType = objectType === CleoObjectType.Addresses ? 'address' : 'project_lead';
  const {
    data: { items },
  } = await API.get(`/user-filters/${sort.orderBy}/${sort.order}`, {
    params: { filterType },
  });
  return instantiateUsersInSavedFiltersList(items) as UserFilterNameResponse[];
};

export const getSavedFilterById = async (savedFilterId: string) => {
  const { data } = await API.get(`/user-filters/${savedFilterId}`);
  return instantiateUsersInSavedFiltersList(data) as UserFilterNameResponse;
};

export const saveFilterName = async (filterBody: UserFilterNameRequest) => {
  const { data } = await API.post('/user-filters/', filterBody);
  return instantiateUsersInSavedFiltersList(data) as UserFilterNameResponse;
};

export const editSavedFilterName = async (
  userFilterId: string,
  editedFilterBody: UserFilterNameRequest,
) => {
  const { data } = await API.patch(`/user-filters/${userFilterId}`, editedFilterBody);
  return instantiateUsersInSavedFiltersList(data) as UserFilterNameResponse;
};

export const deleteFilterName = async (userFilterId: string) => {
  await API.delete(`/user-filters/${userFilterId}`);
};
