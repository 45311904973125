import React from 'react';
import { Modal, Fade, Grid, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { paleGray, white } from '../../theme';

const useStyles = makeStyles(theme => ({
  root: {
    outline: 0,
    marginTop: '5%',
  },
  modal: {
    overflow: 'scroll',
  },
  closeButton: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    backgroundColor: white,
    boxShadow: 'none',
    margin: theme.spacing(8, 0),
    '&:hover': {
      backgroundColor: paleGray,
    },
  },
  closeButtonIcon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    color: theme.palette.success.main,
  },
}));

type IProps = {
  label: string;
  isOpen: boolean;
  handleClose: () => void;
};

const ModalLayout: React.FC<IProps> = ({ label, isOpen, handleClose, children }) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`${label}-modal-title`}
      aria-describedby={`${label}-modal-description`}
      className={classes.modal}
    >
      <Fade in={isOpen}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          {children}
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={handleClose}
            data-testid="closeButton"
          >
            <CloseIcon className={classes.closeButtonIcon} />
          </IconButton>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ModalLayout;
