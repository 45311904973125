import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import includes from 'lodash/includes';
import { Button, Divider, FormControl, Input, Typography } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

import LeadsFilterItemLabel from './LeadsFilterItemLabel';
import { LeadsFilterSection } from './LeadsFilterSection';
import LeadsFilterButtons from './LeadsFilterButtons';

import SaveFilterForm from '../SavedFilters/SaveFilterForm';
import { ScoreStatus } from '../../../../models/lead';
import {
  allKeysSelectedState,
  currentFilterOptionsState,
  editedFilterNameState,
  filterItemsOpenState,
  filterOptionState,
  searchCountyValueState,
  searchKeywordValueState,
  searchLocationValueState,
  searchProjectTypeValueState,
  searchProvinceValueState,
  searchRegionValueState,
  searchSpecAlertValueState,
  searchStateValueState,
  showMoreStateOptionsState,
  showSeeMoreLinksState,
} from '../../../../state/atoms/filters';
import {
  FilterData,
  FilterItem,
  FilterOptionsItem,
  Optional,
  TagsDisplay,
  UserFilterNameResponse,
} from '../../../../types';
import { getAllFilterOptions, getLeadsConfig } from '../Leads.config';
import { StyledFilterItem, useLeadsStyles } from '../Leads.styles';
import { userOptionsState } from '../../../../state/atoms/users';
import { CleoObjectType } from '../../../../models/common';
import { darkBlue, foundationBlue, primaryBlue, white } from '../../../../theme';
import { editSavedFilterName } from '../../../../api/filters';
import { useMessages } from '../../../../state/contexts';

interface IProps {
  handleFilterChange: (filterData: FilterData) => void;
  hasFilterBeenCleared: () => boolean;
  setSavedFilter: (savedFilter: UserFilterNameResponse) => void;
  savedFilter?: UserFilterNameResponse;
  setScoreStatus: (status: ScoreStatus) => void;
  cleoObjectType?: CleoObjectType;
  filterTagsToDisplay: TagsDisplay[];
  filterType: 'address' | 'project_lead';
  editedFilterNameError?: string;
  setEditedFilterNameError: (error: string) => void;
}

const LeadsFilterContainer: React.FC<IProps> = ({
  handleFilterChange,
  hasFilterBeenCleared,
  setScoreStatus,
  setSavedFilter,
  savedFilter,
  cleoObjectType = CleoObjectType.Addresses,
  filterTagsToDisplay,
  filterType,
  editedFilterNameError,
  setEditedFilterNameError,
}: IProps) => {
  const classes = useLeadsStyles();
  const { user } = useAuth0();
  const searchLocationValue = useRecoilValue(searchLocationValueState);
  const searchStateValue = useRecoilValue(searchStateValueState);
  const searchCountyValue = useRecoilValue(searchCountyValueState);
  const searchRegionValue = useRecoilValue(searchRegionValueState);
  const searchProvinceValue = useRecoilValue(searchProvinceValueState);
  const searchSpecAlertValue = useRecoilValue(searchSpecAlertValueState);
  const searchKeywordValue = useRecoilValue(searchKeywordValueState);
  const filterOption = useRecoilValue(filterOptionState);
  const allKeysSelected = useRecoilValue(allKeysSelectedState);
  const currentFilterOptions = useRecoilValue(currentFilterOptionsState);

  const searchProjectTypeValue = useRecoilValue(searchProjectTypeValueState);
  const showMore = useRecoilValue(showMoreStateOptionsState);
  const userOptions = useRecoilValue(userOptionsState);

  const setShowSeeMoreLinks = useSetRecoilState(showSeeMoreLinksState);

  const [filterItemsOpen] = useRecoilState(filterItemsOpenState);

  const { setSuccessMessage } = useMessages();

  const [editedFilterName, setEditedFilterName] = useRecoilState<string>(editedFilterNameState);

  const [hasBeenChanged, setHasBeenChanged] = React.useState(false);

  const [initialFilterOption, setInitialFilterOption] = React.useState<Optional<FilterData>>(
    undefined,
  );

  const getOptions = useCallback(
    (i: FilterItem) => {
      const filterOptions = getAllFilterOptions({
        filterItem: i,
        userOptions,
        searchStateValue,
        searchCountyValue,
        searchRegionValue,
        searchKeywordValue,
        searchSpecAlertValue,
        searchProjectTypeValue,
        searchLocationValue,
        searchProvinceValue,
      });

      if (filterOptions.length > 6) {
        setShowSeeMoreLinks(true);
      } else {
        setShowSeeMoreLinks(false);
      }

      if (showMore) {
        return filterOptions;
      } else {
        return filterOptions.slice(0, 6);
      }
    },
    [
      userOptions,
      searchStateValue,
      searchCountyValue,
      searchRegionValue,
      searchProvinceValue,
      showMore,
      setShowSeeMoreLinks,
      searchSpecAlertValue,
      searchKeywordValue,
      searchProjectTypeValue,
      searchLocationValue,
    ],
  );

  const isOpen = useCallback(
    (filterLabel: string): boolean => {
      return filterItemsOpen[filterLabel];
    },
    [filterItemsOpen],
  );

  const isFilterItemChecked = useCallback(
    (filterOptionsItem: FilterOptionsItem, filterItem: FilterItem) => {
      let optionItems: any = [];
      // Check if filterKey is on filterOptionItem (for location filters - sub-filters). Default to filterItem.filterKey
      const filterKey = filterOptionsItem.filterKey || filterItem.filterKey;

      if (
        allKeysSelected &&
        allKeysSelected[filterKey] &&
        allKeysSelected[filterKey][filterOptionsItem.comparator] &&
        !optionItems.includes(allKeysSelected[filterKey][filterOptionsItem.comparator])
      ) {
        optionItems = [...optionItems].concat(
          allKeysSelected[filterKey][filterOptionsItem.comparator],
        );
      }

      return (
        includes(
          optionItems.map((optionItem: any) => optionItem.toString()),
          filterOptionsItem.value?.toString(),
        ) || filterOptionsItem.value === optionItems
      );
    },
    [allKeysSelected],
  );

  const handleFilterUpdate = useCallback(async () => {
    if (savedFilter && filterOption && editedFilterName.length > 0) {
      try {
        const editedFilterResponse = await editSavedFilterName(savedFilter.id, {
          user_id: savedFilter.user_id,
          filter_name: editedFilterName,
          filter: filterOption,
          shared: true,
          filter_tags: {
            tags: filterTagsToDisplay,
          },
          filter_type: filterType,
        });
        setScoreStatus(ScoreStatus.Home);
        setSavedFilter(editedFilterResponse);
        setSuccessMessage('Your filter name has been changed.');
      } catch (e: any) {
        if (e.response && e.response.data) {
          setEditedFilterNameError(
            e.response.data.message === 'Data already exists in database'
              ? 'Filter Name already exists, please try again'
              : e.response.data.message,
          );
        }
      }
    } else {
      setEditedFilterNameError('You must give this filter a name in order to save it');
    }
  }, [
    setSuccessMessage,
    editedFilterName,
    filterOption,
    savedFilter,
    filterTagsToDisplay,
    setEditedFilterNameError,
    setSavedFilter,
    setScoreStatus,
    filterType,
  ]);

  useEffect(() => {
    if (initialFilterOption) {
      setHasBeenChanged(true);
    } else {
      setInitialFilterOption(filterOption);
    }
  }, [initialFilterOption, filterOption]);

  return (
    <>
      <div className={classes.filterContainer}>
        {getLeadsConfig(currentFilterOptions)
          .filter(config => {
            if (!config.model.includes(cleoObjectType)) {
              return false;
            }
            return !(
              config.filterKey === 'accountRatings' && cleoObjectType === CleoObjectType.Addresses
            );
          })
          .map((config: FilterItem, i: number) => {
            return (
              <div
                key={`${config.filterKey}}-${i}`}
                className={classes.filterItem}
                style={{
                  backgroundColor: isOpen(config.filterLabel) ? foundationBlue : primaryBlue,
                }}
              >
                <StyledFilterItem>
                  <Typography className={classes.filterLabel}>{config.filterLabel}</Typography>
                  <LeadsFilterItemLabel filterItem={config.filterLabel} />
                </StyledFilterItem>
                <LeadsFilterSection
                  isFilterItemChecked={isFilterItemChecked}
                  getOptions={getOptions}
                  config={config}
                  key={i}
                />
              </div>
            );
          })}
      </div>
      <Divider variant="fullWidth" className={classes.filterDividerBottom} flexItem />
      <div
        style={
          editedFilterNameError
            ? { backgroundColor: white, margin: '0 .5rem', paddingTop: '.5rem' }
            : {}
        }
      >
        <LeadsFilterButtons
          allKeysSelected={allKeysSelected}
          handleFilterChange={handleFilterChange}
          editedFilterNameError={editedFilterNameError}
        />

        {cleoObjectType === CleoObjectType.Accounts ? (
          <></>
        ) : (
          <>
            <SaveFilterForm
              filterOption={filterOption}
              setScoreStatus={setScoreStatus}
              setSavedFilter={setSavedFilter}
              cleoObjectType={cleoObjectType}
              disabled={hasFilterBeenCleared()}
            />
            <FormControl
              style={{ width: '100%' }}
              disabled={!savedFilter || savedFilter.user.email !== user.email}
            >
              <div style={{ margin: '1rem 1rem .5rem 1rem' }}>
                <Typography
                  className={
                    editedFilterNameError
                      ? classes.editedFilterNameErrorText
                      : classes.editedFilterNameText
                  }
                  style={!savedFilter ? { color: '#DFE2E1' } : {}}
                >
                  {editedFilterNameError ? 'Updated Filter?' : 'Update Filter'}
                </Typography>
                <Input
                  value={
                    editedFilterName || editedFilterName === ''
                      ? editedFilterName
                      : savedFilter?.filter_name
                  }
                  placeholder={'Enter Text Here (optional)'}
                  onChange={evt => setEditedFilterName(evt.currentTarget.value)}
                  id={`saved-filter-value-input-display`}
                  onKeyPress={ev => ev.key === 'Enter' && handleFilterUpdate()}
                  className={
                    editedFilterNameError
                      ? classes.updatedFilterNameErrorInput
                      : classes.filterNameInput
                  }
                  style={!savedFilter ? { backgroundColor: '#DFE2E1', color: 'black' } : {}}
                  disableUnderline
                />
              </div>
              <Typography
                style={
                  editedFilterNameError
                    ? { fontSize: '.75rem', margin: '.25rem 1rem .5rem' }
                    : { fontSize: '.75rem' }
                }
                className={
                  editedFilterNameError
                    ? classes.editedFilterNameErrorText
                    : classes.editedFilterNameText
                }
              >
                {editedFilterNameError ? `Error: ${editedFilterNameError}` : ''}
              </Typography>
              <Button
                style={
                  editedFilterNameError
                    ? { backgroundColor: darkBlue, color: white, marginBottom: '1rem' }
                    : !savedFilter
                    ? { backgroundColor: '#DFE2E1', color: 'black' }
                    : {}
                }
                className={classes.saveFilterButton}
                onClick={() => handleFilterUpdate()}
                disabled={
                  !savedFilter ||
                  savedFilter.user.email !== user.email ||
                  (editedFilterName === savedFilter?.filter_name && !hasBeenChanged)
                }
              >
                SAVE CHANGES
              </Button>
            </FormControl>
          </>
        )}
      </div>
    </>
  );
};

export default LeadsFilterContainer;
