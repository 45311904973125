import React, { useCallback, useState } from 'react';
import { Box, Button, FormControl, Input, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth0 } from '@auth0/auth0-react';

import { editSavedFilterName } from '../../../../api/filters';
import { ScoreStatus } from '../../../../models/lead';
import { UserFilterNameResponse } from '../../../../types';
import { useLeadsStyles } from '../Leads.styles';
import {
  editedFilterNameState,
  filterOptionState,
  filterTagsToDisplayState,
} from '../../../../state/atoms/filters';
import { ProjectLeadStatus } from '../../../../models/projectLead';
import { CleoObjectType } from '../../../../models/common';
import { useMessages } from '../../../../state/contexts';

interface IProps {
  editedFilterNameError: string | undefined;
  savedFilter: UserFilterNameResponse;
  setScoreStatus: (status: ScoreStatus | ProjectLeadStatus) => void;
  setSavedFilter: (filter: UserFilterNameResponse) => void;
  setEditedFilterNameError: (filterNameError: string) => void;
  cleoObjectType?: CleoObjectType;
}

function SavedFilterHeader({
  editedFilterNameError,
  savedFilter,
  setScoreStatus,
  setSavedFilter,
  setEditedFilterNameError,
  cleoObjectType,
}: IProps) {
  const classes = useLeadsStyles();
  const { user } = useAuth0();
  const [editingSavedFilterName, setEditingSavedFilterName] = useState<boolean>(false);
  const [editedFilterName, setEditedFilterName] = useRecoilState<string>(editedFilterNameState);

  const { setSuccessMessage } = useMessages();

  const filterTagsToDisplay = useRecoilValue(filterTagsToDisplayState);
  const filterOption = useRecoilValue(filterOptionState);
  const filterType = cleoObjectType === CleoObjectType.Addresses ? 'address' : 'project_lead';

  const handleEditSavedFilterName = useCallback(async () => {
    if (savedFilter && filterOption && editedFilterName.length > 0) {
      try {
        const editedFilterResponse = await editSavedFilterName(savedFilter.id, {
          user_id: savedFilter.user_id,
          filter_name: editedFilterName,
          filter: filterOption,
          shared: true,
          filter_tags: {
            tags: filterTagsToDisplay,
          },
          filter_type: filterType,
        });
        setEditingSavedFilterName(false);
        setScoreStatus(ScoreStatus.Home);
        setSavedFilter(editedFilterResponse);
        setSuccessMessage('Your filter name has been changed.');
      } catch (e: any) {
        if (e.response && e.response.data) {
          setEditedFilterNameError(
            e.response.data.message === 'Data already exists in database'
              ? 'Filter Name already exists, please try again'
              : e.response.data.message,
          );
        }
      }
    } else {
      setEditedFilterNameError('You must give this filter a name in order to save it');
    }
  }, [
    setSuccessMessage,
    editedFilterName,
    filterOption,
    savedFilter,
    filterTagsToDisplay,
    setEditedFilterNameError,
    setSavedFilter,
    setScoreStatus,
    filterType,
  ]);

  const handleEditFilterNameCancel = useCallback(() => {
    setEditingSavedFilterName(false);
    setEditedFilterNameError('');
    setEditedFilterName(savedFilter.filter_name);
  }, [setEditedFilterNameError, setEditedFilterName, savedFilter]);

  return (
    <Box
      className={classes.filterNameContainer}
      style={{ borderBottom: !editingSavedFilterName ? '1.5px solid #0C4377' : '' }}
    >
      {!editingSavedFilterName ? (
        <Typography className={classes.filterNameDisplay}>
          {savedFilter.filter_name}
          {savedFilter.user.email === user.email && (
            <sup>
              <EditIcon
                className={classes.filterNameEditIcon}
                onClick={() => {
                  setEditingSavedFilterName(true);
                }}
              />
            </sup>
          )}
        </Typography>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              value={
                editedFilterName || editedFilterName === ''
                  ? editedFilterName
                  : savedFilter.filter_name
              }
              onChange={evt => setEditedFilterName(evt.currentTarget.value)}
              id={`edited-filter-value-input-display`}
              onKeyPress={ev => ev.key === 'Enter' && handleEditSavedFilterName()}
              className={
                !editedFilterNameError
                  ? classes.filterNameEditInput
                  : classes.filterNameErrorEditInput
              }
              disableUnderline
            />
            <div className={classes.filterNameEditButtonGroup}>
              <Button
                onClick={() => handleEditSavedFilterName()}
                className={classes.filterNameEditSaveButton}
                size="small"
              >
                SAVE NAME
              </Button>
              <Button
                onClick={() => handleEditFilterNameCancel()}
                className={classes.filterNameEditCancelButton}
              >
                CANCEL
              </Button>
            </div>
          </FormControl>
          {editedFilterNameError && (
            <Typography style={{ color: '#f03434' }}>Error: {editedFilterNameError}</Typography>
          )}
        </div>
      )}

      <Typography className={classes.filterNameDetails}>
        <strong>{savedFilter.user.fullName || savedFilter.user.email}</strong>
      </Typography>
      <Typography className={classes.filterNameDetails}>
        <strong>Date Created:</strong> {new Date(savedFilter.created_at).toLocaleDateString()}
      </Typography>
      <Typography className={classes.filterNameDetails}>
        <strong>Last Edited:</strong> {new Date(savedFilter.updated_at).toLocaleDateString()}
      </Typography>
    </Box>
  );
}

export default SavedFilterHeader;
