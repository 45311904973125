import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Grid, Input, Tab, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useParams } from 'react-router-dom';

import useUniversalSearchStyles, {
  StyledTabs,
  TabBarContainer,
  tabStyles,
} from './UniversalSearchPage.styles';
import { contactHeadCells, IDetailStakeholderResponse, SearchContact } from './contactHeadCells';
import { accountHeadCells, IDetailAccountResponse, SearchAccount } from './accountHeadCells';
import { projectHeadCells, IDetailProjectResponse, SearchProject } from './projectHeadCells';

import BackButton from '../../Common/BackButton';
import { ScoreStatus, SearchTabItem, SearchTabs } from '../../../models/lead';
import { toTitleCase } from '../../../utils/string';
import Table, { HeadCell } from '../../Common/Tables/Table';
import {
  getProjectLeadsDetailsPagePath,
  getStakeholderDetailsPagePath,
  getUniversalSearchPagePath,
  getAccountDetailsPagePath,
  getAccountsPagePath,
} from '../../../utils/page';
import { getSearchResults, UniversalSearchResponse } from '../../../api/universalSearch';
import { APICancel, createCancelToken, isCancel } from '../../../api';

let apiCancel: APICancel;
// todo: construct the balance of headerCells

const DEFAULT_ROW_COUNT = 10;
const SCORE_STATUS = ScoreStatus.Converted;

const contactSearchTab: SearchTabItem<IDetailStakeholderResponse, SearchContact> = {
  tabItem: SearchTabs.Contacts,
  label: toTitleCase(SearchTabs.Contacts),
  headerCells: contactHeadCells,
};
const accountSearchTab: SearchTabItem<IDetailAccountResponse, SearchAccount> = {
  tabItem: SearchTabs.Accounts,
  label: toTitleCase(SearchTabs.Accounts),
  headerCells: accountHeadCells,
};

const projectSearchTab: SearchTabItem<IDetailProjectResponse, SearchProject> = {
  tabItem: SearchTabs.Projects,
  label: 'Project Leads',
  headerCells: projectHeadCells,
};

export const SEARCH_TAB_MENU = [projectSearchTab, accountSearchTab, contactSearchTab];

interface IRouteParams {
  search: string;
}

const UniversalSearchPage: React.FC = () => {
  const history = useHistory();
  const classes = useUniversalSearchStyles();
  const { search } = useParams<IRouteParams>();

  const [searchStr, setSearchStr] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(SearchTabs.Projects);
  const [searchResults, setSearchResults] = useState<UniversalSearchResponse>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [contactRows, setContactRows] = useState<SearchContact[]>([]);
  const [hasMoreContactItems, setHasMoreContactItems] = useState<boolean>(true);
  const [contactStartIndex, setContactStartIndex] = useState<number>(0);

  const [accountRows, setAccountRows] = useState<SearchAccount[]>([]);
  const [hasMoreAccountItems, setHasMoreAccountItems] = useState<boolean>(true);
  const [accountStartIndex, setAccountStartIndex] = useState<number>(0);

  const [projectRows, setProjectRows] = useState<SearchProject[]>([]);
  const [hasMoreProjectItems, setHasMoreProjectItems] = useState<boolean>(true);
  const [projectStartIndex, setProjectStartIndex] = useState<number>(0);

  const handleSubmitSearch = useCallback(
    async (startIndex: number, value?: string) => {
      if (apiCancel) {
        apiCancel();
        apiCancel = undefined;
      }
      let objectTypeToLoad: string;
      try {
        let currentStringToSearch = searchStr;
        if (value) {
          switch (value) {
            case SearchTabs.Accounts:
              objectTypeToLoad = 'organizations';
              break;
            case SearchTabs.Contacts:
              objectTypeToLoad = 'stakeholders';
              break;
            case SearchTabs.Projects:
              objectTypeToLoad = 'projects';
              break;
            default:
              objectTypeToLoad = '';
              break;
          }
        } else {
          switch (selectedTab) {
            case SearchTabs.Accounts:
              objectTypeToLoad = 'organizations';
              break;
            case SearchTabs.Contacts:
              objectTypeToLoad = 'stakeholders';
              break;
            case SearchTabs.Projects:
              objectTypeToLoad = 'projects';
              break;
            default:
              objectTypeToLoad = '';
              break;
          }
        }

        if (!searchStr) {
          currentStringToSearch = search;
        }
        const res = await getSearchResults(
          currentStringToSearch,
          objectTypeToLoad,
          startIndex,
          DEFAULT_ROW_COUNT,
          createCancelToken(cancel => {
            apiCancel = cancel;
          }),
        );
        if (!res.items) {
          throw new Error('Did not receive searchResults');
        }
        const { items, universalSearchStr } = res;
        switch (objectTypeToLoad) {
          case 'stakeholders':
            setContactRows(rows => [...rows, ...items] as SearchContact[]);
            break;
          case 'organizations':
            setAccountRows(rows => [...rows, ...items] as SearchAccount[]);
            break;
          case 'projects':
            setProjectRows(rows => [...rows, ...items] as SearchProject[]);
            break;
          default:
            break;
        }
        setSearchStr(universalSearchStr);
        setSearchResults(res);
        if (items.length < DEFAULT_ROW_COUNT) {
          switch (objectTypeToLoad) {
            case 'stakeholders':
              setHasMoreContactItems(false);
              break;
            case 'organizations':
              setHasMoreAccountItems(false);
              break;
            case 'projects':
              setHasMoreProjectItems(false);
              break;
            default:
              break;
          }
        }
      } catch (err) {
        if (!isCancel(err)) {
          setErrorMessage(err.message);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleContactClickRow = {
    function: (stakeholderId: string) => {
      history.push(getStakeholderDetailsPagePath(stakeholderId));
    },
    param: 'id' as keyof SearchContact,
    link: '/network/contacts/',
  };

  const handleAccountClickRow = {
    function: (accountId: string) => {
      history.push(getAccountDetailsPagePath(accountId));
    },
    param: 'id' as keyof SearchAccount,
    link: `${getAccountsPagePath()}/details/`,
  };

  const handleProjectLeadClickRow = {
    function: (projectId: string) => {
      history.push(getProjectLeadsDetailsPagePath(projectId));
    },
    param: 'id' as keyof SearchProject,
    link: '/project-leads/details/',
  };

  const handleSubmit = useCallback(
    e => {
      if (!searchStr) {
        return;
      }
      e.preventDefault();
      history.push(`${getUniversalSearchPagePath()}/${searchStr}`);
    },
    [searchStr, history],
  );

  const reloadContactSubmitSearch = useCallback(async () => {
    setContactRows([]);
    setHasMoreContactItems(true);
    setContactStartIndex(0);
    await handleSubmitSearch(contactStartIndex, selectedTab);
  }, [handleSubmitSearch, setContactRows, setContactStartIndex, contactStartIndex, selectedTab]);

  const reloadAccountSubmitSearch = useCallback(async () => {
    setAccountRows([]);
    setHasMoreAccountItems(true);
    setAccountStartIndex(0);
    await handleSubmitSearch(accountStartIndex, selectedTab);
  }, [handleSubmitSearch, setAccountRows, setAccountStartIndex, accountStartIndex, selectedTab]);

  const reloadProjectLeadSubmitSearch = useCallback(async () => {
    setProjectRows([]);
    setHasMoreProjectItems(true);
    setProjectStartIndex(0);
    await handleSubmitSearch(projectStartIndex, selectedTab);
  }, [handleSubmitSearch, setProjectRows, setProjectStartIndex, projectStartIndex, selectedTab]);

  const handleTabChange = useCallback(
    (e: ChangeEvent<unknown>, value: SearchTabs) => {
      e.stopPropagation();
      setSelectedTab(value);
      switch (value) {
        case SearchTabs.Projects:
          reloadProjectLeadSubmitSearch().then();
          break;
        case SearchTabs.Accounts:
          reloadAccountSubmitSearch().then();
          break;
        case SearchTabs.Contacts:
          reloadContactSubmitSearch().then();
          break;
      }
    },
    [reloadContactSubmitSearch, reloadAccountSubmitSearch, reloadProjectLeadSubmitSearch],
  );

  useEffect(() => {
    handleSubmitSearch(contactStartIndex, selectedTab);
  }, [handleSubmitSearch, contactStartIndex, selectedTab]);

  useEffect(() => {
    handleSubmitSearch(accountStartIndex, selectedTab);
  }, [handleSubmitSearch, accountStartIndex, selectedTab]);

  useEffect(() => {
    handleSubmitSearch(projectStartIndex, selectedTab);
  }, [handleSubmitSearch, projectStartIndex, selectedTab]);

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid className={classes.headerSearchContainer}>
        <BackButton />

        <form className={classes.searchForm} onSubmit={handleSubmit}>
          <Input
            id="universal-search"
            placeholder={'Search'}
            className={classes.searchInput}
            disableUnderline={true}
            value={decodeURIComponent(searchStr)}
            onChange={e => setSearchStr(e.target.value)}
          />
          <Button className={classes.searchButton} type={'submit'} disableRipple>
            <SearchIcon className={classes.icon} />
          </Button>
        </form>
      </Grid>
      <Grid
        style={{
          height: '-webkit-fill-available',
          backgroundColor: 'white',
        }}
      >
        <Typography
          variant="h3"
          style={{ fontWeight: 'bolder', textAlign: 'center', margin: '2rem' }}
        >
          {`${!searchResults ? 0 : searchResults.searchCount.total} Search Results For "${
            decodeURIComponent(searchStr) || search
          }"`}
        </Typography>
        <Grid>
          <TabBarContainer>
            <StyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="search tab"
              textColor="secondary"
              id="search tab container"
            >
              {SEARCH_TAB_MENU.map(({ tabItem, label }) => {
                return (
                  <Tab
                    key={tabItem}
                    value={tabItem}
                    classes={tabStyles()}
                    textColor="secondary"
                    label={`${label} (${(() => {
                      if (!searchResults) {
                        return 0;
                      }
                      const { searchCount } = searchResults as UniversalSearchResponse;
                      switch (label) {
                        case 'Project Leads':
                          return searchCount.projects;
                        case 'Addresses':
                          return searchCount.properties;
                        case 'Contacts':
                          return searchCount.stakeholders;
                        case 'Accounts':
                          return searchCount.organizations;
                      }
                    })()})`}
                    style={{
                      marginLeft: 'default',
                    }}
                  />
                );
              })}
            </StyledTabs>
          </TabBarContainer>
          <div style={{ padding: '2rem' }}>
            {(() => {
              switch (selectedTab) {
                case 'contacts':
                  return (
                    <Table<IDetailStakeholderResponse, SearchContact>
                      headerCells={
                        SEARCH_TAB_MENU.filter(entry => entry.tabItem === SearchTabs.Contacts)[0]
                          .headerCells as HeadCell<IDetailStakeholderResponse, SearchContact>[]
                      }
                      rowCount={DEFAULT_ROW_COUNT}
                      rows={contactRows}
                      scoreStatus={SCORE_STATUS}
                      startIndex={contactStartIndex}
                      hasMoreItems={hasMoreContactItems}
                      setStartIndex={setContactStartIndex}
                      handleClickRow={handleContactClickRow}
                      reloadItems={reloadContactSubmitSearch}
                      isSortable={false}
                    />
                  );
                case 'accounts':
                  return (
                    <Table<IDetailAccountResponse, SearchAccount>
                      headerCells={
                        SEARCH_TAB_MENU.filter(entry => entry.tabItem === SearchTabs.Accounts)[0]
                          .headerCells as HeadCell<IDetailAccountResponse, SearchAccount>[]
                      }
                      rowCount={DEFAULT_ROW_COUNT}
                      rows={accountRows}
                      scoreStatus={SCORE_STATUS}
                      startIndex={accountStartIndex}
                      hasMoreItems={hasMoreAccountItems}
                      setStartIndex={setAccountStartIndex}
                      handleClickRow={handleAccountClickRow}
                      reloadItems={reloadAccountSubmitSearch}
                      isNationalKey={'sf_type'}
                      isSortable={false}
                    />
                  );
                case 'projects':
                  return (
                    <Table<IDetailProjectResponse, SearchProject>
                      headerCells={
                        SEARCH_TAB_MENU.filter(entry => entry.tabItem === SearchTabs.Projects)[0]
                          .headerCells as HeadCell<IDetailProjectResponse, SearchProject>[]
                      }
                      rowCount={DEFAULT_ROW_COUNT}
                      rows={projectRows}
                      scoreStatus={SCORE_STATUS}
                      startIndex={projectStartIndex}
                      hasMoreItems={hasMoreProjectItems}
                      setStartIndex={setProjectStartIndex}
                      handleClickRow={handleProjectLeadClickRow}
                      reloadItems={reloadProjectLeadSubmitSearch}
                      isSortable={false}
                    />
                  );
                default:
                  return;
              }
            })()}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UniversalSearchPage;
