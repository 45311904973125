import { FormControl, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import has from 'lodash/has';

import { FieldInputLabel, TextFieldInput } from './LeadsFilterItem.styles';

import {
  allKeysSelectedState,
  filtersInputedState,
  filtersSelectedState,
  allFilterTagsState,
} from '../../../../state/atoms/filters';
import {
  Comparators,
  FilterData,
  FilterItem,
  FilterOptionsItem,
  OptionValue,
  TagsDisplay,
} from '../../../../types';

interface IProps {
  filterItemOption: FilterOptionsItem;
  filterItem: FilterItem;
  setIsChecked: (isChecked: boolean) => void;
  filterInputsToClear: string[];
  setFilterInputsToClear: (inputKeys: string[]) => void;
  multipleSelect?: boolean;
  clearFilters?: boolean;
}

const LeadsFilterItemForm: React.FC<IProps> = ({
  filterItemOption,
  filterItem,
  setIsChecked,
  filterInputsToClear,
  setFilterInputsToClear,
  multipleSelect = true,
  clearFilters = false,
}: IProps) => {
  const defaultSelected = useMemo(
    () => ({
      [filterItem.filterKey]: {},
    }),
    [filterItem.filterKey],
  );
  const [currentFilterItem, setCurrentFilterItem] = useState<FilterOptionsItem | undefined>();
  const setAllKeys = useSetRecoilState<FilterData | undefined>(allKeysSelectedState);

  const [inputedKeys, setInputedKeys] = useRecoilState<Record<string | number, any>>(
    filtersInputedState(filterItem.filterKey),
  );
  const [selectedKeys, setSelectedKeys] = useRecoilState<FilterData>(
    filtersSelectedState(filterItem.filterKey),
  );

  const setAllFilterTags = useSetRecoilState<TagsDisplay[]>(allFilterTagsState);

  const getFormattedValue = useCallback(
    (item: FilterOptionsItem): string => {
      if (!item.id || !inputedKeys[item.id]) {
        return '';
      }
      if (item.formatter) {
        return item.formatter(inputedKeys[item.id]);
      } else {
        return inputedKeys[item.id];
      }
    },
    [inputedKeys],
  );

  const handleComparators = useCallback(
    (value: string | number | any, item: FilterOptionsItem) => {
      value = parseFloat(value);
      if (item && item.id) {
        if (isNaN(value)) {
          const removeKey = { ...inputedKeys };
          delete removeKey[item.id];
          setInputedKeys(removeKey);
          setCurrentFilterItem(item);
          setAllFilterTags((prev: any) => prev.filter((tag: any) => tag.id !== item.id));
        } else {
          setInputedKeys({ ...inputedKeys, [item.id]: value });
          setCurrentFilterItem(item);
          setAllFilterTags((prev: any) =>
            prev
              .filter((tag: any) => tag.id !== item.id)
              .concat([
                {
                  label: filterItem.filterLabel,
                  key: filterItem.filterKey,
                  value,
                  comparator: item.comparator,
                  filterValue: value,
                  id: item.id,
                  isInput: true,
                },
              ]),
          );
        }
      }
    },
    [filterItem.filterKey, filterItem.filterLabel, inputedKeys, setAllFilterTags, setInputedKeys],
  );

  const handleInputChange = useCallback(
    (value: string | number | any, item: FilterOptionsItem) => {
      if (item.id) {
        if (item.formatter && item.unformatter) {
          value = item.unformatter(value);
        }

        if (
          [Comparators.Gt, Comparators.Lt, Comparators.Lte, Comparators.Gte].includes(
            item.comparator,
          )
        ) {
          handleComparators(value, item);
        } else {
          if (value === '') {
            const removeKey = { ...inputedKeys };
            delete removeKey[item.id];
            setInputedKeys(removeKey);
            setCurrentFilterItem(item);
          } else {
            setInputedKeys({ ...inputedKeys, [item.id]: value });
            setCurrentFilterItem(item);
          }
        }
        if (!multipleSelect) {
          setSelectedKeys(defaultSelected);
        }
      }
    },
    [
      defaultSelected,
      inputedKeys,
      multipleSelect,
      setInputedKeys,
      setSelectedKeys,
      handleComparators,
    ],
  );

  useEffect(() => {
    const currentInputedKeys = { ...inputedKeys };
    if (clearFilters) {
      Object.keys(currentInputedKeys).forEach(key => {
        currentInputedKeys[key] = 0;
      });
      setInputedKeys(currentInputedKeys);
      setSelectedKeys(defaultSelected);
      setIsChecked(false);
      return;
    }
    if (filterInputsToClear.length > 0) {
      filterInputsToClear.forEach(inputKey => {
        currentInputedKeys[inputKey] = 0;
      });
      setInputedKeys(currentInputedKeys);
      setFilterInputsToClear([]);
    }
  }, [
    clearFilters,
    filterInputsToClear,
    inputedKeys,
    setFilterInputsToClear,
    setInputedKeys,
    setSelectedKeys,
    defaultSelected,
    setIsChecked,
  ]);

  useEffect(() => {
    let data: FilterData = { ...selectedKeys };

    if (currentFilterItem) {
      Object.keys(inputedKeys).forEach(key => {
        if (currentFilterItem.id !== key) {
          return;
        }
        const item: FilterOptionsItem = { ...currentFilterItem };
        item.value = inputedKeys[key];
        if (!item.value) {
          return;
        }

        if (item.comparator !== Comparators.In && item.comparator !== Comparators.Nin) {
          data[filterItem.filterKey] = {
            ...data[filterItem.filterKey],
            [item.comparator]: item.value,
          };
          setAllKeys((prev: any) => ({
            ...prev,
            ...data,
            ...(data.value && prev.value && { value: { ...prev.value, ...data.value } }),
          }));
          return;
        }

        let filterVal: OptionValue[] = [item.value];
        if (has(data, [filterItem.filterKey, item.comparator])) {
          const valuesArray: Array<OptionValue> = data[filterItem.filterKey][
            item.comparator
          ] as Array<OptionValue>;
          if (multipleSelect) {
            const addList = [...valuesArray];
            addList.push(item.value);
            filterVal = addList;
          }
        }

        data = {
          ...data,
          [filterItem.filterKey]: {
            [item.comparator]: filterVal,
          },
        };
        setAllKeys(prev => ({ ...prev, ...data }));
      });
    }
  }, [
    inputedKeys,
    filterItem.filterKey,
    currentFilterItem,
    setAllKeys,
    multipleSelect,
    selectedKeys,
  ]);

  return (
    <div key={`${filterItemOption.id}-1`} style={{ margin: '0.5rem 0' }}>
      <FormControl key={`${filterItemOption.id}-1`}>
        <FieldInputLabel shrink htmlFor="bootstrap-input">
          {filterItemOption.label}
        </FieldInputLabel>
        <TextFieldInput
          className="hidden"
          value={filterItemOption.id ? inputedKeys[filterItemOption.id] : ''}
          id={filterItemOption.id}
          placeholder={filterItemOption.label}
        />
      </FormControl>
      <FormControl key={`${filterItemOption.id}-2`}>
        <TextFieldInput
          value={getFormattedValue(filterItemOption) || ''}
          onChange={evt => handleInputChange(evt.currentTarget.value, filterItemOption)}
          id={`${filterItemOption.id}_display`}
          placeholder={filterItemOption.label}
        />
      </FormControl>
      {filterItemOption.id === 'value_min' && (
        <Typography
          style={{
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '.75rem',
            paddingRight: '.5rem',
          }}
        >
          to
        </Typography>
      )}
    </div>
  );
};

export default LeadsFilterItemForm;
